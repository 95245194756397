<template>
	<li v-editable="blok" class="timeline__item">
		<picture v-if="image()">
			<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
			<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
			<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
			<img v-lazy-load :data-src="image()" :alt="blok.image.alt" width="400" height="320" />
		</picture>

		<h3 v-if="blok.title" class="text-2">{{ blok.title }}</h3>
		<h3 v-else class="text-2" aria-hidden="true">{{ blok.title }}</h3>

		<div v-if="description" v-html="description"></div>
	</li>
</template>

<style lang="scss">
/* DEV NOTE: Support for the original design of not displaying a title and displaying two items as if they were one, is set up in code, but not within Storyblok. If needed, allow title to be optional, and add a boolean to combine an item...which would need to add the class .timeline__item--combine */
.timeline {
	--side-space: 20px;

	display: grid;
	grid-auto-flow: column;
	list-style: none;
	margin: 0 calc(var(--content-space-side) * -1px) var(--margin-bottom);
	overflow-x: auto;
	overscroll-behavior-x: contain;
	padding: 0 0 calc(var(--content-space-side) * 1px) var(--side-space);
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	scrollbar-color: #fff var(--gray-lite);
	scrollbar-width: thin;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar-track {
		background: var(--gray-lite);
	}
	&::-webkit-scrollbar-thumb {
		background-color: var(--black);
	}
	&::-webkit-scrollbar {
		height: 4px;
	}
	&::-webkit-scrollbar-track {
		margin: 0 var(--side-space);
	}

	&__item:not(.timeline__item--combine) {
		--max-item-width: 500px;
		--min-item-width: 80vw;
	}

	&__item--combine {
		--max-item-width: 400px;
		--min-item-width: 80vw;

		--side-space: 20px;
	}

	li {
		margin-block-end: calc(var(--content-space-side) * 1px);
		scroll-snap-align: center;
	}
	li:not(:last-child) {
		margin-inline-end: var(--side-space);
		width: min(var(--max-item-width), var(--min-item-width));
	}
	li:last-child {
		--max-item-width: 400px;

		padding-right: var(--side-space);
		width: min(calc(var(--max-item-width) + var(--side-space)), calc(var(--min-item-width) + var(--side-space)));

		h3::before {
			width: unset;
		}
	}

	picture {
		display: block;
		margin-block-end: 45px;
	}

	h3 {
		position: relative;

		&::before {
			border-top: 2px solid var(--orange);
			content: '';
			display: block;
			height: 2px;
			margin-block-end: 25px;
			width: calc(100% + var(--side-space));
		}
		&:not(:empty)::after {
			background-color: var(--orange);
			border-radius: 50%;
			content: '';
			display: block;
			height: 16px;
			left: 0;
			position: absolute;
			top: -7px;
			width: 16px;
		}

		&:empty::before {
			color: transparent;
			content: 'No content.';
			height: auto;
			margin-block-end: 0;
			padding-block-start: 25px;
		}
	}

	p {
		width: min(400px, 100%);
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.timeline {
		--margin-bottom: 50px;
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.timeline {
		--margin-bottom: 100px;
	}
}

@media all and (min-width: 1420px) {
	.timeline {
		--side-space: 40px;
	}
}
</style>

<script>
export default {
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 400
				height = 320

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {},
	computed: {
		description() {
			if (this.blok.description && this.blok.description.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.description)
			}
		}
	}
}
</script>
