var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "media-text",
      class: _vm.isFullscreen(),
      style: _vm.styles()
    },
    [
      _c(
        "div",
        { staticClass: "media-text__wrapper", class: _vm.blok.variation },
        [
          _c("div", { staticClass: "media-text__image" }, [
            _c("picture", [
              _c("source", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                attrs: {
                  "data-srcset": _vm.image("webp", true),
                  media:
                    "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                  type: "image/webp"
                }
              }),
              _vm._v(" "),
              _c("source", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                attrs: {
                  "data-srcset": _vm.image(null, true),
                  media:
                    "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                  type: "image/jpeg"
                }
              }),
              _vm._v(" "),
              _c("source", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                attrs: { "data-srcset": _vm.image("webp"), type: "image/webp" }
              }),
              _vm._v(" "),
              _c("img", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                attrs: {
                  "data-src": _vm.image(),
                  width: "750",
                  height: "793",
                  alt: _vm.blok.image.alt
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "media-text__text" }, [
            _vm.blok.title
              ? _c(
                  "h2",
                  [
                    _vm._v("\n\t\t\t\t" + _vm._s(_vm.blok.title)),
                    _vm.blok.bookmark === true
                      ? _c("Bookmark", { attrs: { id: _vm.bookmarkId } })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.subtitle
              ? _c("div", {
                  staticClass: "media-text__subtitle",
                  domProps: { innerHTML: _vm._s(_vm.subtitle) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.text
              ? _c("div", {
                  staticClass: "media-text__content",
                  domProps: { innerHTML: _vm._s(_vm.text) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.bottom_link.cached_url
              ? _c(
                  "a",
                  {
                    staticClass: "media-text__text__link",
                    style: _vm.styles(),
                    attrs: { href: "/" + _vm.blok.bottom_link.cached_url }
                  },
                  [
                    _vm._v(_vm._s(_vm.blok.bottom_link_text) + " "),
                    _c("img", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-src": _vm.arrow(),
                        alt: _vm.blok.bottom_link_text,
                        width: "20",
                        height: "20"
                      }
                    })
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }