<template>
	<li v-editable="blok">
		<figure>
			<img v-lazy-load :data-src="blok.image.filename" :alt="blok.image.alt" />
			<figcaption>{{ blok.title }}</figcaption>
		</figure>
	</li>
</template>

<style lang="scss">
.icon-list-container {
	margin: 0 auto 40px;
	width: min(var(--content-width), 100%);
}

.icon-list {
	@include grid(12, 10);
	grid-auto-flow: column;
	-ms-overflow-style: none; /* IE11: hide scrollbar */
	overflow-x: auto;
	overscroll-behavior-x: contain;
	-webkit-overflow-scrolling: touch;
	scrollbar-width: none; /* Firefox: hide scrollbar */
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;

	/* Chrome/Edge/Safari: hide scrollbar */
	&::-webkit-scrollbar {
		display: none;
	}

	li {
		background-color: var(--beige);
		border-radius: 10px;
		height: 200px;
		padding: 54px 10px 18px;
		scroll-snap-align: start;
		width: var(--li-width);
	}

	figure {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: flex-start;
		flex-direction: column;
	}

	figcaption {
		line-height: 1.1;
		text-align: center;
		width: 100%;
	}

	img {
		margin-block-end: auto;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.icon-list {
		--li-width: 150px;
		margin: 0 calc(var(--content-space-side) * -1px);
		padding-inline-start: 15px;

		li {
			scroll-margin: 0 0 0 15px;
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.icon-list {
		--li-width: 160px;
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
