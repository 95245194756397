var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "bs-image"
    },
    [
      _c("picture", [
        _c("img", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          staticClass: "bs-image__image",
          attrs: {
            width: "1599",
            height: "932",
            "data-src": this.blok.image.filename,
            alt: ""
          }
        })
      ]),
      _vm._v(" "),
      _vm.note
        ? _c("div", {
            staticClass: "bs-image__note",
            domProps: { innerHTML: _vm._s(_vm.note) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }