<template>
	<div v-editable="blok" class="pdp-video">
		<div :class="blok.variation" class="pdp-video__wrapper">
			<div v-if="blok.video_file.filename" class="pdp-video__video">
                <video loop="true" muted playsinline autoplay="true" class="pdp-video__video--video" ref="video">
                    <source v-lazy-load :data-src="blok.video_file.filename" type="video/mp4" ref="videoSrc" />
                </video>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
   .pdp-video {
        width: 100%;
        height: auto;
        overflow: hidden;
        &__wrapper {
            width: 100%;
            height: auto;
            display: flex;
            align-items: stretch;
            justify-content: center;
        }

        &__video {
            width: 100%;
            height: auto;
            video {
                height: auto;
                position: relative;
                width: 100%;
                object-fit: cover;
                display: block;
            }
        }
   }
</style>

<script>
    export default {
        components: {
        },
        data() {
            return {}
        },
        props: ['blok'],
        methods: {
           
        },
        mounted() {
            console.debug(this.blok)
        },
        computed: {
            
        }
    }
    </script>