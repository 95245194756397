<template>
	<div v-editable="blok" class="product-details">
		<component :key="blok._uid" v-for="blok in blok.item" :blok="blok" :is="blok.component | dashify"></component>

		<div v-if="rte" v-html="rte"></div>
	</div>
</template>

<style lang="scss">
.product-details {
	border-block-end: 2px solid var(--gray-lite);
	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.product-details {
		--margin-bottom: 50px;
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.product-details {
		--margin-bottom: 150px;
	}
}

/* Large & Up Screen */
@media all and #{$mq-l-min} {
	.product-details {
		@include grid;

		> *:first-child {
			grid-column: 1 / span 8;
		}

		> *:last-child {
			grid-column: 9 / span 4;
		}
	}
}
</style>

<script>
export default {
	components: {
		ListIcon: () => import('~/components/ListIcon.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		rte() {
			if (this.blok.rte) {
				return this.$storyapi.richTextResolver.render(this.blok.rte)
			}
		}
	}
}
</script>
