<template>
	<div v-editable="blok" class="text-with-video">
		<div :class="blok.variation" class="text-with-video__wrapper">
			<div v-if="blok.video_file.filename" class="text-with-video__video"  data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
                <video loop="true" muted playsinline autoplay="true" class="text-with-video__video--video" ref="video">
                    <source v-lazy-load :data-src="blok.video_file.filename" type="video/mp4" ref="videoSrc" />
                </video>
			</div>

			<div class="text-with-video__text" :style="styles()"  data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
				<div v-if="text" v-html="text" class="text-with-video__content"></div>	
			</div>
		</div>
	</div>
</template>

<style lang="scss">
   .text-with-video {
        width: 100%;
        min-height: 100vh;
        overflow: hidden;
        @media (max-width: 1024px) {
            height: auto;
        }
        &__wrapper {
            width: 100vw;
            min-height: 100vh;
            display: flex;
            align-items: stretch;
            justify-content: center;
            &.right {
                flex-direction: row-reverse;
            }

            @media (max-width: 1024px) {
                flex-direction: column !important;
                height: auto;
            }
        }

        &__video {
            width: 50%;
            min-height: 100%;
            @media (max-width: 1024px) {
                width: 100%;
                height: auto;
            }
            video {
                height: 100%;
                position: relative;
                width: 100%;
                object-fit: cover;
                display: block;
                @media (max-width: 1024px) {
                    display: block;
                    height: auto; 
                }
            }
        }

        &__text{
            width: 50%;
            min-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @media (max-width: 1024px) {
                width: 100%;
                height: auto;
                min-height: 100vh;
            }
        }

        &__content {
            width: 100%;
            max-width: 630px;
            padding: 45px;

            h2 {
                font-size: 48px;
                font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
                line-height: 1.1;
                margin-bottom: 0;
            }

            @media (max-width: 1024px) {
                padding: 40px 30px;
                h2 {
                    font-size: 38px;
                }
            }
        }
   }
</style>

<script>
    export default {
        components: {
        },
        data() {
            return {}
        },
        props: ['blok'],
        methods: {
            styles() {
                let bgColor, textColor;
                if (this.blok && this.blok.background) {
                    bgColor = 'background:' + this.blok.background.color + ';';
                }
                if (this.blok && this.blok.text_color) {
                    textColor = 'color:' + this.blok.text_color + ';';
                }
    
                const styles = bgColor + textColor;
    
                return styles;
            }
        },
        mounted() {
            console.debug(this.blok)
        },
        computed: {
            text() {
                if (this.blok.text) {
                    return this.$storyapi.richTextResolver.render(this.blok.text).replace(/\|/g, "<br />");
                }
            },
        }
    }
    </script>