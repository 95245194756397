var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.gdprData
    ? _c(
        "div",
        {
          ref: "gdpr",
          staticClass: "gdpr",
          class: _vm.gdprVisible == true ? "gdpr--visible" : "",
          attrs: { role: "dialog" }
        },
        [
          _c("div", { staticClass: "gdpr__content" }, [
            _vm.text
              ? _c("div", {
                  staticClass: "small-text",
                  domProps: { innerHTML: _vm._s(_vm.text) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "secondary-button",
                attrs: { type: "button" },
                on: { click: _vm.setGDPR }
              },
              [_vm._v("I Agree")]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }