<template>
	<div v-editable="blok" class="profile-form">
		<form @submit.prevent="handlePasswordChange" class="bn-form" :class="this.hasErrors ? 'has-errors' : false" method="post">
			<input type="hidden" name="token" v-model="token" />

			<p class="note"><em>*</em> = required</p>

			<p v-if="this.errorServer" class="error">There was a problem updating your password, please try again.</p>

			<fieldset>
				<div class="form-field">
					<label for="email">Email Address<em>*</em></label>
					<input type="email" id="email" name="email" required @invalid="invalidateForm" v-model="email" />
					<p v-if="errors('email')" v-html="errors('email')" class="error"></p>
				</div>

				<div class="form-field"></div>

				<div class="field-group">

					<div class="form-field" :class="{ 'has-error': errors('password') }" data-size="full">
						<label for="mypassword">New Password<em>*</em></label>
						<input type="password" id="mypassword" name="mypassword" required  @invalid="invalidateForm" v-model="password" />
						<p v-if="errors('password')" v-html="errors('password')" class="error"></p>
					</div>

					<div class="form-field" :class="{ 'has-error': errors('password_confirmation') }" data-size="full">
						<label for="password_confirmation">Confirm Password<em>*</em></label>
						<input type="password" id="password_confirmation" name="password_confirmation" required @invalid="invalidateForm" v-model="password_confirmation" />
						<p v-if="errors('password_confirmation')" v-html="errors('password_confirmation')" class="error"></p>
					</div>
				</div>

			</fieldset>

			<button type="submit" class="primary-button">Change Password</button>

			<p v-if="successMessage" class="success-message">Your password has been reset. Proceed to <a href="/account/profile">login</a> page.</p>
		</form>
	</div>
</template>

<style lang="scss">
.profile-form {
	--margin-bottom: 50px;

	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	fieldset {
		margin-block-end: 50px;
	}

	.field-group,
	.form-field {
		width: min(428px, 100%);
	}

	button.toggle {
		margin-block-start: 20px;
	}

	.success-message {
		margin-block-start: 2em;
	}
}

@media all and #{$mq-s-max} {
	.profile-form {
		.field-group,
		.form-field {
			width: min(428px, 100%);
		}
	}
}

@media all and #{$mq-m-min} {
	.profile-form {
		fieldset {
			@include grid();
			row-gap: 20px;

			> div:nth-child(odd) {
				grid-column: 1 / span 6;
			}

			> div:nth-child(even) {
				grid-column: 7 / span 6;
			}
		}
	}
}

@media all and #{$mq-l-min} {
}
</style>

<script>
export default {
	components: {},
	data() {
		return {
			aria: true,
			hasErrors: false,
			errorServer: false,
			errorMessages: {},
			successMessage: false,
			password: '',
			password_confirmation: '',
			email: '',
			token: ''
		}
	},

	props: ['blok', 'user'],
	computed: {
	},
	created() {

		this.email = this.$route.query.email
		this.token = this.$route.query.token

		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},

	methods: {
		invalidateForm() {
			this.hasErrors = true
		},
		errors(fieldId) {
			// return formatted error messages for a given field

			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			//if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		handlePasswordChange() {

			// check for any additional frontend validation
			if (this.password != this.password_confirmation) {
				this.errorMessages = this.errorMessages || {}
				this.errorMessages['password_confirmation'] = ['Passwords must match.']

				return
			}

			// make the password change request
			this.$axios.post(process.env.doPasswordChangeEndpoint, {
				token: this.token,
				email: this.email,
				password: this.password,
				password_confirmation: this.password_confirmation
			}).then(response => {

				this.successMessage = true

			}).catch(error => {
				if (error.response && error.response.data && error.response.data.errors) {
					let k;
					for(k in error.response.data.errors) {
						this.errorMessages[k] = error.response.data.errors[k]
					}
				}
				this.invalidateForm()
			})

		}

	}
}
</script>
