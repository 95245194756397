<template>
	<div v-editable="blok" :class="snapToTop()" class="is-hero product-media cs-hero">
		<div v-if="blok.video.filename" class="prod-video-wrapper">
			<video id="csVideo" loop="true" muted playsinline autoplay="true" class="video-clip__video" ref="video">
				<source v-lazy-load :data-src="blok.video.filename" type="video/mp4" ref="videoSrc" />
			</video>
            <div class="bn-video--button">
                <button id="bn-toggleMute" @click=toggleMute()>MUTE</button>
            </div>
            <div class="scroll-hint">
                <span class="scroll-hint__mouse">
                    <span></span>
                </span>
                <p>Scroll Down</p>
            </div>
		</div>
	</div>
</template>

<style lang="scss">
	.bn-video--button {
        position: absolute;
        bottom:40px;
        left: 50px;
        z-index: 1000;
        @media (max-width: 768px) {
            bottom: 10px;
            left: 10px;
        }
        button {
            display: block;
            color: #fff;
            width: 80px;
            height: 80px;
            border: 0;
            box-shadow: none;
            font-size: 0;
            background: url(/img/ui/mute.svg) center center no-repeat;
            border-radius: 50%;
            &.unmuted {
                background-image: url(/img/ui/unmute.svg);
            }

            @media (max-width: 768px) {
                width: 40px;
                height: 40px;
            }
        }
    }

    .cs-hero .prod-video-wrapper{
        @media (max-width: 768px) {
            height: 50vh;
        }
    }

    .cs-hero {
        .scroll-hint {
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            color: var(--white);
            z-index: 100;
            @media (max-width: 768px) {
                display: none;
            }

            &__mouse {
                width: 19px;
                height: 28px;
                border: 2px solid var(--white);
                display: block;
                position: relative;
                margin: 0 auto 5px auto;
                border-radius: 9px;

                span {
                    width: 1.5px;
                    height: 6px;
                    position: absolute;
                    left: 50%;
                    top: 4px;
                    background: var(--white);
                    display: block;
                    transform: translate(-50%, 0);
                    animation: scrollHint2 0.75s infinite alternate-reverse linear;
                }
            }

            p {
                margin: 0;
            }
        }
    }

    .is-hero + .bs-text {
        p {
            font-size: 24px;
            line-height: 32px;
            font-family: "FranklinGothicLTPro-Bk", "Segoe UI", "Helvetica Neue", sans-serif;
        }
    }
    @keyframes scrollHint2 {
        0% {
            transform: translate(-50%, 0);
        }
        100% {
            transform: translate(-50%, 40%);
        }
    }

</style>

<script>
export default {
	components: {
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		snapToTop() {
			if(this.blok && this.blok.snap_to_top) {
				return ' snap';
			}
		},
        toggleMute() {
            let video=document.getElementById("csVideo");
            let btn=document.getElementById("bn-toggleMute");
            video.muted = !video.muted;
            btn.classList.toggle('unmuted');
        }
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		
	}
}
</script>
