var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "text-number"
    },
    [
      _c("div", { staticClass: "text-number__wrapper" }, [
        _vm.blok.number
          ? _c("div", { staticClass: "text-number__number" }, [
              _c("span", [_vm._v(_vm._s(_vm.blok.number))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.text
          ? _c("div", {
              staticClass: "text-number__title",
              domProps: { innerHTML: _vm._s(_vm.text) }
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }