var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "bs-text-on-image",
      style: _vm.bgimage()
    },
    [
      _c(
        "div",
        {
          staticClass: "bs-text-on-image__wrapper",
          attrs: {
            "data-aos": "fade",
            "data-aos-offset": "-100",
            "data-aos-delay": "0",
            "data-aos-duration": "700",
            "data-aos-once": "true",
            "data-aos-anchor-placement": "bottom-bottom"
          }
        },
        [
          _vm.blok.category
            ? _c("div", {
                staticClass: "bs-text-on-image__pre",
                domProps: { innerHTML: _vm._s(_vm.blok.category) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.blok.title
            ? _c("h2", {
                staticClass: "bs-text-on-image__title",
                domProps: { innerHTML: _vm._s(_vm.blok.title) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.description
            ? _c("div", {
                staticClass: "bs-text-on-image__description",
                domProps: { innerHTML: _vm._s(_vm.description) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.note
            ? _c("div", {
                staticClass: "bs-text-on-image__note",
                class: _vm.blok.note_position,
                domProps: { innerHTML: _vm._s(_vm.note) }
              })
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }