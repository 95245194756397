var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "accordion__item"
    },
    [
      _c(
        "h3",
        {
          staticClass: "text-2 accordion__title",
          class: [_vm.showContent == true ? "accordion__item--active" : ""]
        },
        [
          _c(
            "button",
            {
              attrs: { "aria-expanded": _vm.showContent },
              on: {
                click: function($event) {
                  ;(_vm.showContent = !_vm.showContent), (_vm.aria = !_vm.aria)
                }
              }
            },
            [_vm._v(_vm._s(_vm.blok.category))]
          )
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.blok.content, function(blok) {
        return _c(_vm._f("dashify")(blok.component), {
          key: blok._uid,
          tag: "component",
          attrs: { blok: blok, inAccordion: true, "aria-hidden": _vm.aria }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }