var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "is-hero product-media cs-hero",
      class: _vm.snapToTop()
    },
    [
      _vm.blok.video.filename
        ? _c("div", { staticClass: "prod-video-wrapper" }, [
            _c(
              "video",
              {
                ref: "video",
                staticClass: "video-clip__video",
                attrs: {
                  id: "csVideo",
                  loop: "true",
                  muted: "",
                  playsinline: "",
                  autoplay: "true"
                },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  ref: "videoSrc",
                  attrs: {
                    "data-src": _vm.blok.video.filename,
                    type: "video/mp4"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "bn-video--button" }, [
              _c(
                "button",
                {
                  attrs: { id: "bn-toggleMute" },
                  on: {
                    click: function($event) {
                      return _vm.toggleMute()
                    }
                  }
                },
                [_vm._v("MUTE")]
              )
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scroll-hint" }, [
      _c("span", { staticClass: "scroll-hint__mouse" }, [_c("span")]),
      _vm._v(" "),
      _c("p", [_vm._v("Scroll Down")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }