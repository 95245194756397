import { render, staticRenderFns } from "./UserLogout.vue?vue&type=template&id=17e62b60&"
import script from "./UserLogout.vue?vue&type=script&lang=js&"
export * from "./UserLogout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/webadmin/sites/bnind.com/bnfe/releases/20240605T022834/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17e62b60')) {
      api.createRecord('17e62b60', component.options)
    } else {
      api.reload('17e62b60', component.options)
    }
    module.hot.accept("./UserLogout.vue?vue&type=template&id=17e62b60&", function () {
      api.rerender('17e62b60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/UserLogout.vue"
export default component.exports