<template>
	<div v-editable="blok" class="accordion">
		<h2 class="section-title">{{ blok.section_title }}</h2>
		<AccordionItem :key="item._uid" v-for="item in blok.item" :blok="item" />
	</div>
</template>

<style lang="scss">
.accordion {
	border-block-end: 2px solid var(--gray-lite);
	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	> h2 {
		margin-block-end: 0;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.accordion {
		--margin-bottom: 50px;
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.accordion {
		--margin-bottom: 150px;
	}
}
</style>

<script>
export default {
	components: {
		AccordionItem: () => import('@/components/AccordionItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
