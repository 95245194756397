var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      class: _vm.isSelected()
        ? "selected-item interactive-item"
        : "interactive-item",
      style: _vm.style()
    },
    [
      _vm.blok.image
        ? [
            _c(
              "a",
              {
                staticClass: "interactive-block__fullscreen",
                attrs: { href: "javascript:void(0);" }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "34px",
                      height: "34px",
                      viewBox: "0 0 34 34",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "PDP",
                          stroke: "none",
                          "stroke-width": "1",
                          fill: "none",
                          "fill-rule": "evenodd"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "m-reconfigure",
                              transform: "translate(-331.000000, -2006.000000)"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Group",
                                  transform:
                                    "translate(331.000000, 2006.000000)"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    id: "Rectangle",
                                    stroke: "#CBCBCB",
                                    fill: "#FFFFFF",
                                    x: "0.5",
                                    y: "0.5",
                                    width: "33",
                                    height: "33",
                                    rx: "4"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      transform:
                                        "translate(8.000000, 8.000000)",
                                      fill: "#000000",
                                      id: "expand"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M5.6195122,0.746341463 L5.6195122,0 L0,0 L0,5.6195122 L0.746341463,5.6195122 L0.746341463,1.27317073 L6.10243902,6.62926829 L6.62926829,6.10243902 L1.27317073,0.746341463 L5.6195122,0.746341463 Z M12.3804878,0 L12.3804878,0.746341463 L16.7268293,0.746341463 L11.3707317,6.10243902 L11.897561,6.62926829 L17.2536585,1.27317073 L17.2536585,5.6195122 L18,5.6195122 L18,0 L12.3804878,0 Z M6.10243902,11.3707317 L0.746341463,16.7268293 L0.746341463,12.3804878 L0,12.3804878 L0,18 L5.6195122,18 L5.6195122,17.2536585 L1.27317073,17.2536585 L6.62926829,11.897561 L6.10243902,11.3707317 Z M17.2536585,16.7268293 L11.897561,11.3707317 L11.3707317,11.897561 L16.7268293,17.2536585 L12.3804878,17.2536585 L12.3804878,18 L18,18 L18,12.3804878 L17.2536585,12.3804878 L17.2536585,16.7268293 Z",
                                        id: "Fill-1"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "interactive-block__fullscreen__div" }, [
              _c(
                "a",
                {
                  staticClass: "interactive-block__fullscreen__div__close",
                  attrs: { href: "javascript:void(0);" }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "34px",
                        height: "34px",
                        viewBox: "0 0 34 34",
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink"
                      }
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "PDP",
                            stroke: "none",
                            "stroke-width": "1",
                            fill: "none",
                            "fill-rule": "evenodd"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "toggle-open",
                                transform: "translate(-331.000000, -10.000000)"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Group",
                                    transform:
                                      "translate(331.000000, 10.000000)"
                                  }
                                },
                                [
                                  _c("rect", {
                                    attrs: {
                                      id: "Rectangle",
                                      stroke: "#CBCBCB",
                                      fill: "#FFFFFF",
                                      x: "0.5",
                                      y: "0.5",
                                      width: "33",
                                      height: "33",
                                      rx: "4"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "minimize",
                                        transform:
                                          "translate(8.000000, 8.000000)",
                                        stroke: "#000000",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M5,0 L5,3 C5,4.1045695 4.1045695,5 3,5 L0,5 M18,5 L15,5 C13.8954305,5 13,4.1045695 13,3 L13,0 M13,18 L13,15 C13,13.8954305 13.8954305,13 15,13 L18,13 M0,13 L3,13 C4.1045695,13 5,13.8954305 5,15 L5,18",
                                          id: "Shape"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("img", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                attrs: {
                  "data-src": _vm.blok.image.filename,
                  width: "2880",
                  height: "1680",
                  alt: ""
                }
              })
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }