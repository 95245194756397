var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "social"
    },
    [
      _c("li", [
        _c(
          "a",
          {
            staticClass: "social__facebook",
            attrs: { href: _vm.blok.facebook_link.cached_url }
          },
          [_vm._v("Visit B+N Industries on Facebook")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "social__twitter",
            attrs: { href: _vm.blok.twitter_link.cached_url }
          },
          [_vm._v("Visit B+N Industries on Twitter")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "social__linkedin",
            attrs: { href: _vm.blok.linkedin_link.cached_url }
          },
          [_vm._v("Visit B+N Industries on LinkedIn")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "social__instagram",
            attrs: { href: _vm.blok.instagram_link.cached_url }
          },
          [_vm._v("Visit B+N Industries on Instagram")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "social__pinterest",
            attrs: { href: _vm.blok.pinterest_link.cached_url }
          },
          [_vm._v("Visit B+N Industries on Pinterest")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }