<template>
	<main v-if="loggedIn || !blok.protected" id="content" class="configurator" v-editable="blok">
		<iframe frameborder="0" :data-cfgrurl="blok.iframe_src.cached_url" :title="blok.iframe_title"></iframe>
	</main>
	<main v-else id="content" class="wait" v-editable="blok">
		<div class="content-container">
			<user-login :blok="blok"></user-login>
		</div>
	</main>
</template>

<style lang="scss">
.configurator {
	iframe {
		border: 0;
		bottom: 0;
		height: calc(100vh - var(--header-height));
		left: 0;
		position: absolute;
		right: 0;
		top: var(--header-height);
		width: 100%;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.configurator {
		align-items: center;
		display: flex;
		justify-content: center;

		&::before {
			content: 'To provide the best Configurator experience, a browser window at least 768 pixels wide is needed.';
			display: block;
			padding: 50px 20px;
			width: min(350px, 100%);
		}

		iframe {
			opacity: 0;
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.configurator {
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	mounted() {
		let slug = this.blok.iframe_src.cached_url
		if(slug.includes('SORBETTI')){
			let lib = document.createElement('script')
			lib.setAttribute('src', 'https://portal.combeenation.com/plugin/bnindustries/SORBETTI')
			document.head.appendChild(lib)
		}
		if(slug.includes('SYSTEM1224')){
			let lib = document.createElement('script')
			lib.setAttribute('src', 'https://portal.combeenation.com/plugin/bnindustries/SYSTEM1224')
			document.head.appendChild(lib)
		}

		if(window.CbnPlugin){
			window.CbnPlugin.init()
		}
	},
	head() {
		if (this.blok.meta) {
			var meta = this.blok.meta
		}

		if (this.blok.overlap_content) {
			var bodyBgColor = JSON.parse('{ "color":"bg-beige" }')
		}

		const headObj = { ...bodyBgColor, ...meta }

		if (headObj) {
			return {
				bodyAttrs: {
					class: headObj.color
				},
				title: headObj.title,
				meta: [
					{
						hid: 'description',
						name: 'description',
						content: headObj.description
					},
					{
						hid: 'og:title',
						property: 'og:title',
						content: headObj.og_title
					},
					{
						hid: 'og:image',
						property: 'og:image',
						content: headObj.og_image
					},
					{
						hid: 'og:description',
						property: 'og:description',
						content: headObj.og_description
					},
					{
						hid: 'twitter:title',
						name: 'twitter:title',
						content: headObj.twitter_title
					},
					{
						hid: 'twitter:image',
						name: 'twitter:image',
						content: headObj.twitter_image
					},
					{
						hid: 'twitter:description',
						name: 'twitter:description',
						content: headObj.twitter_description
					}
				]
			}
		}
	},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		}
	},
	methods: {},
	props: ['blok']
}
</script>
