<template>
    <div v-editable="blok" class="megamenu-card">
        <nuxt-link :to="'/' + blok.link.cached_url + '/'" class="megamenu-card__link" @click.native="exitNav()">
            <picture>
                <source v-lazy-load :data-srcset="image('webp')" type="image/webp" /> 
                <source v-lazy-load :data-srcset="image(null)" type="image/jpeg" />
                <img v-lazy-load :data-src="image()" width="400" height="180" :alt="blok.title" />
            </picture>
        </nuxt-link>
        <nuxt-link :to="'/' + blok.link.cached_url + '/'" class="megamenu-card__title" @click.native="exitNav()">
            {{ blok.title }}
        </nuxt-link>
    </div>
</template>

<style lang="scss">

.megamenu-columns.three{
    .megamenu-card {
            width: calc(33% - 12px);
        }
    }

.megamenu-columns.four {
    .megamenu-card {
        width: calc(25% - 12px);
    }
}

.megamenu-card {
    &__link {
        display: block;
        width: 100%;
        picture {
            display: block;
            width: 100%;
            position: relative;
            overflow: hidden;
            height: 0;
            padding-bottom: 66%;
            .four & {
                padding-bottom: 70%;
            }
            img {
                width: 100%;
                display: block;
                transition: all 0.3s ease-in-out;
                max-width: none;
            }

            &:hover img {
                transform: scale(1.05);
            }
        }
    }

    &__title {
        display: block;
        text-align: center;
        font-family:'Teodor-Regular', 'Times New Roman', Times, serif;
        font-size: 17px;
        color: #151515;
        margin: 13px 0px 40px;
    }
}

</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
        image(fileType = null) {
			if (this.blok && this.blok.image && this.blok.image.filename) {
				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					fileType: fileType
				})
				return processedImage
			}
		}, 
        exitNav: function () {
            let header = document.querySelector('header.global-header');
            if(header) {
                header.classList.remove('global-nav--interacting');
            }
        }
	},
	mounted() {
	},
	computed: {
	}
}
</script>
