import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4ada8a47 = () => interopDefault(import('../pages/products/rolling-office/_.vue' /* webpackChunkName: "pages/products/rolling-office/_" */))
const _33aa54d8 = () => interopDefault(import('../pages/products/gira/_.vue' /* webpackChunkName: "pages/products/gira/_" */))
const _07b3cd62 = () => interopDefault(import('../pages/about-b-n/contact-us/_.vue' /* webpackChunkName: "pages/about-b-n/contact-us/_" */))
const _09950ec6 = () => interopDefault(import('../pages/resources/_.vue' /* webpackChunkName: "pages/resources/_" */))
const _6b34c1f2 = () => interopDefault(import('../pages/custom-services/_.vue' /* webpackChunkName: "pages/custom-services/_" */))
const _67af07fa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _06b6cdf2 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-exact',
  scrollBehavior,

  routes: [{
    path: "/products/rolling-office/*",
    component: _4ada8a47,
    pathToRegexpOptions: {"strict":true},
    name: "products-rolling-office-all"
  }, {
    path: "/products/gira/*",
    component: _33aa54d8,
    pathToRegexpOptions: {"strict":true},
    name: "products-gira-all"
  }, {
    path: "/about-b-n/contact-us/*",
    component: _07b3cd62,
    pathToRegexpOptions: {"strict":true},
    name: "about-b-n-contact-us-all"
  }, {
    path: "/resources/*",
    component: _09950ec6,
    pathToRegexpOptions: {"strict":true},
    name: "resources-all"
  }, {
    path: "/custom-services/*",
    component: _6b34c1f2,
    pathToRegexpOptions: {"strict":true},
    name: "custom-services-all"
  }, {
    path: "/",
    component: _67af07fa,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/*",
    component: _06b6cdf2,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
