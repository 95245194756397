<template>
	<div v-editable="blok" class="columns">
		<div v-if="block1" v-html="block1" class="columns__block"></div>

		<div v-if="block2" v-html="block2" class="columns__block"></div>

		<div v-if="block3" v-html="block3" class="columns__block"></div>

		<div v-if="block4" v-html="block4" class="columns__block"></div>
	</div>
</template>

<style lang="scss">
/* Styles defined in _columns.scss */
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	computed: {
		block1() {
			if (this.blok.block_1 && this.blok.block_1.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.block_1)
			}
		},
		block2() {
			if (this.blok.block_2 && this.blok.block_2.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.block_2)
			}
		},
		block3() {
			if (this.blok.block_3 && this.blok.block_3.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.block_3)
			}
		},
		block4() {
			if (this.blok.block_4 && this.blok.block_1.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.block_4)
			}
		}
	},
	methods: {},
	mounted() {}
}
</script>
