<template>
	<div v-if="gdprData" role="dialog" class="gdpr" :class="gdprVisible == true ? 'gdpr--visible' : ''" ref="gdpr">
		<div class="gdpr__content">
			<div v-if="text" v-html="text" class="small-text"></div>

			<button @click="setGDPR" type="button" class="secondary-button">I Agree</button>
		</div>
	</div>
</template>

<style lang="scss">
.gdpr {
	background-color: var(--white);
	box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
	bottom: 0;
	display: none;
	left: 10px;
	position: fixed;
	right: 10px;
	z-index: 5;

	&--visible {
		display: block;
	}

	&__content {
		display: flex;
		justify-content: space-between;
		margin: auto;
		max-width: 1400px;
	}
}

// Small Screen Only
@media all and #{$mq-s-max} {
	.gdpr {
		&__content {
			flex-direction: column;
			padding: 25px;

			.secondary-button {
				align-self: flex-start;
			}
		}
	}
}

// Medium Screen
@media all and #{$mq-m-min} {
	.gdpr {
		&__content {
			align-items: center;
			padding: 25px 40px 20px;

			p {
				margin: 0 40px 0 0;
				max-width: 900px;
			}
		}
	}
}

// Large Screen
@media all and #{$mq-l-min} {
	// GDPR
	.gdpr {
		&__content {
			flex-grow: 1;
		}
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {
			gdprVisible: false
		}
	},
	props: ['blok'],
	computed: {
		gdprData() {
			return this.$store.getters['gdpr/getGDPRData']
		},
		text() {
			if(!this.gdprData.story){
				return '';
			}
			return this.$storyapi.richTextResolver.render(this.gdprData.story.content.gdpr[0].text)
		}
	},
	methods: {
		checkGDPR() {
			this.gdpr = this.$refs.gdpr
			if (process.client) {
				const gdprStorage = localStorage.getItem('gdpr')

				if (gdprStorage !== 'agreed') {
					// Show GDPR Message
					this.gdprVisible = true
				}
			}
		},
		setGDPR() {
			if (process.client) {
				// Store Acceptance
				localStorage.setItem('gdpr', 'agreed')

				// Hide GDPR Message
				this.gdprVisible = false
			}
		}
	},
	mounted() {
		this.$store.dispatch('gdpr/fetchAllGDPRData');
		this.checkGDPR()
	}
}
</script>
