var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-aside" }, [
    _c(
      "aside",
      _vm._l(_vm.blok.aside, function(blok) {
        return _c(_vm._f("dashify")(blok.component), {
          key: blok._uid,
          tag: "component",
          attrs: { blok: blok }
        })
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "layout-aside__content" },
      _vm._l(_vm.blok.body, function(blok) {
        return _c(_vm._f("dashify")(blok.component), {
          key: blok._uid,
          tag: "component",
          attrs: { blok: blok }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }