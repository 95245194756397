var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "text-block",
      class: _vm.blok.custom_class,
      attrs: { "data-size": [_vm.blok.col2 && "col2"] }
    },
    [
      _vm.blok.section_title
        ? _c("h2", [_vm._v(_vm._s(_vm.blok.section_title))])
        : _vm._e(),
      _vm._v(" "),
      _vm.text
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })
        : _vm._e(),
      _vm._v(" "),
      _vm.text2
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.text2) } })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }