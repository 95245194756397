var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "bs-text",
      style: _vm.background()
    },
    [
      _c(
        "div",
        {
          staticClass: "bs-text__wrapper",
          attrs: {
            "data-aos": "fade",
            "data-aos-offset": "0",
            "data-aos-delay": "0",
            "data-aos-duration": "700",
            "data-aos-once": "true",
            "data-aos-anchor-placement": "top-center"
          }
        },
        [
          _c(
            "div",
            { staticClass: "bs-text__text", style: _vm.textColor() },
            [
              _vm.blok.above_title
                ? _c("div", {
                    staticClass: "bs-text__pre",
                    domProps: { innerHTML: _vm._s(_vm.blok.above_title) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.title
                ? _c("div", {
                    staticClass: "bs-text__title",
                    domProps: { innerHTML: _vm._s(_vm.title) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.description
                ? _c("div", {
                    staticClass: "bs-text__description",
                    domProps: { innerHTML: _vm._s(_vm.description) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.blok.add_button
                ? [
                    _c(
                      "div",
                      { staticClass: "bs-text__button" },
                      [
                        _vm.blok.button_link.linktype === "url"
                          ? _c(
                              "a",
                              {
                                class: _vm.blok.button_color,
                                attrs: {
                                  href:
                                    _vm.blok.button_link &&
                                    _vm.blok.button_link.cached_url
                                      ? _vm.blok.button_link.cached_url
                                      : "#"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.blok.button_text) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _c(
                              "nuxt-link",
                              {
                                class: _vm.blok.button_color,
                                attrs: {
                                  to:
                                    _vm.blok.button_link &&
                                    _vm.blok.button_link.cached_url
                                      ? "/" + _vm.blok.button_link.cached_url
                                      : "#"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.blok.button_text) +
                                    "\n                        "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }