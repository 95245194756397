<template>
	<div v-editable="blok" class="message-block">
		<h1>{{ blok.title }}</h1>

		<div v-if="text1" v-html="text1"></div>

		<div v-if="text2 && acctMessage" v-html="text2"></div>

		<nuxt-link v-if="blok.cta_link && blok.cta_link.cached_url" :to="'/' + blok.cta_link.cached_url + '/'" class="secondary-button">{{ blok.cta_text }}</nuxt-link>
	</div>
</template>

<style lang="scss">
.center-vertically {
	display: flex;

	.content-container {
		align-items: center;
		display: flex;
		justify-content: center;
	}
}
.message-block {
	margin: auto;
	padding: 0 15px;
	width: min(682px, 100%);
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {
		this.$emit('verticallyCenter')
	},
	computed: {
		acctMessage() {
			if (process.client && localStorage.getItem('passiveAcct') == 'checked') {
				return true
			}
		},
		text1() {
			if (this.blok.text_1 && this.blok.text_1.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text_1)
			}
		},
		text2() {
			if (this.blok.text_2 && this.blok.text_2.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text_2)
			}
		}
	}
}
</script>
