var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bookmark-container" }, [
    _vm.loggedIn && _vm.isBookmarked
      ? _c(
          "button",
          {
            staticClass: "bookmark bookmark--active",
            attrs: { type: "button" },
            on: { click: _vm.removeBookmark }
          },
          [_vm._v("\n\t\tBookmark it.\n\t")]
        )
      : _vm.loggedIn
      ? _c(
          "button",
          {
            staticClass: "bookmark",
            attrs: { type: "button" },
            on: { click: _vm.saveBookmark }
          },
          [_vm._v("\n\t\tBookmark it.\n\t")]
        )
      : _c(
          "button",
          {
            staticClass: "bookmark",
            attrs: { type: "button" },
            on: { click: _vm.showLoginModal }
          },
          [_vm._v("\n\t\tBookmark it.\n\t")]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }