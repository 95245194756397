var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "bio-block"
    },
    [
      _vm.blok.title
        ? _c("h2", {
            attrs: {
              "data-aos": "fade",
              "data-aos-offset": "-150",
              "data-aos-delay": "0",
              "data-aos-duration": "700",
              "data-aos-once": "true",
              "data-aos-anchor-placement": "top-center"
            },
            domProps: { innerHTML: _vm._s(_vm.blok.title) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bio-block__wrapper" },
        _vm._l(_vm.blok.items, function(blok) {
          return _c(_vm._f("dashify")(blok.component), {
            key: blok._uid,
            tag: "component",
            attrs: { blok: blok }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }