var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.editable(),
          expression: "editable()"
        },
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "card"
    },
    [
      _vm.data
        ? [
            _vm.isFile
              ? [
                  _vm.loggedIn || !_vm.blok.protected_file
                    ? [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.blok.file.filename,
                              target: "_blank",
                              rel: "noopener"
                            }
                          },
                          [
                            _vm.image()
                              ? _c("picture", [
                                  _c("source", {
                                    directives: [
                                      {
                                        name: "lazy-load",
                                        rawName: "v-lazy-load"
                                      }
                                    ],
                                    attrs: {
                                      "data-srcset": _vm.image("webp", true),
                                      media:
                                        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    directives: [
                                      {
                                        name: "lazy-load",
                                        rawName: "v-lazy-load"
                                      }
                                    ],
                                    attrs: {
                                      "data-srcset": _vm.image(null, true),
                                      media:
                                        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                      type: "image/jpeg"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    directives: [
                                      {
                                        name: "lazy-load",
                                        rawName: "v-lazy-load"
                                      }
                                    ],
                                    attrs: {
                                      "data-srcset": _vm.image("webp"),
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "lazy-load",
                                        rawName: "v-lazy-load"
                                      }
                                    ],
                                    attrs: {
                                      width: "500",
                                      height: "500",
                                      "data-src": _vm.image(),
                                      alt: _vm.data.image.alt
                                        ? _vm.data.image.alt
                                        : "alt"
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    : [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.showLoginModal($event)
                              }
                            }
                          },
                          [
                            _vm.image()
                              ? _c("picture", [
                                  _c("source", {
                                    directives: [
                                      {
                                        name: "lazy-load",
                                        rawName: "v-lazy-load"
                                      }
                                    ],
                                    attrs: {
                                      "data-srcset": _vm.image("webp", true),
                                      media:
                                        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    directives: [
                                      {
                                        name: "lazy-load",
                                        rawName: "v-lazy-load"
                                      }
                                    ],
                                    attrs: {
                                      "data-srcset": _vm.image(null, true),
                                      media:
                                        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                      type: "image/jpeg"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    directives: [
                                      {
                                        name: "lazy-load",
                                        rawName: "v-lazy-load"
                                      }
                                    ],
                                    attrs: {
                                      "data-srcset": _vm.image("webp"),
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "lazy-load",
                                        rawName: "v-lazy-load"
                                      }
                                    ],
                                    attrs: {
                                      width: "500",
                                      height: "500",
                                      "data-src": _vm.image(),
                                      alt: _vm.data.image.alt
                                        ? _vm.data.image.alt
                                        : "alt"
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                ]
              : _vm.data.link && _vm.data.link.cached_url
              ? [
                  _vm.externalDownload
                    ? _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: "/" + _vm.data.link.cached_url + "/",
                            target: "_blank",
                            rel: "noopener"
                          }
                        },
                        [
                          _vm.image()
                            ? _c("picture", [
                                _c("source", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load"
                                    }
                                  ],
                                  attrs: {
                                    "data-srcset": _vm.image("webp", true),
                                    media:
                                      "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                    type: "image/webp"
                                  }
                                }),
                                _vm._v(" "),
                                _c("source", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load"
                                    }
                                  ],
                                  attrs: {
                                    "data-srcset": _vm.image(null, true),
                                    media:
                                      "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                    type: "image/jpeg"
                                  }
                                }),
                                _vm._v(" "),
                                _c("source", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load"
                                    }
                                  ],
                                  attrs: {
                                    "data-srcset": _vm.image("webp"),
                                    type: "image/webp"
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load"
                                    }
                                  ],
                                  attrs: {
                                    width: "500",
                                    height: "500",
                                    "data-src": _vm.image(),
                                    alt: _vm.data.image.alt
                                      ? _vm.data.image.alt
                                      : "alt"
                                  }
                                })
                              ])
                            : _vm._e()
                        ]
                      )
                    : _c(
                        "nuxt-link",
                        { attrs: { to: "/" + _vm.data.link.cached_url + "/" } },
                        [
                          _vm.image()
                            ? _c("picture", [
                                _c("source", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load"
                                    }
                                  ],
                                  attrs: {
                                    "data-srcset": _vm.image("webp", true),
                                    media:
                                      "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                    type: "image/webp"
                                  }
                                }),
                                _vm._v(" "),
                                _c("source", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load"
                                    }
                                  ],
                                  attrs: {
                                    "data-srcset": _vm.image(null, true),
                                    media:
                                      "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                    type: "image/jpeg"
                                  }
                                }),
                                _vm._v(" "),
                                _c("source", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load"
                                    }
                                  ],
                                  attrs: {
                                    "data-srcset": _vm.image("webp"),
                                    type: "image/webp"
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load"
                                    }
                                  ],
                                  attrs: {
                                    width: "500",
                                    height: "500",
                                    "data-src": _vm.image(),
                                    alt: _vm.data.image.alt
                                      ? _vm.data.image.alt
                                      : "alt"
                                  }
                                })
                              ])
                            : _vm._e()
                        ]
                      )
                ]
              : [
                  _vm.galleryCardIndex !== undefined && _vm.image()
                    ? _c("picture", [
                        _c("source", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" }
                          ],
                          attrs: {
                            "data-srcset": _vm.image("webp", true),
                            media:
                              "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                            type: "image/webp"
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" }
                          ],
                          attrs: {
                            "data-srcset": _vm.image(null, true),
                            media:
                              "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                            type: "image/jpeg"
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" }
                          ],
                          attrs: {
                            "data-srcset": _vm.image("webp"),
                            type: "image/webp"
                          }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" }
                          ],
                          staticClass: "gallery-image",
                          attrs: {
                            width: "500",
                            height: "500",
                            "data-src": _vm.image(),
                            alt: _vm.data.image.alt ? _vm.data.image.alt : "alt"
                          },
                          on: { click: _vm.galleryClick }
                        })
                      ])
                    : [
                        _vm.image()
                          ? _c("picture", [
                              _c("source", {
                                directives: [
                                  { name: "lazy-load", rawName: "v-lazy-load" }
                                ],
                                attrs: {
                                  "data-srcset": _vm.image("webp", true),
                                  media:
                                    "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                  type: "image/webp"
                                }
                              }),
                              _vm._v(" "),
                              _c("source", {
                                directives: [
                                  { name: "lazy-load", rawName: "v-lazy-load" }
                                ],
                                attrs: {
                                  "data-srcset": _vm.image(null, true),
                                  media:
                                    "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                                  type: "image/jpeg"
                                }
                              }),
                              _vm._v(" "),
                              _c("source", {
                                directives: [
                                  { name: "lazy-load", rawName: "v-lazy-load" }
                                ],
                                attrs: {
                                  "data-srcset": _vm.image("webp"),
                                  type: "image/webp"
                                }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  { name: "lazy-load", rawName: "v-lazy-load" }
                                ],
                                attrs: {
                                  width: "500",
                                  height: "500",
                                  "data-src": _vm.image(),
                                  alt: _vm.data.image.alt
                                    ? _vm.data.image.alt
                                    : "alt"
                                }
                              })
                            ])
                          : _vm._e()
                      ]
                ],
            _vm._v(" "),
            _vm.data.title
              ? _c(
                  "h3",
                  { staticClass: "text-5" },
                  [
                    _vm.isFile
                      ? [
                          this.loggedIn || !_vm.blok.protected_file
                            ? [
                                _c(
                                  "a",
                                  {
                                    staticClass: "icon-download",
                                    attrs: {
                                      href: _vm.blok.file.filename,
                                      target: "_blank",
                                      rel: "noopener"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.data.title))]
                                )
                              ]
                            : [
                                _c(
                                  "a",
                                  {
                                    staticClass: "icon-download",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.showLoginModal($event)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.data.title))]
                                )
                              ]
                        ]
                      : _vm.data.link && _vm.data.link.cached_url
                      ? [
                          _vm.externalDownload
                            ? [
                                _c(
                                  "nuxt-link",
                                  {
                                    staticClass: "icon-download",
                                    attrs: {
                                      to: "/" + _vm.data.link.cached_url + "/",
                                      target: "_blank",
                                      rel: "noopener"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.data.title))]
                                )
                              ]
                            : [
                                _vm.data.link && _vm.data.link.cached_url
                                  ? _c(
                                      "nuxt-link",
                                      {
                                        attrs: {
                                          to:
                                            "/" + _vm.data.link.cached_url + "/"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.data.title))]
                                    )
                                  : _vm._e()
                              ]
                        ]
                      : [
                          _vm._v(
                            "\n\t\t\t\t" + _vm._s(_vm.data.title) + "\n\t\t\t"
                          )
                        ],
                    _vm._v(" "),
                    _vm.data.bookmark === true &&
                    _vm.data.link &&
                    _vm.data.link.cached_url
                      ? _c("Bookmark", {
                          attrs: { id: _vm.data.link.cached_url }
                        })
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data.sub_title
              ? _c(
                  "h4",
                  { staticClass: "sub-title" },
                  [
                    _vm.isFile
                      ? [
                          _vm.loggedIn || !_vm.blok.protected_file
                            ? [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.blok.file.filename,
                                      target: "_blank",
                                      rel: "noopener"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.data.sub_title))]
                                )
                              ]
                            : [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#", target: "_blank" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.showLoginModal($event)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.data.sub_title))]
                                )
                              ]
                        ]
                      : [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(_vm.data.sub_title) +
                              "\n\t\t\t"
                          )
                        ],
                    _vm._v(" "),
                    _vm.data.sub_title_2
                      ? [
                          _c("br"),
                          _vm._v(_vm._s(_vm.data.sub_title_2) + "\n\t\t\t")
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data.blurb
              ? _c("p", [_vm._v(_vm._s(_vm.data.blurb))])
              : _vm._e(),
            _vm._v(" "),
            _vm.description
              ? _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } })
              : _vm._e(),
            _vm._v(" "),
            _vm.data.cta_link && _vm.data.cta_link.cached_url
              ? _c(
                  "nuxt-link",
                  {
                    staticClass: "primary-button",
                    attrs: { to: "/" + _vm.data.cta_link.cached_url + "/" }
                  },
                  [_vm._v(_vm._s(_vm.data.cta_text))]
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }