var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "slider-block"
    },
    [
      _vm.blok.section_title
        ? _c("h2", { staticClass: "section-title" }, [
            _vm._v(_vm._s(_vm.blok.section_title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ol",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ],
          staticClass: "slider"
        },
        _vm._l(_vm.blok.item, function(item, index) {
          return _c("SliderItem", {
            key: item._uid,
            attrs: { index: index, blok: item }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }