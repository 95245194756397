<template>
	<div v-editable="blok" class="timeline-block">
		<h2 class="section-title">{{ blok.title }}</h2>

		<ol v-editable="blok" class="timeline">
			<TimelineItem :key="item._uid" v-for="(item, index) in blok.item" :index="index" :blok="item" />
		</ol>
	</div>
</template>

<style lang="scss">
.timeline-block {
	margin: 0 auto var(--margin-bottom);

	.section-title {
		margin-inline-end: auto;
		margin-inline-start: auto;
		width: min(var(--content-width), 100%);
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.timeline {
		--margin-bottom: 50px;
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.timeline {
		--margin-bottom: 100px;
	}
}

/* Large Screen & Up */
@media all and #{$mq-l-min} {
	.timeline {
	}
}
</style>

<script>
export default {
	components: {
		TimlineItem: () => import('@/components/TimelineItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
