<template>
	<div v-editable="blok" class="callout" :class="[blok.add_divider && 'callout--top-divider']">
		<div v-html="description"></div>

		<nuxt-link v-if="blok.cta_secondary_link && blok.cta_secondary_link.cached_url" :to="'/' + blok.cta_secondary_link.cached_url + '/'" class="secondary-button">{{ blok.cta_secondary_text }}</nuxt-link>
		<a v-if="blok.cta_primary_text=='Contact Us'" href="'/' + blok.cta_primary_link.cached_url + '/'" class="primary-button" @click.stop.prevent="contactForm">{{ blok.cta_primary_text }}</a>
		<nuxt-link v-else-if="blok.cta_primary_link && blok.cta_primary_link.cached_url" :to="'/' + blok.cta_primary_link.cached_url + '/'" class="primary-button">{{ blok.cta_primary_text }}</nuxt-link>
	</div>
</template>

<style lang="scss">
.callout {
	border-block-end: 2px solid var(--gray-lite);
	margin: 0 auto var(--margin-bottom);
	padding: var(--padding-top) 0 var(--padding-bottom);
	width: min(var(--content-width), 100%);

	&--top-divider {
		border-block-start: 2px solid var(--gray-lite);
	}

	a {
		margin: 10px 0;
	}

	p {
		--min-font: 1.75;
		--max-font: 2.25;
		font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
		margin-block-end: 1em;
	}

	.secondary-button {
		margin-inline-end: 15px;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.callout {
		--margin-bottom: 50px;
		--padding-bottom: 30px;
		--padding-top: 10px;
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.callout {
		--margin-bottom: 150px;
		--padding-bottom: 70px;
		--padding-top: 60px;
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		contactForm: function() {
			this.showNav = false
			this.$bus.$emit('openContactModal')
		}
	},
	mounted() {},
	computed: {
		description() {
			if (this.blok.text && this.blok.text.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		}
	}
}
</script>
