<template>
	<div v-editable="blok" class="tile-item" data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
		<div class="tile-item__image">
			<picture>
				<source v-lazy-load :data-srcset="image('webp')" type="image/webp" /> 
				<source v-lazy-load :data-srcset="image(null)" type="image/jpeg" />
				<img v-lazy-load :data-src="image()" width="865" height="888" :alt="blok.title" />
			</picture>
		</div>
		<div class="tile-item__content">
			<h3 v-if="blok.title" class="tile-item__title">{{blok.title}}</h3>
			<div v-if="description" class="tile-item__description" v-html="description"></div>
		</div>
	</div>
</template>

<style lang="scss">
	.tile-item {
		&__image {
			overflow: hidden;
			img {
				transition: all 1s ease-in-out;
			}

			&:hover {
				img {
					// transform: scale(1.1);
				}
			}
		}

		&__title {
			font-size: 28px;
			line-height: 36px;
			margin: 30px 0px;
		}

		@media (max-width: 1024px) {
			margin-bottom: 40px;
		}
	}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null) {
			if (this.blok && this.blok.image && this.blok.image.filename) {
				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		description() {
			if (this.blok.description) {
				return this.$storyapi.richTextResolver.render(this.blok.description).replace(/\|/g, "<br />");
			}
		},
	}
}
</script>
