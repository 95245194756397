<template>
	<transition name="fade">
		<div v-if="typeof index === 'number'" class="light-gallery" @touchstart="touchstartHandler" @touchmove="touchmoveHandler" @touchend="touchendHandler">
			<div class="light-gallery__modal" :style="`background: ${background}`">
				<div :class="['light-gallery__spinner', !isImageLoaded || 'hide']">
					<div class="light-gallery__dot" :style="`border-color: ${interfaceColor}`" />
					<div class="light-gallery__dot" :style="`border-color: ${interfaceColor}`" />
					<div class="light-gallery__dot" :style="`border-color: ${interfaceColor}`" />
				</div>
				<div class="light-gallery__container">
					<ul class="light-gallery__content">
						<li v-for="(image, imageIndex) in formattedImages" :key="imageIndex" :style="`transform: translate3d(${currentIndex * -100}%, 0px, 0px);`" class="light-gallery__image-container">
							<figure class="light-gallery__image">
								<figcaption v-show="image.title && isImageLoaded" class="light-gallery__text">
									<p>{{ image.title }}</p>
								</figcaption>
								<img :ref="`lg-img-${imageIndex}`" v-lazy-load :data-src="shouldPreload(imageIndex) ? image.url : false" @load="imageLoaded($event, imageIndex)" />
							</figure>
						</li>
					</ul>
				</div>
				<button v-if="currentIndex > 0" class="light-gallery__prev" @click="prev()">Next</button>
				<button v-if="currentIndex + 1 < images.length" class="light-gallery__next" @click="next()">Previous</button>
				<button class="light-gallery__close" @click="close()">Close</button>
			</div>
		</div>
	</transition>
</template>

<script>
const keyMap = {
	LEFT: 37,
	RIGHT: 39,
	ESC: 27
}

export default {
	props: {
		images: {
			type: Array,
			default: () => []
		},
		index: {
			type: Number,
			default: 1
		},
		disableScroll: {
			type: Boolean,
			default: false
		},
		background: {
			type: String,
			default: 'rgba(0, 0, 0, 0.8)'
		},
		interfaceColor: {
			type: String,
			default: 'rgba(255, 255, 255, 0.8)'
		}
	},
	data() {
		return {
			currentIndex: this.index,
			isImageLoaded: false,
			bodyOverflowStyle: '',
			touch: {
				count: 0,
				x: 0,
				y: 0,
				multitouch: false,
				flag: false
			}
		}
	},
	computed: {
		formattedImages() {
			return this.images.map(image => (typeof image === 'string' ? { url: image } : image))
		}
	},
	watch: {
		index(val) {
			if (!document) return

			this.currentIndex = val

			if (this.disableScroll && typeof val === 'number') {
				document.body.style.overflow = 'hidden'
			} else if (this.disableScroll && !val) {
				document.body.style.overflow = this.bodyOverflowStyle
			}
		},
		currentIndex(val) {
			this.setImageLoaded(val)
		}
	},
	mounted() {
		if (!document) return

		this.bodyOverflowStyle = document.body.style.overflow
		this.bindEvents()
	},
	beforeDestroy() {
		if (!document) return

		if (this.disableScroll) {
			document.body.style.overflow = this.bodyOverflowStyle
		}
		this.unbindEvents()
	},
	methods: {
		close() {
			this.$emit('close')
		},
		prev() {
			if (this.currentIndex === 0) return
			this.currentIndex -= 1
			this.$emit('slide', { index: this.currentIndex })
		},
		next() {
			if (this.currentIndex === this.images.length - 1) return
			this.currentIndex += 1
			this.$emit('slide', { index: this.currentIndex })
		},
		imageLoaded($event, imageIndex) {
			const { target } = $event
			target.classList.add('loaded')

			if (imageIndex === this.currentIndex) {
				this.setImageLoaded(imageIndex)
			}
		},
		getImageElByIndex(index) {
			const elements = this.$refs[`lg-img-${index}`] || []
			return elements[0]
		},
		setImageLoaded(index) {
			const el = this.getImageElByIndex(index)
			this.isImageLoaded = !el ? false : el.classList.contains('loaded')
		},
		shouldPreload(index) {
			const el = this.getImageElByIndex(index) || {}
			const { src } = el

			return !!src || index === this.currentIndex || index === this.currentIndex - 1 || index === this.currentIndex + 1
		},
		bindEvents() {
			document.addEventListener('keydown', this.keyDownHandler, false)
		},
		unbindEvents() {
			document.removeEventListener('keydown', this.keyDownHandler, false)
		},
		touchstartHandler(event) {
			this.touch.count += 1
			if (this.touch.count > 1) {
				this.touch.multitouch = true
			}
			this.touch.x = event.changedTouches[0].pageX
			this.touch.y = event.changedTouches[0].pageY
		},
		touchmoveHandler(event) {
			if (this.touch.flag || this.touch.multitouch) return

			const touchEvent = event.touches[0] || event.changedTouches[0]

			if (touchEvent.pageX - this.touch.x > 40) {
				this.touch.flag = true
				this.prev()
			} else if (touchEvent.pageX - this.touch.x < -40) {
				this.touch.flag = true
				this.next()
			}
		},
		touchendHandler() {
			this.touch.count -= 1
			if (this.touch.count <= 0) {
				this.touch.multitouch = false
			}
			this.touch.flag = false
		},
		keyDownHandler(event) {
			switch (event.keyCode) {
				case keyMap.LEFT:
					this.prev()
					break
				case keyMap.RIGHT:
					this.next()
					break
				case keyMap.ESC:
					this.close()
					break
				default:
					break
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.light-gallery {
	&__modal {
		position: fixed;
		display: block;
		z-index: 1001;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__content {
		height: 100%;
		width: 100%;
		white-space: nowrap;
		padding: 0;
		margin: 0;
	}

	&__container {
		position: absolute;
		z-index: 1002;
		display: block;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	&__image-container {
		//display: inline-table;
		display: inline-block;
		//height: 100%;
		height: 100vh;
		position: relative;
		text-align: center;
		transition: left 0.4s ease, transform 0.4s ease, -webkit-transform 0.4s ease;
		vertical-align: middle;
		width: 100%;
	}

	&__image {
		& {
			//display: inline-block;
			display: grid;
			height: 100%;
			place-items: center;
			position: relative;
			margin: 0 auto;
			width: 100%;
		}

		& img {
			& {
				max-height: 80vh;
				object-fit: contain;
				opacity: 0;
				transition: opacity 0.2s;
				width: min(1440px, 100%);
			}

			&.loaded {
				opacity: 1;
			}
		}
	}

	&__text {
		background-color: #000;
		bottom: 0;
		box-sizing: border-box;
		color: #fff;
		display: block;
		left: 0;
		line-height: 1.2;
		margin: 0 auto;
		padding: 12px 30px;
		position: absolute;
		white-space: initial;
		width: 100%;
		z-index: 1000;

		p {
			font-size: 1rem;
			margin: 0 auto;
			width: min(var(--content-width), 100%);
		}
	}

	&__next,
	&__prev,
	&__close {
		background: transparent;
		border: 0;
		cursor: pointer;
		display: block;
		line-height: 0;
		outline: none;
		overflow: hidden;
		padding: 0;
		position: absolute;
		text-indent: 100%;
		white-space: nowrap;
		z-index: 1002;
	}

	&__close {
		background: transparent url(/img/ui/icon-close.svg) 50% no-repeat;
		filter: invert(1);
		height: 44px;
		right: 18px;
		top: 18px;
		width: 44px;
	}

	&__next,
	&__prev {
		background: var(--white) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18'%3E%3Cpath fill='none' stroke='%23000' stroke-width='2' transform='rotate(90 4.75 9.125)' d='M-3.125 12.875l7.774-7.5 7.976 7.5'/%3E%3Cdefs /%3E%3C/svg%3E") center center no-repeat;
		border-radius: 50%;
		height: 36px;
		width: 36px;
	}

	&__prev {
		left: 30px;
		top: 50%;
		transform: translate(0, -50%) scale(-1);
	}

	&__next {
		right: 30px;
		top: 50%;
		transform: translate(0, -50%);
	}

	&__spinner {
		& {
			position: absolute;
			z-index: 1003;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0 auto;
			display: block;
			height: 15px;
			width: auto;
			box-sizing: border-box;
			text-align: center;
		}

		&.hide {
			display: none;
		}
	}

	&__dot {
		& {
			float: left;
			margin: 0 calc(15px / 2);
			width: 15px;
			height: 15px;
			border: calc(15px / 5) solid rgba(255, 255, 255, 0.8);
			border-radius: 50%;
			transform: scale(0);
			box-sizing: border-box;
			animation: spinner-animation 1000ms ease infinite 0ms;
		}

		&:nth-child(1) {
			animation-delay: calc(300ms * 1);
		}

		&:nth-child(2) {
			animation-delay: calc(300ms * 2);
		}

		&:nth-child(3) {
			animation-delay: calc(300ms * 3);
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	position: fixed;
	z-index: 1000;
	transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
	position: fixed;
	opacity: 0;
	z-index: 1000;
}

@keyframes spinner-animation {
	50% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
