<template>
	<div class="text-media" v-editable="blok">
		<div class="text-media__content" :class="[blok.align_content_bottom && 'u--align-bottom']">
			<Stat :key="item._uid" v-for="item in blok.stat" :blok="item" :is="item.component | dashify" />

			<h2 v-if="blok.title">{{ blok.title }}</h2>

			<p v-if="blok.description && !rteDescription">{{ blok.description }}</p>

			<div v-if="rteDescription && !blok.description" v-html="rteDescription"></div>
		</div>

		<picture v-if="image()">
			<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
			<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
			<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
			<img v-lazy-load :data-src="image()" :alt="blok.image.alt" />
		</picture>
	</div>
</template>

<style lang="scss">
.text-media {
	--margin-bottom: 60px;
	margin: 0 auto var(--margin-bottom);
	max-width: var(--content-width);

	&__content {
		h2 {
			margin-block-end: 0.75em;
		}

		p:not(.stat):last-of-type {
			margin-block-end: 0;
		}
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.text-media {
		display: flex;
		flex-direction: column;

		picture {
			margin-block-end: 20px;
			order: -1;
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.text-media {
		@include grid();

		&:nth-of-type(odd) {
			.text-media__content {
				grid-column: 1 / span 4;
			}

			picture {
				grid-column: 6 / span 7;
			}
		}
		&:nth-of-type(even) {
			> * {
				grid-row: 1;
			}

			.text-media__content {
				grid-column: 9 / span 4;
			}

			picture {
				grid-column: 1 / span 7;
			}
		}

		&__content {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
		}

		/* Aligns content to bottom of container */
		.u--align-bottom {
			justify-content: flex-end;
		}
	}
}
</style>

<script>
export default {
	components: {
		Stat: () => import('@/components/Stat.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 764
				height = 0 // variable height

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {},
	computed: {
		rteDescription() {
			if (this.blok.rte_description && this.blok.rte_description.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.rte_description)
			}
		}
	}
}
</script>
