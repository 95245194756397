<template>
	<main v-if="loggedIn || !blok.protected" id="content" v-editable="blok">
		<component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify"></component>
	</main>
	<main v-else id="content" class="wait" v-editable="blok">
		<div class="content-container">
			<user-login :blok="blok"></user-login>
		</div>
	</main>
</template>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	head() {
		if (this.blok.meta) {
			var meta = this.blok.meta
		}

		if (this.blok.overlap_content) {
			var bodyBgColor = JSON.parse('{ "color":"bg-beige" }')
		}

		const headObj = { ...bodyBgColor, ...meta }

		if (headObj) {
			return {
				bodyAttrs: {
					class: headObj.color
				},
				title: headObj.title,
				meta: [
					{
						hid: 'description',
						name: 'description',
						content: headObj.description
					},
					{
						hid: 'og:title',
						property: 'og:title',
						content: headObj.og_title
					},
					{
						hid: 'og:image',
						property: 'og:image',
						content: headObj.og_image
					},
					{
						hid: 'og:description',
						property: 'og:description',
						content: headObj.og_description
					},
					{
						hid: 'twitter:title',
						name: 'twitter:title',
						content: headObj.twitter_title
					},
					{
						hid: 'twitter:image',
						name: 'twitter:image',
						content: headObj.twitter_image
					},
					{
						hid: 'twitter:description',
						name: 'twitter:description',
						content: headObj.twitter_description
					}
				]
			}
		}
	},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		}
	},
	methods: {},
	props: ['blok']
}
</script>
