var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "bio-item",
      attrs: {
        "data-aos": "fade",
        "data-aos-offset": "-150",
        "data-aos-delay": "0",
        "data-aos-duration": "700",
        "data-aos-once": "true",
        "data-aos-anchor-placement": "top-center"
      }
    },
    [
      _vm.blok.image.filename
        ? _c("img", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-src": _vm.blok.image.filename,
              alt: _vm.blok.image.alt
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.blok.text ? _c("p", [_vm._v(_vm._s(_vm.blok.text))]) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }