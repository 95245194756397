var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "bs-text-image",
      style: _vm.background()
    },
    [
      _c(
        "div",
        {
          staticClass: "bs-text-image__wrapper",
          class: _vm.blok.image_position
        },
        [
          _c("div", { staticClass: "bs-text-image__image" }, [
            _c("picture", [
              _c("img", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                staticClass: "slideimage",
                attrs: {
                  width: "721",
                  height: "840",
                  "data-src": this.blok.image.filename,
                  alt: ""
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "bs-text-image__text",
              style: _vm.textColor(),
              attrs: {
                "data-aos": "fade",
                "data-aos-offset": "0",
                "data-aos-delay": "0",
                "data-aos-duration": "700",
                "data-aos-once": "true",
                "data-aos-anchor-placement": "top-center"
              }
            },
            [
              _vm.blok.category
                ? _c("div", {
                    staticClass: "bs-text-image__pre",
                    domProps: { innerHTML: _vm._s(_vm.blok.category) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.blok.title
                ? _c("h2", {
                    staticClass: "bs-text-image__title",
                    domProps: { innerHTML: _vm._s(_vm.blok.title) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.description
                ? _c("div", {
                    staticClass: "bs-text-image__description",
                    domProps: { innerHTML: _vm._s(_vm.description) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.note
                ? _c("div", {
                    staticClass: "bs-text-image__note",
                    domProps: { innerHTML: _vm._s(_vm.note) }
                  })
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }