<template>
	<div v-editable="blok" class="video-clip" ref="videoClip">
		<video :loop="blok.loop" muted playsinline class="video-clip__video" v-lazy-load :data-poster="blok.poster.filename" :aria-label="blok.aria_description" ref="video" autoplay>
			<source :data-src="blok.mp4.filename" type="video/mp4" ref="videoSrc" />
		</video>
	</div>
</template>

<style lang="scss">
.video-clip {
	margin: 0 auto var(--margin-bottom);
	position: relative;
	width: min(var(--content-width), 100%);
}
.video-clip__video {
	display: block;
	height: 100%;
	object-fit: cover;
	width: 100%;
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.video-clip {
		--margin-bottom: 50px;
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.video-clip {
		--margin-bottom: 100px;
	}
}
</style>

<script>
export default {
	props: ['blok'],
	methods: {
		loadMedia: function() {
			if (!this.video.hasAttribute('src')) {
				this.video.setAttribute('src', this.videoSrc)
			}
		}
	},
	mounted() {
		this.videoClip = this.$refs.videoClip
		this.video = this.$refs.video
		this.videoSrc = this.$refs.videoSrc.getAttribute('data-src')

		if (this.$motionPref == true) {
			let observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry['isIntersecting'] == true) {
						this.loadMedia()
						this.video.play()
					} else {
						this.video.pause()
					}
				})
			})
			observer.observe(this.videoClip)
		}

		this.$bus.$on('stopVideoClip', () => {
			if (this.video) {
				this.video.pause()
			}
		})

		this.$bus.$on('playVideoClip', () => {
			if (this.video) {
				this.video.play()
			}
		})
	}
}
</script>
