var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "interactive-block-parent" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "editable",
            rawName: "v-editable",
            value: _vm.blok,
            expression: "blok"
          }
        ],
        staticClass: "interactive-block__mobile",
        style: _vm.backgroundStyle(),
        attrs: {
          "data-aos": "fade",
          "data-aos-offset": "-150",
          "data-aos-delay": "0",
          "data-aos-duration": "700",
          "data-aos-once": "true",
          "data-aos-anchor-placement": "top-center"
        }
      },
      [
        _c("div", { staticClass: "interactive-block__content" }, [
          _vm.blok.Number
            ? _c("span", { staticClass: "interactive-block__number" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.blok.Number) + "\n\t\t\t")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.text
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })
            : _vm._e()
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "editable",
            rawName: "v-editable",
            value: _vm.blok,
            expression: "blok"
          }
        ],
        staticClass: "interactive-block",
        style: _vm.backgroundStyle()
      },
      [
        _c("div", { staticClass: "interactive-block__content" }, [
          _vm.blok.Number
            ? _c("span", { staticClass: "interactive-block__number" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.blok.Number) + "\n\t\t\t")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.text
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.hasAtLeastOneItem()
          ? _c(
              "div",
              {
                staticClass: "interactive-block__selector",
                class: _vm.hasItems() ? "" : "hide"
              },
              [
                _c(
                  "a",
                  { staticClass: "interactive-block__selector--toggle" },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "16px",
                          height: "10px",
                          viewBox: "0 0 16 10",
                          version: "1.1",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "PDP",
                              stroke: "none",
                              "stroke-width": "1",
                              fill: "none",
                              "fill-rule": "evenodd"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "reconfigure-3",
                                  transform:
                                    "translate(-1283.000000, -3155.000000)",
                                  fill: "#151515"
                                }
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Group",
                                      transform:
                                        "translate(1194.000000, 3139.000000)"
                                    }
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          transform:
                                            "translate(89.000000, 16.000000)",
                                          id: "drop-down-arrow"
                                        }
                                      },
                                      [
                                        _c("polygon", {
                                          attrs: {
                                            id: "Fill-1",
                                            transform:
                                              "translate(8.000000, 4.801876) rotate(-270.000000) translate(-8.000000, -4.801876) ",
                                            points:
                                              "3.19812391 11.6389217 10.400938 4.79249563 3.21688124 -2.05392807 4.39859293 -3.19812391 12.8018761 4.79249563 4.3798356 12.8018761"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "interactive-block__selector--buttons" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "interactive-block__selector--buttonsList"
                      },
                      _vm._l(_vm.blok.items, function(blok) {
                        return _c(
                          "button",
                          {
                            class: _vm.isSelectedColor(blok.color_label)
                              ? "selected-color"
                              : false,
                            attrs: { "data-color": blok.color_label },
                            on: { click: _vm.changeSelectedColor }
                          },
                          [
                            _c("span", {
                              staticClass: "swatch",
                              style: blok.swatch
                                ? "background-image:url(" +
                                  blok.swatch.filename +
                                  ")"
                                : "background-color:" + blok.color.color
                            }),
                            _vm._v(
                              " \n\t\t\t\t\t\t" +
                                _vm._s(blok.color_label) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasAtLeastOneItem()
          ? _vm._l(_vm.blok.items, function(blok) {
              return _c(_vm._f("dashify")(blok.component), {
                key: blok._uid,
                tag: "component",
                attrs: {
                  selected: _vm.isSelectedColor(blok.color_label),
                  blok: blok
                }
              })
            })
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }