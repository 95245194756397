<template>
	<div v-editable="blok" class="instagram">
		<h2 class="section-title">Instagram</h2>
		<h3 class="text-2">{{ blok.title }}</h3>

		<div class="instagram__items">
			<div v-for="item in instagramItems" :key="item.id">
				<a v-if="item.link" :href="item.link" rel="noopener" target="_blank">
					<img v-lazy-load :data-src="imageSource(item)" :alt="item.accessibilityCaption">
				</a>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">

.instagram {
	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	h3 {
		margin-bottom: 20px;
	}

	a {
		display: block;

		&::after {
			display: none;
		}
	}
}
.instagram__items {
	@include grid(2);
	overflow: hidden;
	row-gap: 20px;
}

@include mq(l) {
	.instagram {
		h3 {
			margin-bottom: 40px;
		}
	}
	.instagram__items {
		@include grid(3);
	}
}
</style>

<script>
export default {
	data() {
		return {
			instagramItems: []
		}
	},
	props: ['blok'],
	methods: {
		async loadInstagramData() {
			const items = await this.$axios.get(process.env.instagramEndpoint)
			if (items.data && items.data.length)
				this.instagramItems = items.data.slice(0, 6);
		},
		imageSource(item) {
			if (!item) return

			let source

			// iterate through thumbnails for the best size image to use
			for (const index in item.thumbnails) {
				const thumb = item.thumbnails[index]
				if (parseInt(thumb.config_width) >= 428) {
					source = thumb.src
					break
				}
			}

			if (source)
				return source
		}
	},
	created() {
		this.loadInstagramData()
	},
	mounted() {},
	computed: {}
}
</script>
