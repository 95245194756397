var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "course"
    },
    [
      _vm.image()
        ? _c("picture", [
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                "data-srcset": _vm.image("webp", true),
                media:
                  "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                type: "image/webp"
              }
            }),
            _vm._v(" "),
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                "data-srcset": _vm.image(null, true),
                media:
                  "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                type: "image/jpeg"
              }
            }),
            _vm._v(" "),
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: { "data-srcset": _vm.image("webp"), type: "image/webp" }
            }),
            _vm._v(" "),
            _c("img", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: { "data-src": _vm.image(), alt: _vm.blok.image.alt }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "course__meta" }, [
        _c("h2", [_vm._v(_vm._s(_vm.blok.title))]),
        _vm._v(" "),
        _c("h3", { staticClass: "sub-title" }, [_vm._v("Program Level")]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.blok.program_level))]),
        _vm._v(" "),
        _c("h3", { staticClass: "sub-title" }, [_vm._v("Course Credits")]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.blok.course_credits))]),
        _vm._v(" "),
        _vm.blok.cta_link && _vm.blok.cta_link.cached_url
          ? _c(
              "a",
              {
                staticClass: "primary-button",
                attrs: {
                  href: _vm.blok.cta_link.cached_url,
                  target: "_blank",
                  rel: "noopener"
                }
              },
              [_vm._v(_vm._s(_vm.blok.cta_text))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.description1
          ? _c(
              "button",
              {
                staticClass: "primary-link",
                attrs: { type: "button", "aria-expanded": _vm.showDetails },
                on: {
                  click: function($event) {
                    ;(_vm.showDetails = !_vm.showDetails),
                      (_vm.aria = !_vm.aria)
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.showDetails == false ? "Show" : "Hide") +
                    " Details"
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.description1
        ? _c(
            "div",
            {
              staticClass: "course__description",
              class: _vm.showDetails == true ? "active" : "",
              attrs: { "aria-hidden": _vm.aria }
            },
            [
              _vm.description1
                ? _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.description1) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.description2
                ? _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.description2) }
                  })
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }