<template>
	<div v-editable="blok" class="sectioned-text">
		<h4 class="section-title">{{ blok.group_title }}</h4>
		<div v-if="content" v-html="content"></div>
	</div>
</template>

<style lang="scss">
.sectioned-text {
	margin: 0 auto 50px;
	width: min(var(--content-width), 100%);

	.accordion & {
		margin-block-end: 0;
	}

	h4 {
		margin: 0.35em 0 1em;
	}

	> div {
		counter-reset: questions;
	}

	h5 {
		--min-font: 1.5;
		--max-font: 1.75;

		margin-block-end: 0.5em;

		// &::before {
		// 	content: counter(questions) '. ';
		// 	counter-increment: questions;
		// }
	}
	h5 ~ h5 {
		margin-block-start: 1.25em;
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.sectioned-text {
		@include grid();

		h4 {
			grid-column: 1 / span 4;
		}

		> div {
			grid-column: 5 / span 8;
		}
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		content() {
			if (this.blok.content) {
				return this.$storyapi.richTextResolver.render(this.blok.content)
			}
		}
	}
}
</script>
