var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ]
    },
    [
      _c("div", { staticClass: "details-list__content" }, [
        _c("h2", { staticClass: "section-title" }, [
          _vm._v(_vm._s(_vm.blok.section_title))
        ]),
        _vm._v(" "),
        _vm.blok.title
          ? _c("h3", { staticClass: "text-2" }, [
              _vm._v(_vm._s(_vm.blok.title))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.blok.description))])
      ]),
      _vm._v(" "),
      _vm.image()
        ? _c("picture", [
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                "data-srcset": _vm.image("webp", true),
                media:
                  "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                type: "image/webp"
              }
            }),
            _vm._v(" "),
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                "data-srcset": _vm.image(null, true),
                media:
                  "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                type: "image/jpeg"
              }
            }),
            _vm._v(" "),
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: { "data-srcset": _vm.image("webp"), type: "image/webp" }
            }),
            _vm._v(" "),
            _c("img", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                "data-src": _vm.image(),
                alt: _vm.blok.image.alt,
                width: "764",
                height: "500"
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }