var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product
    ? _c(
        "div",
        { staticClass: "solution-product" },
        [
          _vm.getProductSlug()
            ? _c(
                "nuxt-link",
                {
                  staticClass: "primary-link",
                  attrs: { to: "/" + _vm.getProductSlug() + "/" }
                },
                [
                  _vm.image()
                    ? _c("picture", [
                        _c("source", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" }
                          ],
                          attrs: {
                            "data-srcset": _vm.image("webp", true),
                            media:
                              "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                            type: "image/webp"
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" }
                          ],
                          attrs: {
                            "data-srcset": _vm.image(null, true),
                            media:
                              "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                            type: "image/jpeg"
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" }
                          ],
                          attrs: {
                            "data-srcset": _vm.image("webp"),
                            type: "image/webp"
                          }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" }
                          ],
                          attrs: {
                            "data-src": _vm.image(),
                            alt: _vm.product.name
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v("\n\t\t" + _vm._s(_vm.product.name) + "\n\t")
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }