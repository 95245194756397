<template>
	<header v-editable="blok" class="page-header-account">
		<div>
			<Breadcrumb :key="breadcrumb._uid" v-for="breadcrumb in blok.breadcrumb" :blok="breadcrumb" :is="breadcrumb.component | dashify"></Breadcrumb>

			<h1 v-if="blok.title">{{ blok.title }}</h1>

			<nuxt-link v-if="blok.profile_link" to="/account/profile">Edit Profile</nuxt-link>

			<div v-if="description" v-html="description"></div>
		</div>

		<div>
			<button v-if="blok.logout_button" type="button" class="primary-button" @click="logOut">Log Out</button>
		</div>
	</header>
</template>

<style lang="scss">
.page-header-account {
	margin: 0 auto 50px;
	width: min(var(--content-width), 100%);
}

@media all and #{$mq-s-max} {
	.page-header-account {
		display: grid;

		> div:first-of-type {
			order: 2;
		}
		.primary-button {
			margin-block-end: 30px;
		}
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.page-header-account {
		@include grid();

		> div:first-of-type {
			grid-column: 1 / span 6;
		}

		> div:last-of-type {
			grid-column: 7 / span 6;
		}

		.primary-button {
			display: flex;
			margin: 25px 0 0 auto;
		}
	}
}

@media all and #{$mq-l-min} {
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		logOut() {
			this.$auth.logout()
			this.$router.push('/')
		}
	},
	mounted() {},
	computed: {
		description() {
			if (this.blok.description && this.blok.description.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.description)
			}
		}
	}
}
</script>
