<template>
	<div v-editable="blok" class="text-media-block">
		<component :key="item._uid" v-for="item in blok.item" :blok="item" :is="item.component | dashify"></component>
	</div>
</template>

<style lang="scss">
/* Text Media styles defined in TextMediaItem.vue */
.text-media-block {
	padding: 0 0 var(--padding-bottom);
}
/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.text-media-block {
		--padding-bottom: 90px;
	}
}
</style>

<script>
export default {
	components: {
		Blockquote: () => import('@/components/Blockquote.vue'),
		TextMediaItem: () => import('@/components/TextMediaItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
