var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "bn-form"
    },
    [
      _c("h2", { staticClass: "section-title" }, [
        _vm._v(_vm._s(_vm.blok.title))
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        { ref: "filters", staticClass: "filters" },
        _vm._l(_vm.filters(), function(item) {
          return _c("div", { key: item._uid, staticClass: "form-field" }, [
            _c("input", {
              attrs: {
                id: "filter-" + item.uuid,
                "data-id": item.uuid,
                type: "checkbox",
                name: "checkbox-options"
              },
              on: { change: _vm.filterChange }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "for-checkbox",
                attrs: { for: "filter-" + item.uuid }
              },
              [_vm._v(_vm._s(item.name))]
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }