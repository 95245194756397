var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "stat"
    },
    [
      _c("em", [_vm._v(_vm._s(_vm.blok.value))]),
      _vm._v("\n\t" + _vm._s(_vm.blok.description) + "\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }