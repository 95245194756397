<template>
	<li v-editable="blok">
		<picture v-if="image()">
			<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
			<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
			<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
			<img v-lazy-load :data-src="blok.image.filename" width="764" height="535" :alt="blok.image.alt" />
		</picture>

		<h3 class="text-2">{{ blok.title }}</h3>

		<p>{{ blok.description }}</p>
	</li>
</template>

<style lang="scss">
.slider {
	--max-item-width: 535px;
	--min-item-width: 80vw;
	--side-space: 15px;

	column-gap: var(--col-gap);
	display: grid;
	grid-auto-flow: column;
	list-style: none;
	margin: 0 -15px var(--margin-bottom);
	overflow-x: auto;
	overscroll-behavior-x: contain;
	padding: 0 0 15px var(--side-space);
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	scrollbar-color: #fff var(--gray-lite);
	scrollbar-width: thin;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar-track {
		background: var(--gray-lite);
	}
	&::-webkit-scrollbar-thumb {
		background-color: var(--black);
	}
	&::-webkit-scrollbar {
		height: 4px;
	}
	&::-webkit-scrollbar-track {
		margin: 0 var(--side-space);
	}

	li {
		margin-block-end: 10px;
		scroll-snap-align: center;
	}
	li:not(:last-child) {
		width: min(var(--max-item-width), var(--min-item-width));
	}
	li:last-child {
		padding-right: var(--side-space);
		width: min(calc(var(--max-item-width) + var(--side-space)), calc(var(--min-item-width) + var(--side-space)));
	}

	picture {
		display: block;
		margin-block-end: 20px;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.slider {
		--col-gap: 20px;
		--margin-bottom: 50px;
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.slider {
		--col-gap: 40px;
		--margin-bottom: 100px;
	}
}

@media all and (min-width: 1420px) {
	.slider {
		--col-gap: 60px;
		--side-space: 40px;
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok', 'index'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 535
				height = 535

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {},
	computed: {}
}
</script>
