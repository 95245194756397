<template>
	<form v-editable="blok" class="bn-form">
		<h2 class="section-title">{{ blok.title }}</h2>

		<fieldset class="filters" ref="filters">
			<div class="form-field" :key="item._uid" v-for="item in filters()">
				<input :id="'filter-' + item.uuid" :data-id="item.uuid" type="checkbox" name="checkbox-options" @change="filterChange" />
				<label :for="'filter-' + item.uuid" class="for-checkbox">{{ item.name }}</label>
			</div>
		</fieldset>
	</form>
</template>

<style lang="scss"></style>

<script>
export default {
	components: {},
	data() {
		return {
		}
	},
	props: ['blok'],
	methods: {
		filterChange() {
			this.activeFilters()
		},
		activeFilters() {
			const active = []
			this.$refs.filters.querySelectorAll('input[type=checkbox]:checked').forEach(checkbox => {
				let productId = checkbox.getAttribute('data-id')
				active.push(productId)
			})

			// emit filter array so cards can update
			this.$bus.$emit('solutionsFilterProductsUsed', active)
		},
		filters() {
			let solutions
			const validFilters = []
			
			if (this.blok.category)
				solutions = this.$store.getters['solutions/getSolutionsByCategory'](this.blok.category)
			else
				solutions = this.$store.getters['solutions/getAllSolutions']

			if (solutions) {
				solutions.forEach(item => {
					// body components
					const body = item.content.body

					if (body) {
						body.forEach(item => {
							if (item.component === 'solution_details') {
								// get list of product uuids from this component
								const products = item.products

								if (products) {
									products.forEach(item => {
										if (item.component === 'solution_details_product') {
											if (item.product && item.product.id) {
												const product = this.$store.getters['products/getProductByUUID'](item.product.id)

												// prevent duplicate products
												if (!validFilters.includes(product))
													validFilters.push(product)
											}
										}
									})
								}
							}
						})
					}
				})
			}

			if (validFilters.length) {
				// make sure filters match the assigned product order, based on position in SB
				validFilters.sort((a, b) => {
					return parseInt(a.position) - parseInt(b.position)
				})

				return validFilters
			}
		},

	},
	mounted() {},
	computed: {
		
	}
}
</script>
