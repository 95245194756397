<template>
	<div v-editable="blok" :class="snapToTop()" class="is-hero product-media">
		<div v-if="blok.Video.filename" class="prod-video-wrapper">
			<video loop="true" muted playsinline :autoplay="blok.Autoplay" class="video-clip__video" ref="video">
				<source v-lazy-load :data-src="blok.Video.filename" type="video/mp4" ref="videoSrc" />
			</video>
			<div class="video-text">
				<h1 tabindex="-1">
					{{ blok.Text }}
					<img v-if="blok.image_top" v-lazy-load :data-src="blok.image_top.filename" width="600" :alt="blok.image_top.alt" />
					<span>{{ blok.Description }}</span>
					<a class="video-icon"  @click.prevent="showVideoModal(blok.link.cached_url)" >Play Video</a>
				</h1>
			</div>
		</div>
		<div v-else class="prod-image-wrapper">
			<img v-if="blok.Image" v-lazy-load :data-src="blok.Image.filename" :alt="blok.Image.alt" />
			<div class="image-text">
				<h2>
					{{ blok.Text }}
				</h2>
			</div>
		</div>
		<div class="product-tabs" id="tabs">
			<TabLinkBar :blok="blok"/>
		</div>
	</div>
</template>

<style lang="scss">
	.product-media.is-hero {
		width: 100%;
		// left: 50%;
		// right: 50%;
		// margin-left: -50vw;
		// margin-right: -50vw;
		position: relative;
		.video-text {
			position: absolute;
			width: 100%;
			left:0;
			top: 50%;
			transform: translateY(-60%);
			text-align: center;
			z-index: 3;
			h1 {
				color: var(--white);
				display: block;
				margin: 0;
				padding: 80px 20px 20px;
				pointer-events: auto;
				position: relative;
				text-decoration: none;
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
				// transition: all .5s ease;
				width: 100%;
				font-size: 40px;
				font-family: Teodor-Regular,Times New Roman,Times,serif;
				line-height: unset;
				margin-block-end: 0.4em;
				img {
					display: block;
					margin: 20px auto 0;
				}
				span {
					display: block;
					font-size: 34px;
					margin-top: 15px;
				}

				@media (max-width: 600px) {
					font-size: 34px;
					span {
						font-size: 30px;
					}
				}
			}

			.video-icon {
				background: #fff;
				background-position: 50%;
				background-size: cover;
				border-radius: 50%;
				display: block;
				height: 72px;
				margin: 40px auto 0;
				position: relative;
				text-indent: -99999px;
				width: 72px;
				cursor: pointer;
				&:after {
					background: url(/img/ui/icon-play-black.svg) no-repeat;
					background-position: 50%;
					background-size: cover;
					content: "";
					display: block;
					height: 23px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-40%,-50%);
					width: 22px;
				}
			}
			.scroll-hint {
				position: static;
				transform: none;
				margin-top: 100px;
			}
			
		}

		&.snap {
			// height: 52px;
			// .prod-video-wrapper {
			// 	height: 52px;

			// }
		}
	}

	.prod-video-wrapper {
		position: relative;
		z-index: 1;
		height: 100vh;
		overflow: hidden;
		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			background: rgba(0,0,0,0.4);
		}
	}

	.prod-image-wrapper {
		position: relative;
		z-index: 1;
		height: 100vh;
		overflow: hidden;
		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			background: rgba(0,0,0,0.3);
		}
		img {
			min-width: 100%;
			max-width: unset;
			min-height: 100%;
		}

		.image-text {
			position: absolute;
			width: 100%;
			left:0;
			top: 50%;
			transform: translateY(-70%);
			text-align: center;
			z-index: 3;
			h2 {
				color: var(--white);
				display: block;
				margin: 0;
				padding: 20px 0;
				pointer-events: auto;
				position: relative;
				text-decoration: none;
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
				// transition: all .5s ease;
				width: 100%;
				font-size: 100px;
				font-family: Teodor-Regular,Times New Roman,Times,serif;
				line-height: unset;
				margin-block-end: 0.4em;
			}
		}
	}

	.product-tabs {
		display: flex;
		width: 100%;
		position: relative;
		z-index: 5;
		align-items: flex-start;
		justify-content: center;
		margin-top: -52px;
	}
</style>

<script>
export default {
	components: {
		TabLinkBar: () => import("@/components/RollingOffice/TabLinkBar.vue"),
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		showVideoModal: function(url) {
			this.$bus.$emit('openVideoModal', { type: 'video', url: url })
		},
		snapToTop() {
			if(this.blok && this.blok.snap_to_top) {
				return ' snap';
			}
		}
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		text() {
			if (this.blok.Text) {
				return this.$storyapi.richTextResolver.render(this.blok.Text)
			}
		}
	}
}
</script>
