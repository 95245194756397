<template>
	<div v-editable="blok" :class="hasOnlyDescription()" class="megamenu-content">
        <template v-if="this.addGridItems()">
            <template v-if="this.threeColumns()">
                <div class="megamenu-columns three">
                    <component 
                        v-for="gridCard in getGridCards()" 
                        :key="gridCard._uid" 
                        :blok="gridCard" :is="gridCard.component | dashify">
                    </component>
                </div>
            </template>
            <template v-if="this.fourColumns()">
                <div class="megamenu-columns four">
                    <component 
                        v-for="gridCard in getGridCards()" 
                        :key="gridCard._uid" 
                        :blok="gridCard" :is="gridCard.component | dashify">
                    </component>
                </div>
            </template>
        </template>

        <template v-if="this.justDescription()">
            <div class="megamenu-content__wrapper">
                <div v-if="description" class="description" v-html="description"></div>
            </div>
        </template>
	</div>
</template>

<style lang="scss">
    .description-only .megamenu-content__wrapper {
        position: relative;
        &:after {
            content:"";
            display: block;
            position: absolute;
            top: 10px;
            right: 120px;
            height: 54%;
            width: 2px;
            border-radius: 1px;
            background: #CBCBCB;
        }
        .description {
            max-width: 740px;
            padding-bottom: 40px;
            p {
                font-family:'Teodor-Regular', 'Times New Roman', Times, serif;
                font-size: 30px;
                line-height: 44px;
                margin-bottom: 0;
                color: #151515;
                em, i {
                    font-style: normal;
                    color: #FF5601;
                }
            }
        }
    }
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
        addGridItems() { return this.blok.add_grid_items },
        threeColumns(){ return this.blok.grid_columns === 'three' },
        fourColumns(){ return this.blok.grid_columns === 'four' },
        getGridCards() {
            if (this.blok && this.blok.grid_card) {
                return this.blok.grid_card;
            }
            return {};
        },
        justDescription() { return this.blok.just_description },

        hasOnlyDescription() {
            if( this.blok && this.blok.just_description ) {
                return ' description-only ';
            }
        }
	},
	mounted() {
		console.debug(this.blok);
	},
	computed: {
		description() {
			if (this.blok && this.blok.description) {
				return this.$storyapi.richTextResolver.render(this.blok.description).replace(/\|/g, "<br />");
			}
		}
	}
}
</script>
