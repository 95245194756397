<template>
	<div v-editable="blok" class="profile-form">
		<form @submit.prevent="handleProfileUpdate" class="bn-form" :class="this.hasErrors ? 'has-errors' : false" method="post">
			<p class="note"><em>*</em> = required</p>

			<p v-if="this.errorServer" class="error">There was a problem updating your profile, please try again.</p>

			<fieldset>
				<div class="form-field">
					<label for="email">Email Address<em>*</em></label>
					<input type="email" id="email" name="email" required @invalid="invalidateForm" v-model="profile.email" />

					<button type="button" class="link-style toggle" :aria-expanded="togglePassword" @click=";(togglePassword = !togglePassword), (aria = !aria)">{{ togglePassword == false ? 'Change' : 'Keep' }} Password</button>
				</div>

				<div class="form-field"></div>

				<div class="field-group" :aria-hidden="aria" v-show="togglePassword">
					<div class="form-field" :class="{ 'has-error': errors('password') }" data-size="full">
						<label for="password">New Password<em>*</em></label>
						<input type="password" id="password" name="password" :required="togglePassword" @invalid="invalidateForm" v-model="password" />
						<p v-if="errors('password')" v-html="errors('password')" class="error"></p>
					</div>

					<div class="form-field" :class="{ 'has-error': errors('password_confirmation') }" data-size="full">
						<label for="password_confirmation">Confirm Password<em>*</em></label>
						<input type="password" id="password_confirmation" name="password_confirmation" :required="togglePassword" @invalid="invalidateForm" v-model="password_confirmation" />
						<p v-if="errors('password_confirmation')" v-html="errors('password_confirmation')" class="error"></p>
					</div>
				</div>

				<div class="form-field"></div>

				<div class="form-field" :class="{ 'has-error': errors('profile.firstname') }">
					<label for="firstname">First Name<em>*</em></label>
					<input type="text" id="firstname" name="firstname" maxlength="48" required @invalid="invalidateForm" v-model="profile.firstname" />
					<p v-if="errors('profile.firstname')" v-html="errors('profile.firstname')" class="error"></p>
				</div>

				<div class="form-field" :class="{ 'has-error': errors('profile.lastname') }">
					<label for="lastname">Last Name<em>*</em></label>
					<input type="text" id="lastname" name="lastname" maxlength="48" required @invalid="invalidateForm" v-model="profile.lastname" />
					<p v-if="errors('profile.lastname')" v-html="errors('profile.lastname')" class="error"></p>
				</div>

				<div class="form-field">
					<label for="job-title">Job Title</label>
					<input type="text" id="job-title" name="cjob-title" maxlength="128" v-model="profile.title" />
				</div>

				<div class="form-field">
					<label for="phone">Phone</label>
					<input type="tel" id="phone" name="phone" maxlength="12" v-model="profile.phone" />
				</div>

				<div class="form-field" :class="{ 'has-error': errors('profile.company') }">
					<label for="company">Company Name<em>*</em></label>
					<input type="text" id="company" name="company" maxlength="128" required @invalid="invalidateForm" v-model="profile.company" />
					<p v-if="errors('profile.company')" v-html="errors('profile.company')" class="error"></p>
				</div>

				<div class="form-field">
					<label for="company-type">Company Type</label>
					<select class="custom-select" id="company-type" name="company-type" v-model="profile.companytype">
						<option v-for="item in this.companyTypesData.datasource_entries" :key="item._uid" :value="item.value">{{ item.name }}</option>
					</select>
				</div>

				<div class="form-field" :class="{ 'has-error': errors('profile.city') }">
					<label for="city">City<em>*</em></label>
					<input type="text" id="city" name="city" maxlength="128" required @invalid="invalidateForm" v-model="profile.city" />
					<p v-if="errors('profile.city')" v-html="errors('profile.city')" class="error"></p>
				</div>

				<div class="field-group" :class="{ 'has-error': errors('profile.state') }" data-size="half">
					<div class="form-field">
						<label for="state">State<em>*</em></label>
						<select class="custom-select" id="state" name="state" required @invalid="invalidateForm" v-model="profile.state">
							<option value="N/A">N/A</option>
							<option value="AK">AK</option>
							<option value="AL">AL</option>
							<option value="AR">AR</option>
							<option value="AZ">AZ</option>
							<option value="CA">CA</option>
							<option value="CO">CO</option>
							<option value="CT">CT</option>
							<option value="DC">DC</option>
							<option value="DE">DE</option>
							<option value="FL">FL</option>
							<option value="GA">GA</option>
							<option value="HI">HI</option>
							<option value="IA">IA</option>
							<option value="ID">ID</option>
							<option value="IL">IL</option>
							<option value="IN">IN</option>
							<option value="KS">KS</option>
							<option value="KY">KY</option>
							<option value="LA">LA</option>
							<option value="MA">MA</option>
							<option value="MD">MD</option>
							<option value="ME">ME</option>
							<option value="MI">MI</option>
							<option value="MN">MN</option>
							<option value="MO">MO</option>
							<option value="MS">MS</option>
							<option value="MT">MT</option>
							<option value="NC">NC</option>
							<option value="ND">ND</option>
							<option value="NE">NE</option>
							<option value="NH">NH</option>
							<option value="NJ">NJ</option>
							<option value="NM">NM</option>
							<option value="NV">NV</option>
							<option value="NY">NY</option>
							<option value="OH">OH</option>
							<option value="OK">OK</option>
							<option value="OR">OR</option>
							<option value="PA">PA</option>
							<option value="PR">PR</option>
							<option value="RI">RI</option>
							<option value="SC">SC</option>
							<option value="SD">SD</option>
							<option value="TN">TN</option>
							<option value="TX">TX</option>
							<option value="UT">UT</option>
							<option value="VA">VA</option>
							<option value="VT">VT</option>
							<option value="WA">WA</option>
							<option value="WI">WI</option>
							<option value="WV">WV</option>
							<option value="WY">WY</option>
						</select>
						<p v-if="errors('profile.state')" v-html="errors('profile.state')" class="error"></p>
					</div>

					<div class="form-field">
						<label for="country">Country<em>*</em></label>
						<select class="custom-select" id="country" name="country" required v-model="profile.country">
							<option value="Afghanistan">Afghanistan</option>
							<option value="Åland Islands">Åland Islands</option>
							<option value="Albania">Albania</option>
							<option value="Algeria">Algeria</option>
							<option value="American Samoa">American Samoa</option>
							<option value="Andorra">Andorra</option>
							<option value="Angola">Angola</option>
							<option value="Anguilla">Anguilla</option>
							<option value="Antarctica">Antarctica</option>
							<option value="Antigua and Barbuda">Antigua and Barbuda</option>
							<option value="Argentina">Argentina</option>
							<option value="Armenia">Armenia</option>
							<option value="Aruba">Aruba</option>
							<option value="Australia">Australia</option>
							<option value="Austria">Austria</option>
							<option value="Azerbaijan">Azerbaijan</option>
							<option value="Bahamas">Bahamas</option>
							<option value="Bahrain">Bahrain</option>
							<option value="Bangladesh">Bangladesh</option>
							<option value="Barbados">Barbados</option>
							<option value="Belarus">Belarus</option>
							<option value="Belgium">Belgium</option>
							<option value="Belize">Belize</option>
							<option value="Benin">Benin</option>
							<option value="Bermuda">Bermuda</option>
							<option value="Bhutan">Bhutan</option>
							<option value="Bolivia">Bolivia</option>
							<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
							<option value="Botswana">Botswana</option>
							<option value="Bouvet Island">Bouvet Island</option>
							<option value="Brazil">Brazil</option>
							<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
							<option value="Brunei Darussalam">Brunei Darussalam</option>
							<option value="Bulgaria">Bulgaria</option>
							<option value="Burkina Faso">Burkina Faso</option>
							<option value="Burundi">Burundi</option>
							<option value="Cambodia">Cambodia</option>
							<option value="Cameroon">Cameroon</option>
							<option value="Canada">Canada</option>
							<option value="Cape Verde">Cape Verde</option>
							<option value="Cayman Islands">Cayman Islands</option>
							<option value="Central African Republic">Central African Republic</option>
							<option value="Chad">Chad</option>
							<option value="Chile">Chile</option>
							<option value="China">China</option>
							<option value="Christmas Island">Christmas Island</option>
							<option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
							<option value="Colombia">Colombia</option>
							<option value="Comoros">Comoros</option>
							<option value="Congo">Congo</option>
							<option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
							<option value="Cook Islands">Cook Islands</option>
							<option value="Costa Rica">Costa Rica</option>
							<option value="Cote D'ivoire">Cote D'ivoire</option>
							<option value="Croatia">Croatia</option>
							<option value="Cuba">Cuba</option>
							<option value="Cyprus">Cyprus</option>
							<option value="Czech Republic">Czech Republic</option>
							<option value="Denmark">Denmark</option>
							<option value="Djibouti">Djibouti</option>
							<option value="Dominica">Dominica</option>
							<option value="Dominican Republic">Dominican Republic</option>
							<option value="Ecuador">Ecuador</option>
							<option value="Egypt">Egypt</option>
							<option value="El Salvador">El Salvador</option>
							<option value="Equatorial Guinea">Equatorial Guinea</option>
							<option value="Eritrea">Eritrea</option>
							<option value="Estonia">Estonia</option>
							<option value="Ethiopia">Ethiopia</option>
							<option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
							<option value="Faroe Islands">Faroe Islands</option>
							<option value="Fiji">Fiji</option>
							<option value="Finland">Finland</option>
							<option value="France">France</option>
							<option value="French Guiana">French Guiana</option>
							<option value="French Polynesia">French Polynesia</option>
							<option value="French Southern Territories">French Southern Territories</option>
							<option value="Gabon">Gabon</option>
							<option value="Gambia">Gambia</option>
							<option value="Georgia">Georgia</option>
							<option value="Germany">Germany</option>
							<option value="Ghana">Ghana</option>
							<option value="Gibraltar">Gibraltar</option>
							<option value="Greece">Greece</option>
							<option value="Greenland">Greenland</option>
							<option value="Grenada">Grenada</option>
							<option value="Guadeloupe">Guadeloupe</option>
							<option value="Guam">Guam</option>
							<option value="Guatemala">Guatemala</option>
							<option value="Guernsey">Guernsey</option>
							<option value="Guinea">Guinea</option>
							<option value="Guinea-bissau">Guinea-bissau</option>
							<option value="Guyana">Guyana</option>
							<option value="Haiti">Haiti</option>
							<option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
							<option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
							<option value="Honduras">Honduras</option>
							<option value="Hong Kong">Hong Kong</option>
							<option value="Hungary">Hungary</option>
							<option value="Iceland">Iceland</option>
							<option value="India">India</option>
							<option value="Indonesia">Indonesia</option>
							<option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
							<option value="Iraq">Iraq</option>
							<option value="Ireland">Ireland</option>
							<option value="Isle of Man">Isle of Man</option>
							<option value="Israel">Israel</option>
							<option value="Italy">Italy</option>
							<option value="Jamaica">Jamaica</option>
							<option value="Japan">Japan</option>
							<option value="Jersey">Jersey</option>
							<option value="Jordan">Jordan</option>
							<option value="Kazakhstan">Kazakhstan</option>
							<option value="Kenya">Kenya</option>
							<option value="Kiribati">Kiribati</option>
							<option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
							<option value="Korea, Republic of">Korea, Republic of</option>
							<option value="Kuwait">Kuwait</option>
							<option value="Kyrgyzstan">Kyrgyzstan</option>
							<option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
							<option value="Latvia">Latvia</option>
							<option value="Lebanon">Lebanon</option>
							<option value="Lesotho">Lesotho</option>
							<option value="Liberia">Liberia</option>
							<option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
							<option value="Liechtenstein">Liechtenstein</option>
							<option value="Lithuania">Lithuania</option>
							<option value="Luxembourg">Luxembourg</option>
							<option value="Macao">Macao</option>
							<option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
							<option value="Madagascar">Madagascar</option>
							<option value="Malawi">Malawi</option>
							<option value="Malaysia">Malaysia</option>
							<option value="Maldives">Maldives</option>
							<option value="Mali">Mali</option>
							<option value="Malta">Malta</option>
							<option value="Marshall Islands">Marshall Islands</option>
							<option value="Martinique">Martinique</option>
							<option value="Mauritania">Mauritania</option>
							<option value="Mauritius">Mauritius</option>
							<option value="Mayotte">Mayotte</option>
							<option value="Mexico">Mexico</option>
							<option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
							<option value="Moldova, Republic of">Moldova, Republic of</option>
							<option value="Monaco">Monaco</option>
							<option value="Mongolia">Mongolia</option>
							<option value="Montenegro">Montenegro</option>
							<option value="Montserrat">Montserrat</option>
							<option value="Morocco">Morocco</option>
							<option value="Mozambique">Mozambique</option>
							<option value="Myanmar">Myanmar</option>
							<option value="Namibia">Namibia</option>
							<option value="Nauru">Nauru</option>
							<option value="Nepal">Nepal</option>
							<option value="Netherlands">Netherlands</option>
							<option value="Netherlands Antilles">Netherlands Antilles</option>
							<option value="New Caledonia">New Caledonia</option>
							<option value="New Zealand">New Zealand</option>
							<option value="Nicaragua">Nicaragua</option>
							<option value="Niger">Niger</option>
							<option value="Nigeria">Nigeria</option>
							<option value="Niue">Niue</option>
							<option value="Norfolk Island">Norfolk Island</option>
							<option value="Northern Mariana Islands">Northern Mariana Islands</option>
							<option value="Norway">Norway</option>
							<option value="Oman">Oman</option>
							<option value="Pakistan">Pakistan</option>
							<option value="Palau">Palau</option>
							<option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
							<option value="Panama">Panama</option>
							<option value="Papua New Guinea">Papua New Guinea</option>
							<option value="Paraguay">Paraguay</option>
							<option value="Peru">Peru</option>
							<option value="Philippines">Philippines</option>
							<option value="Pitcairn">Pitcairn</option>
							<option value="Poland">Poland</option>
							<option value="Portugal">Portugal</option>
							<option value="Puerto Rico">Puerto Rico</option>
							<option value="Qatar">Qatar</option>
							<option value="Reunion">Reunion</option>
							<option value="Romania">Romania</option>
							<option value="Russian Federation">Russian Federation</option>
							<option value="Rwanda">Rwanda</option>
							<option value="Saint Helena">Saint Helena</option>
							<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
							<option value="Saint Lucia">Saint Lucia</option>
							<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
							<option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
							<option value="Samoa">Samoa</option>
							<option value="San Marino">San Marino</option>
							<option value="Sao Tome and Principe">Sao Tome and Principe</option>
							<option value="Saudi Arabia">Saudi Arabia</option>
							<option value="Senegal">Senegal</option>
							<option value="Serbia">Serbia</option>
							<option value="Seychelles">Seychelles</option>
							<option value="Sierra Leone">Sierra Leone</option>
							<option value="Singapore">Singapore</option>
							<option value="Slovakia">Slovakia</option>
							<option value="Slovenia">Slovenia</option>
							<option value="Solomon Islands">Solomon Islands</option>
							<option value="Somalia">Somalia</option>
							<option value="South Africa">South Africa</option>
							<option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
							<option value="Spain">Spain</option>
							<option value="Sri Lanka">Sri Lanka</option>
							<option value="Sudan">Sudan</option>
							<option value="Suriname">Suriname</option>
							<option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
							<option value="Swaziland">Swaziland</option>
							<option value="Sweden">Sweden</option>
							<option value="Switzerland">Switzerland</option>
							<option value="Syrian Arab Republic">Syrian Arab Republic</option>
							<option value="Taiwan">Taiwan</option>
							<option value="Tajikistan">Tajikistan</option>
							<option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
							<option value="Thailand">Thailand</option>
							<option value="Timor-leste">Timor-leste</option>
							<option value="Togo">Togo</option>
							<option value="Tokelau">Tokelau</option>
							<option value="Tonga">Tonga</option>
							<option value="Trinidad and Tobago">Trinidad and Tobago</option>
							<option value="Tunisia">Tunisia</option>
							<option value="Turkey">Turkey</option>
							<option value="Turkmenistan">Turkmenistan</option>
							<option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
							<option value="Tuvalu">Tuvalu</option>
							<option value="Uganda">Uganda</option>
							<option value="Ukraine">Ukraine</option>
							<option value="United Arab Emirates">United Arab Emirates</option>
							<option value="United Kingdom">United Kingdom</option>
							<option value="United States">United States</option>
							<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
							<option value="Uruguay">Uruguay</option>
							<option value="Uzbekistan">Uzbekistan</option>
							<option value="Vanuatu">Vanuatu</option>
							<option value="Venezuela">Venezuela</option>
							<option value="Viet Nam">Viet Nam</option>
							<option value="Virgin Islands, British">Virgin Islands, British</option>
							<option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
							<option value="Wallis and Futuna">Wallis and Futuna</option>
							<option value="Western Sahara">Western Sahara</option>
							<option value="Yemen">Yemen</option>
							<option value="Zambia">Zambia</option>
							<option value="Zimbabwe">Zimbabwe</option>
						</select>
						<p v-if="errors('country')" v-html="errors('country')" class="error"></p>
					</div>

					<div class="form-field">
						<label for="industry">Industry<em>*</em></label>
						<select class="custom-select" id="industry" name="industry" required v-model="profile.industry">
							<option value="Commercial">Commercial</option>
							<option value="Healthcare">Healthcare</option>
							<option value="Offices/Workspaces">Offices/Workspaces</option>
							<option value="Retail">Retail</option>
							<option value="Residential">Residential</option>
							<option value="Multidiscipline">Multidiscipline</option>
							</select>
						<p v-if="errors('industry')" v-html="errors('industry')" class="error"></p>
					</div>
				</div>

				<div class="form-field" data-size="half">
					<input id="opt-in" type="checkbox" name="opt-in" v-model="profile.email_optin" />
					<label for="opt-in" class="for-checkbox">I want to receive e-mails from B+N</label>
				</div>
			</fieldset>

			<button type="submit" class="primary-button">Update Profile</button>

			<p v-if="successMessage" class="success-message">Your profile has been updated.</p>
		</form>
	</div>
</template>

<style lang="scss">
.profile-form {
	--margin-bottom: 50px;

	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	fieldset {
		margin-block-end: 50px;
	}

	.field-group,
	.form-field {
		width: min(428px, 100%);
	}

	button.toggle {
		margin-block-start: 20px;
	}

	.success-message {
		margin-block-start: 2em;
	}
}

@media all and #{$mq-s-max} {
	.profile-form {
		.field-group,
		.form-field {
			width: min(428px, 100%);
		}
	}
}

@media all and #{$mq-m-min} {
	.profile-form {
		fieldset {
			@include grid();
			row-gap: 20px;

			> div:nth-child(odd) {
				grid-column: 1 / span 6;
			}

			> div:nth-child(even) {
				grid-column: 7 / span 6;
			}
		}
	}
}

@media all and #{$mq-l-min} {
}
</style>

<script>
export default {
	components: {},
	data() {
		return {
			aria: true,
			togglePassword: false,
			hasErrors: false,
			errorServer: false,
			errorMessages: null,
			successMessage: false,
			password: '',
			password_confirmation: '',
			profile: {
				email: this.$auth.user.email,
				firstname: this.$auth.user.firstname,
				lastname: this.$auth.user.lastname,
				title: this.$auth.user.title,
				phone: this.$auth.user.phone,
				company: this.$auth.user.company,
				companytype: this.$auth.user.companytype,
				city: this.$auth.user.city,
				state: this.$auth.user.state,
				country: this.$auth.user.country,
				industry: this.$auth.user.industry,
				email_optin: this.$auth.user.email_optin
			}
		}
	},

	props: ['blok', 'user'],
	computed: {
		companyTypesData() {
			return this.$store.getters['companyTypes/getCompanyTypes']
		}
	},
	created() {
		console.log('user is logged in?', this.$auth.loggedIn)

		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},

	methods: {
		invalidateForm() {
			this.hasErrors = true
		},
		errors(fieldId) {
			// return formatted error messages for a given field

			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		handleProfileUpdate() {
			// clear existing errors first
			this.errorMessages = null

			this.handlePasswordChange()

			// if there are frontend errors, don't submit the form
			if (this.errorMessages !== null) return

			this.$axios
				.put(process.env.profileUpdateEndpoint, this.profile)
				.then(response => {
					// show success (toast?)
					this.$auth.fetchUser()

					this.successMessage = true

					setTimeout(() => (this.successMessage = false), 7500)
				})
				.catch(error => {
					// do nothing if there is no error object
					if (!error) return

					// use server-side validation messages if they exist
					// otherwise use a generic error message
					if (error.response && error.response.data && error.response.data.errors) {
						this.errorMessages = error.response.data.errors
					} else {
						this.errorServer = true
					}
				})
		},
		handlePasswordChange() {
			if (!this.togglePassword) {
				console.info('not changing password')
				return
			}

			// check for any additional frontend validation
			if (this.password != this.password_confirmation) {
				this.errorMessages = this.errorMessages || {}
				this.errorMessages['password_confirmation'] = ['Passwords must match.']

				return
			}

			// make the password change request
			this.$axios
				.put(process.env.passwordChangeEndpoint, {
					password: this.password,
					password_confirmation: this.password_confirmation
				})
				.then(response => {
					// @todo push a success message somewhere?
					this.togglePassword = false
					this.password = ''
					this.password_confirmation = ''
				})
				.catch(error => {
					this.errorMessages = this.errorMessages || {}
					if (error.response && error.response.data && error.response.data.errors) {
						let k;
						for(k in error.response.data.errors) {
							this.errorMessages[k] = error.response.data.errors[k]
						}
					}
					this.invalidateForm()
				})
		},
		handleLogout() {
			this.$auth.logout()
		}
	}
}
</script>
