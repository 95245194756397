<template>
	<header v-editable="blok" class="page-header" :class="[blok.add_divider && 'page-header--divider']">
		<div class="page-header__text">
			<Breadcrumb :key="breadcrumb._uid" v-for="breadcrumb in blok.breadcrumb" :blok="breadcrumb" :is="breadcrumb.component | dashify"></Breadcrumb>

			<h1 v-if="blok.title">{{ blok.title }} <Bookmark v-if="blok.bookmark === true" :id="bookmarkId" /></h1>

			<div v-if="description" v-html="description"></div>

			<p v-if="blok.note" class="page-header__note">{{ blok.note }}</p>

			<ContactButton v-if="blok.contact_button === true" :contact_text="blok.contact_text" />
		</div>

		<picture v-if="image()">
			<template v-if="this.blok.image.filename.endsWith('svg')">
				<img v-lazy-load :data-src="this.blok.image.filename" :alt="blok.image.alt" />
			</template>

			<template v-else>
				<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
				<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
				<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
				<img v-lazy-load :data-src="image()" :alt="blok.image.alt" width="450" height="235" />
			</template>
		</picture>
	</header>
</template>

<style lang="scss">
.page-header {
	margin: 0 auto 50px;
	width: min(var(--content-width), 100%);

	&--divider {
		border-block-end: 2px solid var(--gray-lite);
		padding-block-end: 50px;
	}

	&__note {
		color: var(--gray-medium);
	}

	picture {
		display: block;
	}
}

@media all and #{$mq-s-max} {
	.page-header {
		img {
			margin: 0 auto;
		}
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.page-header {
		@include grid();

		&__text {
			grid-column: 1 / span 6;
		}

		picture {
			align-self: center;
			display: block;
			grid-column: 7 / span 6;
		}

		img {
			margin: 0 auto;
		}
	}
}

@media all and #{$mq-l-min} {
}
</style>

<script>
export default {
	components: {
		Breadcrumb: () => import('~/components/Breadcrumb.vue'),
		Bookmark: () => import('~/components/Bookmark.vue'),
		ContactButton: () => import('~/components/ContactButton.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 450
				height = 0 // variable height

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {},
	computed: {
		description() {
			if (this.blok.description) {
				return this.$storyapi.richTextResolver.render(this.blok.description)
			}
		},
		bookmarkId() {
			return this.$route.params.pathMatch
		}
	}
}
</script>
