var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "tile-item",
      attrs: {
        "data-aos": "fade",
        "data-aos-offset": "-150",
        "data-aos-delay": "0",
        "data-aos-duration": "700",
        "data-aos-once": "true",
        "data-aos-anchor-placement": "top-center"
      }
    },
    [
      _c("div", { staticClass: "tile-item__image" }, [
        _c("picture", [
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: { "data-srcset": _vm.image("webp"), type: "image/webp" }
          }),
          _vm._v(" "),
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: { "data-srcset": _vm.image(null), type: "image/jpeg" }
          }),
          _vm._v(" "),
          _c("img", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-src": _vm.image(),
              width: "865",
              height: "888",
              alt: _vm.blok.title
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tile-item__content" }, [
        _vm.blok.title
          ? _c("h3", { staticClass: "tile-item__title" }, [
              _vm._v(_vm._s(_vm.blok.title))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.description
          ? _c("div", {
              staticClass: "tile-item__description",
              domProps: { innerHTML: _vm._s(_vm.description) }
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }