<template>
	<div v-editable="blok" class="home">
		<div class="home-slider">
			<div class="home-slider__item" v-for="(slide, index) in blok.slides" :key="slide.id">
				<VideoClip v-if="slide.video.length" :blok="slide.video[0]"></VideoClip>
				<picture v-else>
					<source v-lazy-load :data-srcset="image(slide.image, 'webp', true)" media="(min-width: 1800px)" type="image/webp" />
					<source v-lazy-load :data-srcset="image(slide.image, null, true)" media="(min-width: 1800px)" type="image/jpeg" />
					<source v-lazy-load :data-srcset="image(slide.image, 'webp')" type="image/webp" />
					<img width="1440" height="810" class="slideimage" :id="'home-slide' + index"  v-lazy-load :data-src="image(slide.image)" alt="" />
				</picture>
				<!-- <img v-else v-lazy-load :data-src="image(slide.image)" class="slideimage" :id="'home-slide' + index" alt="" /> -->
				<h2 class="text-jumbo">
					<template v-if="isVideoLink(slide.link)">
						<a :href="slide.link.cached_url" @click.prevent="showVideoModal(slide.link.cached_url)" tabindex="-1">
							{{ slide.title }}
							<b v-if="wordArray(slide.words)" class="words">
								&nbsp;
								<template v-for="word in wordArray(slide.words)">
									<span :key="word.id">{{ word }}</span>
								</template>
							</b>
							<span class="video-icon">Play Video</span>
						</a>
					</template>
					
					<a v-else-if="slide.link.linktype === 'url'" :href="slide.link && slide.link.cached_url ? slide.link.cached_url : '#'" tabindex="-1">
						{{ slide.title }}
						<b v-if="wordArray(slide.words)" class="words">
							&nbsp;
							<template v-for="word in wordArray(slide.words)">
								<span :key="word.id">{{ word }}</span>
							</template>
						</b>
					</a>
					<nuxt-link v-else :to="slide.link && slide.link.cached_url ? slide.link.cached_url : '#'" tabindex="-1">
						{{ slide.title }}
						<b v-if="wordArray(slide.words)" class="words">
							&nbsp;
							<template v-for="word in wordArray(slide.words)">
								<span :key="word.id">{{ word }}</span>
							</template>
						</b>
					</nuxt-link>
				</h2>
				<template v-if="slide.video.length">
					<div class="scroll-hint">
						<span class="scroll-hint__mouse">
							<span></span>
						</span>
						<p>Scroll Down</p>
					</div>
				</template>
			</div>
		</div>
		<div class="home-wrapper">
			<div class="home-content" :class="gsapReady ? 'ready' : ''">
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.home-slider{
	&__item {
		width: 100%;
		height: 100vh;
		position: relative;
		overflow: hidden;

		picture,
		img,
		video {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
		.video-clip {
			width: 100%;
			height: 100vh;
		}

		.slideimage {
			max-width: 300%;
			min-height: 100%;
			min-width: 100%;
		}

		h2 {
			align-items: center;
			color: var(--white);
			display: flex;
			height: 100%;
			left: 0;
			pointer-events: none;
			position: absolute;
			text-align: center;
			top: 0;
			width: 100%;
			will-change: transform opacity;
			// opacity: .2;
		}

		a {
			color: var(--white);
			display: block;
			margin: 0 20px;
			padding: 20px 0;
			pointer-events: auto;
			position: relative;
			text-decoration: none;
			text-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
			// transition: all .5s ease;
			width: 100%;

			&:hover {
				// text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
			}

			@media (min-width: 64rem) {
				margin: 0 12.5%;
			}
		}

		b {
			color: var(--orange);
			display: block;
			font-weight: normal;
			position: relative;

			span {
				display: block;
				left: 50%;
				opacity: 0;
				position: absolute;
				top: 0;
				transform: translate(-50%, 0);

				&:first-of-type {
					opacity: 1;
				}
			}
		}

		.video-icon {
			background: white;
			background-position: center center;
			background-size: cover;
			border-radius: 50%;
			display: block;
			height: 44px;
			margin: 40px auto 0 auto;
			position: relative;
			text-indent: -99999px;
			width: 44px;

			&::after {
				background: url(/img/ui/icon-play-black.svg) no-repeat;
				background-position: center center;
				background-size: cover;
				content: '';
				display: block;
				height: 15px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-40%, -50%);
				width: 13px;
			}

			@media (min-width: 64rem) {
				height: 72px;
    			width: 72px;
			}
		}
	}
	.scroll-hint {
		position: absolute;
		bottom: 130px;
		left: 50%;
		transform: translateX(-50%);
		color: var(--white);

		&__mouse {
			width: 19px;
			height: 28px;
			border: 2px solid var(--white);
			display: block;
			position: relative;
			margin: 0 auto 5px auto;
			border-radius: 9px;

			span {
				width: 1.5px;
				height: 6px;
				position: absolute;
				left: 50%;
				top: 4px;
				background: var(--white);
				display: block;
				transform: translate(-50%, 0);
				animation: scrollHint 0.75s infinite alternate-reverse linear;
			}
		}

		p {
			margin: 0;
		}
	}
}
.simpleParallax {
	height: 100vh;
}
@keyframes scrollHint {
	0% {
		transform: translate(-50%, 0);
	}
	100% {
		transform: translate(-50%, 30%);
	}
}

// html {
// 	scroll-snap-type: y;
// }
// .global-footer {
// 	scroll-snap-align: start;
// }
.home {
	position: relative;

	main > & {
		margin: calc(-1 * var(--header-height)) -10px 0 -10px;
	}
}
.home + .content-container {
	margin: 0;
	padding: 0;
}
.home-wrapper {
	position: relative;
	width: 100%;
	display: none !important;
}

/* Large & Up Screen */
@media all and #{$mq-l-min} {
	.scroll-hint {
		bottom: 55px;

		&__mouse {
			width: 26px;
			height: 40px;
			border-radius: 13px;

			span {
				height: 10px;
				top: 5px;
			}
		}
	}
	
}
</style>

<script>
export default {
	components: {
		VideoClip: () => import('@/components/VideoClip.vue')
	},
	data() {
		return {
			gsapReady: false,
			mainTimeline: null,
			mainScrollTrigger: null,
			slides: null,
			slideCount: 0
		}
	},
	mixins: [],
	props: ['blok'],
	computed: {
		isNoTouch() {
			return document.documentElement.classList.contains('no-touch')
		}
	},
	methods: {
		image(image, fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			const notHighResolutionScale = 1.2;
			let width, height

			if (image && image.filename) {
				// default size
				width = 1440
				height = 0

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				} else {
					height *= notHighResolutionScale
					width *= notHighResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(image.filename, {
					size: width + 'x' + height,
					focus: image.focus,
					fileType: fileType
				})
				return processedImage
			}
		},
		initHome() {
			if (this.$gsap && this.$ScrollTrigger) {
				// this.initMainTimeline()
				// this.initFocusedAnchors()
				// this.initScroll()
				this.initWordTimelines()
				this.gsapReady = true
			}
				
		},
		initMainTimeline() {
			const gsap = this.$gsap
			const ScrollTrigger = this.$ScrollTrigger

			// main timeline for slides
			this.mainTimeline = gsap.timeline({
				paused: true
			})

			// build out the timeline

			// start label

			for (let i = 0; i < this.slideCount; i++) {
				if (i < this.slideCount - 1) {
					// leaving
					this.mainTimeline
						.to(
							textArray[i],
							{
								y: '-25%',
								autoAlpha: 0,
								transform: 'scale(0.5)',
								display: 'none',
								duration: 1,
								ease: 'none'
							},
							i
						)
						.addLabel('slidelabel' + i)
					this.mainTimeline.to(mediaArray[i], { autoAlpha: 0, display: 'none', duration: 1, ease: 'power2.in' }, i)
				}

				// entering
				if (textArray[i + 1]) this.mainTimeline.to(textArray[i + 1], { y: '0%', autoAlpha: 1, transform: 'scale(1)', display: 'flex', duration: 1, ease: 'none' }, i)
				if (mediaArray[i + 1]) this.mainTimeline.to(mediaArray[i + 1], { autoAlpha: 1, display: 'flex', duration: 1, ease: 'power2.in' }, i)
			}

			// use scrolltrigger for touch devices, for better performance than scroll event
			// if (!this.isNoTouch) {
			this.mainScrollTrigger = ScrollTrigger.create({
				animation: this.mainTimeline,
				trigger: '.home-triggers',
				start: 'top top',
				end: 'bottom bottom',
				scrub: true,
				snap: {
					snapTo: 'labels',
					duration: { min: 0.5, max: 1 }
				}
			})
		},
		initWordTimelines() {
			const gsap = this.$gsap
			const ScrollTrigger = this.$ScrollTrigger

			// timelines for orange word animations
			const secsPerWord = 1.5

			// this.slides.forEach(slide => {
				const wordContainer = document.querySelector('.words')
				if (!wordContainer) return

				const words = wordContainer.querySelectorAll('span')
				if (!words) return

				const wordTimeline = gsap.timeline({
					paused: false,
					repeat: -1,
					delay: 0.5
				})

				for (let i = 0; i < words.length; i++) {
					// leaving word
					wordTimeline.to(words[i], { autoAlpha: 0, duration: 0.25 }, (i + 1) * secsPerWord - 0.5)

					// entering word
					if (i < words.length - 1) {
						wordTimeline.to(
							words[i + 1],
							{
								startAt: {
									y: '100px'
								},
								y: '0px',
								autoAlpha: 1,
								duration: 0.5
							},
							(i + 1) * secsPerWord - 0.5
						)
					}
				}

				// enter first word again to complete loop
				wordTimeline.to(
					words[0],
					{
						startAt: {
							y: '100px'
						},
						y: '0px',
						autoAlpha: 1,
						duration: 0.5
					},
					words.length * secsPerWord - 0.5
				)

				// set scroll triggers to start/stop word animations
				ScrollTrigger.create({
					animation: wordTimeline,
					trigger: `.home-slider`,
					start: 'top top',
					toggleActions: 'play pause resume pause'
				})
			// })
		},
		wordArray(words) {
			if (!words) return

			return words.split(/\r?\n/)
		},
		isVideoLink(link) {
			if (link.linktype === 'url' && link.cached_url) {
				const urlObject = new URL(link.cached_url)
				if (urlObject.hostname.match('vimeo') || urlObject.hostname.match('youtube')) return true
			}

			return false
		},
		showVideoModal: function(url) {
			this.$bus.$emit('openVideoModal', { type: 'video', url: url })
		}
	},
	mounted() {
		let para = document.createElement('script');
      	para.setAttribute('src', 'https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js');
      	document.head.appendChild(para);
		this.initHome();

		this.$bus.$emit('transparentHeader');
		para.onload = function() {
			var image = document.querySelectorAll('img.slideimage');
			new simpleParallax(image, {delay: .1, transition: 'cubic-bezier(.49,.73,.64,.66)', scale: 1.3});
		};
		
	},
	beforeDestroy() {
		if (this.mainTimeline) this.mainTimeline.kill()
		if (this.mainScrollTrigger) this.mainScrollTrigger.kill()
	}
}
</script>
