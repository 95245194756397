<template>
	<div v-editable="blok" :class="isSelected() ? 'selected-item interactive-item' : 'interactive-item'" :style="style()">
		<template v-if="blok.image">
			<a class="interactive-block__fullscreen" href="javascript:void(0);">
				<svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g id="PDP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="m-reconfigure" transform="translate(-331.000000, -2006.000000)">
							<g id="Group" transform="translate(331.000000, 2006.000000)">
								<rect id="Rectangle" stroke="#CBCBCB" fill="#FFFFFF" x="0.5" y="0.5" width="33" height="33" rx="4"></rect>
								<g transform="translate(8.000000, 8.000000)" fill="#000000" id="expand">
									<path d="M5.6195122,0.746341463 L5.6195122,0 L0,0 L0,5.6195122 L0.746341463,5.6195122 L0.746341463,1.27317073 L6.10243902,6.62926829 L6.62926829,6.10243902 L1.27317073,0.746341463 L5.6195122,0.746341463 Z M12.3804878,0 L12.3804878,0.746341463 L16.7268293,0.746341463 L11.3707317,6.10243902 L11.897561,6.62926829 L17.2536585,1.27317073 L17.2536585,5.6195122 L18,5.6195122 L18,0 L12.3804878,0 Z M6.10243902,11.3707317 L0.746341463,16.7268293 L0.746341463,12.3804878 L0,12.3804878 L0,18 L5.6195122,18 L5.6195122,17.2536585 L1.27317073,17.2536585 L6.62926829,11.897561 L6.10243902,11.3707317 Z M17.2536585,16.7268293 L11.897561,11.3707317 L11.3707317,11.897561 L16.7268293,17.2536585 L12.3804878,17.2536585 L12.3804878,18 L18,18 L18,12.3804878 L17.2536585,12.3804878 L17.2536585,16.7268293 Z" id="Fill-1"></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</a>
			<div class="interactive-block__fullscreen__div">
				<a class="interactive-block__fullscreen__div__close" href="javascript:void(0);">
					<svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<g id="PDP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="toggle-open" transform="translate(-331.000000, -10.000000)">
								<g id="Group" transform="translate(331.000000, 10.000000)">
									<rect id="Rectangle" stroke="#CBCBCB" fill="#FFFFFF" x="0.5" y="0.5" width="33" height="33" rx="4"></rect>
									<g id="minimize" transform="translate(8.000000, 8.000000)" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
										<path d="M5,0 L5,3 C5,4.1045695 4.1045695,5 3,5 L0,5 M18,5 L15,5 C13.8954305,5 13,4.1045695 13,3 L13,0 M13,18 L13,15 C13,13.8954305 13.8954305,13 15,13 L18,13 M0,13 L3,13 C4.1045695,13 5,13.8954305 5,15 L5,18" id="Shape"></path>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</a>
				<img v-lazy-load :data-src="blok.image.filename" width="2880" height="1680" alt="" />
			</div>
		</template>
	</div>
</template>

<style lang="scss">
	.interactive-item {
		position: absolute;
		left: 0;
		top:0;
		width: 100%;
		height: 100%;
		z-index: 5;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0;
		transition: all 0.4s;
		&.selected-item {
			z-index: 6; 
			opacity: 1;
			&.smaller-index {
				z-index: 100;
			}
		}
	}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok', 'selected'],
	methods: {
		isSelected() {
			return this.selected;
		},
		style() {
			if(this.blok && this.blok.image.filename) {
				return 'background-image:url(' + this.blok.image.filename + ')';
			}
		}
	},
	mounted() {},
	computed: {}
}
</script>
