var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    typeof _vm.index === "number"
      ? _c(
          "div",
          {
            staticClass: "light-gallery",
            on: {
              touchstart: _vm.touchstartHandler,
              touchmove: _vm.touchmoveHandler,
              touchend: _vm.touchendHandler
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "light-gallery__modal",
                style: "background: " + _vm.background
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      "light-gallery__spinner",
                      !_vm.isImageLoaded || "hide"
                    ]
                  },
                  [
                    _c("div", {
                      staticClass: "light-gallery__dot",
                      style: "border-color: " + _vm.interfaceColor
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "light-gallery__dot",
                      style: "border-color: " + _vm.interfaceColor
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "light-gallery__dot",
                      style: "border-color: " + _vm.interfaceColor
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "light-gallery__container" }, [
                  _c(
                    "ul",
                    { staticClass: "light-gallery__content" },
                    _vm._l(_vm.formattedImages, function(image, imageIndex) {
                      return _c(
                        "li",
                        {
                          key: imageIndex,
                          staticClass: "light-gallery__image-container",
                          style:
                            "transform: translate3d(" +
                            _vm.currentIndex * -100 +
                            "%, 0px, 0px);"
                        },
                        [
                          _c(
                            "figure",
                            { staticClass: "light-gallery__image" },
                            [
                              _c(
                                "figcaption",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: image.title && _vm.isImageLoaded,
                                      expression: "image.title && isImageLoaded"
                                    }
                                  ],
                                  staticClass: "light-gallery__text"
                                },
                                [_c("p", [_vm._v(_vm._s(image.title))])]
                              ),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  { name: "lazy-load", rawName: "v-lazy-load" }
                                ],
                                ref: "lg-img-" + imageIndex,
                                refInFor: true,
                                attrs: {
                                  "data-src": _vm.shouldPreload(imageIndex)
                                    ? image.url
                                    : false
                                },
                                on: {
                                  load: function($event) {
                                    return _vm.imageLoaded($event, imageIndex)
                                  }
                                }
                              })
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _vm.currentIndex > 0
                  ? _c(
                      "button",
                      {
                        staticClass: "light-gallery__prev",
                        on: {
                          click: function($event) {
                            return _vm.prev()
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentIndex + 1 < _vm.images.length
                  ? _c(
                      "button",
                      {
                        staticClass: "light-gallery__next",
                        on: {
                          click: function($event) {
                            return _vm.next()
                          }
                        }
                      },
                      [_vm._v("Previous")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "light-gallery__close",
                    on: {
                      click: function($event) {
                        return _vm.close()
                      }
                    }
                  },
                  [_vm._v("Close")]
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }