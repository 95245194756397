<template>
	<div v-editable="blok" class="image-feature">
		<picture>
			<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
			<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
			<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
			<img v-lazy-load :data-src="image()" :alt="blok.image.alt" />
		</picture>

		<div v-if="text" v-html="text"></div>
	</div>
</template>

<style lang="scss">
.image-feature {
	display: flex;
	flex-direction: column;
	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	picture {
		display: inline-flex;
		margin: 0 0 10px auto;
	}

	p {
		--min-font: 1.75;
		--max-font: 3.75;

		font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
		line-height: 1.1;
		margin: 1.5em auto 1em;
		text-align: center;
		width: min(1100px, 100%);
	}

	.text-orange {
		color: var(--orange);
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.image-feature {
		--margin-bottom: 50px;

		picture {
			height: 290px;

			img {
				height: inherit;
				object-fit: cover;
				width: 100%;
			}
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.image-feature {
		--margin-bottom: 100px;
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 1324
				height = 745

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {},
	computed: {
		text() {
			if (this.blok.text && this.blok.text.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		}
	}
}
</script>
