var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "prod-tiles",
      class: _vm.isFullscreen(),
      style: _vm.styles()
    },
    [
      this.is3Equal()
        ? [
            _c("div", { staticClass: "prod-tiles__wrapper three-equal" }, [
              _c(
                "div",
                {
                  staticClass: "prod-tiles__title",
                  attrs: {
                    "data-aos": "fade",
                    "data-aos-offset": "0",
                    "data-aos-delay": "0",
                    "data-aos-duration": "700",
                    "data-aos-once": "true",
                    "data-aos-anchor-placement": "top-center"
                  }
                },
                [
                  _vm.blok.title
                    ? _c(
                        "h2",
                        [
                          _vm._v(_vm._s(_vm.blok.title)),
                          _vm.blok.title_2
                            ? [_c("br"), _vm._v(_vm._s(_vm.blok.title_2))]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "prod-tiles_scroller" },
                _vm._l(_vm.blok.tiles, function(blok) {
                  return _c(_vm._f("dashify")(blok.component), {
                    key: blok._uid,
                    tag: "component",
                    attrs: { blok: blok }
                  })
                }),
                1
              )
            ])
          ]
        : this.is2Equal()
        ? [
            _c(
              "div",
              { staticClass: "prod-tiles__wrapper two-equal" },
              [
                _c(
                  "div",
                  {
                    staticClass: "prod-tiles__title",
                    attrs: {
                      "data-aos": "fade",
                      "data-aos-offset": "0",
                      "data-aos-delay": "0",
                      "data-aos-duration": "700",
                      "data-aos-once": "true",
                      "data-aos-anchor-placement": "top-center"
                    }
                  },
                  [
                    _vm.blok.title
                      ? _c(
                          "h2",
                          [
                            _vm._v(_vm._s(_vm.blok.title)),
                            _vm.blok.title_2
                              ? [_c("br"), _vm._v(_vm._s(_vm.blok.title_2))]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.blok.tiles, function(blok) {
                  return _c(_vm._f("dashify")(blok.component), {
                    key: blok._uid,
                    tag: "component",
                    attrs: { blok: blok }
                  })
                })
              ],
              2
            )
          ]
        : this.isBiggerLeft()
        ? [
            _c(
              "div",
              { staticClass: "prod-tiles__wrapper bigger-left" },
              [
                _c(
                  "div",
                  {
                    staticClass: "prod-tiles__title",
                    attrs: {
                      "data-aos": "fade",
                      "data-aos-offset": "0",
                      "data-aos-delay": "0",
                      "data-aos-duration": "700",
                      "data-aos-once": "true",
                      "data-aos-anchor-placement": "top-center"
                    }
                  },
                  [
                    _vm.blok.title
                      ? _c(
                          "h2",
                          [
                            _vm._v(_vm._s(_vm.blok.title)),
                            _vm.blok.title_2
                              ? [_c("br"), _vm._v(_vm._s(_vm.blok.title_2))]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.blok.tiles, function(blok) {
                  return _c(_vm._f("dashify")(blok.component), {
                    key: blok._uid,
                    tag: "component",
                    attrs: { blok: blok }
                  })
                })
              ],
              2
            )
          ]
        : this.isBiggerRight()
        ? [
            _c(
              "div",
              { staticClass: "prod-tiles__wrapper bigger-right" },
              [
                _c(
                  "div",
                  {
                    staticClass: "prod-tiles__title",
                    attrs: {
                      "data-aos": "fade",
                      "data-aos-offset": "0",
                      "data-aos-delay": "0",
                      "data-aos-duration": "700",
                      "data-aos-once": "true",
                      "data-aos-anchor-placement": "top-center"
                    }
                  },
                  [
                    _vm.blok.title
                      ? _c(
                          "h2",
                          [
                            _vm._v(_vm._s(_vm.blok.title)),
                            _vm.blok.title_2
                              ? [_c("br"), _vm._v(_vm._s(_vm.blok.title_2))]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.blok.tiles, function(blok) {
                  return _c(_vm._f("dashify")(blok.component), {
                    key: blok._uid,
                    tag: "component",
                    attrs: { blok: blok }
                  })
                })
              ],
              2
            )
          ]
        : [
            _c("div", { staticClass: "prod-tiles__wrapper carousel" }, [
              _c(
                "div",
                {
                  staticClass: "prod-tiles__title",
                  attrs: {
                    "data-aos": "fade",
                    "data-aos-offset": "0",
                    "data-aos-delay": "0",
                    "data-aos-duration": "700",
                    "data-aos-once": "true",
                    "data-aos-anchor-placement": "top-center"
                  }
                },
                [
                  _vm.blok.title
                    ? _c(
                        "h2",
                        [
                          _vm._v(_vm._s(_vm.blok.title)),
                          _vm.blok.title_2
                            ? [_c("br"), _vm._v(_vm._s(_vm.blok.title_2))]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "prod-tiles__carousel--scroll" }, [
                _c(
                  "div",
                  { staticClass: "prod-tiles__carousel" },
                  _vm._l(_vm.blok.tiles, function(blok) {
                    return _c(_vm._f("dashify")(blok.component), {
                      key: blok._uid,
                      tag: "component",
                      attrs: { blok: blok }
                    })
                  }),
                  1
                )
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }