var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-forms" }, [
    _vm.toggleForms
      ? _c("div", { staticClass: "user-login" }, [
          _c(
            "form",
            {
              staticClass: "bn-form",
              class: this.login.errors ? "has-errors" : false,
              attrs: { method: "post" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                }
              }
            },
            [
              _c("h2", [_vm._v("Log In")]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              this.errorServer
                ? _c("p", { staticClass: "error" }, [
                    _vm._v(
                      "There was a problem with creating the account, please try again."
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.errors("email")
                ? _c("p", {
                    staticClass: "error",
                    domProps: { innerHTML: _vm._s(_vm.errors("email")) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("fieldset", [
                _c("div", { staticClass: "form-field" }, [
                  _c("label", { attrs: { for: "email" } }, [
                    _vm._v("Email Address")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.login.email,
                        expression: "login.email"
                      }
                    ],
                    attrs: {
                      type: "email",
                      id: "email",
                      name: "email",
                      required: ""
                    },
                    domProps: { value: _vm.login.email },
                    on: {
                      invalid: _vm.invalidateLoginForm,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.login, "email", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _c("label", { attrs: { for: "password" } }, [
                    _vm._v("Password")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.login.password,
                        expression: "login.password"
                      }
                    ],
                    attrs: {
                      type: "password",
                      id: "password",
                      name: "password",
                      required: ""
                    },
                    domProps: { value: _vm.login.password },
                    on: {
                      invalid: _vm.invalidateLoginForm,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.login, "password", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "field-group",
                    attrs: { "data-size": "half" }
                  },
                  [
                    _c("div", { staticClass: "form-field" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.login.rememberMe,
                            expression: "login.rememberMe"
                          }
                        ],
                        attrs: {
                          id: "remember-me",
                          type: "checkbox",
                          name: "remember-me"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.login.rememberMe)
                            ? _vm._i(_vm.login.rememberMe, null) > -1
                            : _vm.login.rememberMe
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.login.rememberMe,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.login,
                                    "rememberMe",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.login,
                                    "rememberMe",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.login, "rememberMe", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "for-checkbox",
                          attrs: { for: "remember-me" }
                        },
                        [_vm._v("Remember me")]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "form-field",
                        attrs: { "data-align": "right" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "link-style",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                _vm.toggleForms = !_vm.toggleForms
                              }
                            }
                          },
                          [_vm._v("Forgot Password?")]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-actions" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "primary-button",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("Log In")]
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      attrs: { to: "/create-account" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.closeModal($event)
                        }
                      }
                    },
                    [_vm._v("Create a new account")]
                  )
                ],
                1
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.toggleForms
      ? _c(
          "div",
          { staticClass: "forgot-password" },
          [
            !_vm.toogleForgotPassword
              ? _c(
                  "form",
                  {
                    staticClass: "bn-form",
                    class: this.forgotPassword.errors ? "has-errors" : false,
                    attrs: { method: "post" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.resetPassword($event)
                      }
                    }
                  },
                  [
                    _c("h2", [_vm._v("Forgot Password")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Please enter the email address associated with your B+N account."
                      )
                    ]),
                    _vm._v(" "),
                    _c("fieldset", [
                      _c("div", { staticClass: "form-field" }, [
                        _c("label", { attrs: { for: "email" } }, [
                          _vm._v("Email Address")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.forgotPassword.email,
                              expression: "forgotPassword.email"
                            }
                          ],
                          attrs: {
                            type: "email",
                            id: "email",
                            name: "email",
                            required: ""
                          },
                          domProps: { value: _vm.forgotPassword.email },
                          on: {
                            invalid: _vm.invalidateForgotPassworForm,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.forgotPassword,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(1)
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.toogleForgotPassword
              ? [
                  _c("h2", [_vm._v("Reset Password Submitted")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Please check your email for further instructions to complete the process."
                    )
                  ])
                ]
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("small", [
        _vm._v(
          "If you don’t yet have an account, please create one to access all that B+N has to offer. Existing user? You may need to create a new account due to our new website as of August 2021."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-actions" }, [
      _c(
        "button",
        { staticClass: "primary-button", attrs: { type: "submit" } },
        [_vm._v("Reset Password")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }