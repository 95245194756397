var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "image-feature"
    },
    [
      _c("picture", [
        _c("source", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          attrs: {
            "data-srcset": _vm.image("webp", true),
            media:
              "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
            type: "image/webp"
          }
        }),
        _vm._v(" "),
        _c("source", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          attrs: {
            "data-srcset": _vm.image(null, true),
            media:
              "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
            type: "image/jpeg"
          }
        }),
        _vm._v(" "),
        _c("source", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          attrs: { "data-srcset": _vm.image("webp"), type: "image/webp" }
        }),
        _vm._v(" "),
        _c("img", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          attrs: { "data-src": _vm.image(), alt: _vm.blok.image.alt }
        })
      ]),
      _vm._v(" "),
      _vm.text
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }