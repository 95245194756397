var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loggedIn || !_vm.blok.protected
    ? _c(
        "main",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ],
          attrs: { id: "content" }
        },
        _vm._l(_vm.blok.body, function(blok) {
          return _c(_vm._f("dashify")(blok.component), {
            key: blok._uid,
            tag: "component",
            attrs: { blok: blok }
          })
        }),
        1
      )
    : _c(
        "main",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ],
          staticClass: "wait",
          attrs: { id: "content" }
        },
        [
          _c(
            "div",
            { staticClass: "content-container" },
            [_c("user-login", { attrs: { blok: _vm.blok } })],
            1
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }