<template>
	<div :key="this.activeSection" class="fp-parent">
		<template v-if="hasItems()">
			<template
				v-for="(blok, index) in blok.items" :blok="blok" >
				<section 
					:index="index" :class="index === 0 ? 'fp-child-fixed initial' : 'fp-child-fixed'">
						<component :blok="blok" :is="blok.component | dashify"></component>
				</section>
				<div  class="fp-child-static" :index="index">

				</div>
			</template>
			
		</template>
	</div>
</template>

<style lang="scss">
	.fp-parent {
		position: relative;
		width: 100%;
		// height: 450vh;
		.fp-space {
			// height: 100vh;
		}
		> div:last-child {
			margin-top: -100vh;
			height: 150vh;
		}

		@media (max-width: 1024px) { 
			position: static;
		}
	}

	.fp-child-fixed {
		width: 100%;
		height: 100vh;
		position: fixed; 
		left: 0;
		top: 0;
		opacity: 0;
		transition: all 0.5s;
		font-size: 30px;
		overflow-y: auto;
		display: flex;
		align-items: flex-start;
		z-index: -1;
		padding: 0px; 
		box-sizing: border-box;
		justify-content: center;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}

		&:first-of-type, &:last-of-type {
			position: sticky;
		}
		
		&.initial {
			opacity: 1;
			transition: all 0.5s;
		}
		
		
		&.active {
			opacity: 1;
			z-index: 100 !important;
		}
		
		&.instant {
			// transition: opacity 0s;
		}

		@media (max-width: 1024px) {
			position: static !important;
			opacity: 1 !important;
			height: auto !important;
		}
		
	}

	.end:last-of-type {
		opacity: 1 !important; 
	}

	.fp-child-static {
		position: relative;
		width: 100%;
		height: 50vh;
		box-sizing: border-box;
		// border: 1px solid #000;
		// margin-top: -100vh;
		&[index='0'] {
			top: 0;
			margin-top: -100vh;
		}

		@media (max-width: 1024px) {
			display: none;
		}
  	}
</style>

<script>
import $ from 'jquery';

export default {
	components: {},
	data() {
		return {
			activeSection: 0
		}
	},
	props: ['blok'],
	methods: {
		hasItems(){
			return this.blok && this.blok.items.length > 0;
		},
		handleSectionTransition(id) {
			this.$forceUpdate();
			this.activeSection = id;
		},
		isCurrentSection(id) {
			return id === this.activeSection;
		},
	},
	mounted() {
		console.debug(this.blok);
		//TODO - ADD EVENT LISTENERS
		$(window).scroll(function(){
			var sections = $('.fp-child-static');
			sections.each(function(){
				var el = this;
				var top  = $(el).offset().top;
				var bottom = top +$(el).height();
				var scroll = $(window).scrollTop();
				if (scroll < top) {
					$(el).prev('.fp-child-fixed[index="0"]').addClass('initial');
				} else {
					$(el).prev('.fp-child-fixed[index="0"]').removeClass('initial');
				}
				if( scroll >= top && scroll <= bottom){
					$(el).prev('.fp-child-fixed').addClass('active');
					
				} else {
					$(el).prev('.fp-child-fixed').removeClass('active');	
				}
			
			if (scroll > bottom) {
				$(el).prev('.fp-child-fixed').addClass('end');
				} else {
				$(el).prev('.fp-child-fixed').removeClass('end');
				}
			})
		});
	},
	computed: {},
	destroyed() {
		//TODO - REMOVE ANY LISTENERS HERE
	},
}
</script>
