<template>
	<blockquote v-editable="blok" class="blockquote">
		<p>{{ blok.text }}</p>
		<footer>
			<cite>
				{{ blok.author }} <span v-if="blok.profession">{{ blok.profession }}</span>
			</cite>
		</footer>
	</blockquote>
</template>

<style lang="scss">
.blockquote {
	margin: 0 auto var(--margin-bottom);
	max-width: var(--content-width);

	p {
		--min-font: 2.25;
		--max-font: 3;

		font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
		line-height: 1.1;
		margin-block-end: 0;
	}

	p::before {
		content: '“';
	}
	p::after {
		content: '”';
	}

	footer {
		margin-block-start: 30px;
	}

	cite {
		font-style: normal;

		span {
			color: var(--gray-medium);
			display: block;
			margin-block-start: 6px;
		}
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.blockquote {
		--margin-bottom: 60px;

		span {
			font-size: 16px;
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.blockquote {
		--margin-bottom: 110px;

		span {
			font-size: 18px;
		}
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
