<template>
	<div class="bookmark-container">
		<button v-if="loggedIn && isBookmarked" type="button" class="bookmark bookmark--active" @click="removeBookmark">
			Bookmark it.
		</button>
		<button v-else-if="loggedIn" type="button" class="bookmark" @click="saveBookmark">
			Bookmark it.
		</button>
		<button v-else type="button" class="bookmark" @click="showLoginModal">
			Bookmark it.
		</button>
	</div>
</template>

<style lang="scss">
.bookmark-container {
	.page-header & {
		display: inline-flex;
		height: 44px;
		margin-block-start: -4px;
		vertical-align: middle;
	}

	.card & {
		position: absolute;
		right: 0;
		top: var(--offset);
	}
}

.bookmark {
	--default: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='17'%3E%3Cpath stroke='%23000' stroke-width='1.5' d='M1.5 15V1h10v14l-5-4.936z' fill='none'/%3E%3C/svg%3E");
	--hover: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='17'%3E%3Cpath stroke='%23ff5500' stroke-width='1.5' d='M1.5 15V1h10v14l-5-4.936z' fill='none'/%3E%3C/svg%3E");
	--active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='17'%3E%3Cpath stroke='%23ff5500' stroke-width='1.5' d='M1.5 15V1h10v14l-5-4.936z' fill='%23ff5500'/%3E%3C/svg%3E");

	background: var(--default) center center no-repeat;
	flex-shrink: 0;
	height: 44px;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	width: 44px;

	&--active {
		background-image: var(--active);
	}
}

/* Only Small Screen */
@media all and #{$mq-s-max} {
	.bookmark-container {
		.card & {
			--offset: -8px;
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.bookmark-container {
		.card & {
			--offset: -6px;
		}
	}
}
/* Hover for Non-Touch */
@media (hover: hover) and (pointer: fine) {
	.bookmark:not(.bookmark--active):hover {
		background-image: var(--hover);
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['id'],
	methods: {
		saveBookmark() {
			console.log('SAVE bookmark! id:', this.id)

			this.$store.dispatch('bookmarks/addBookmark', { bookmark: this.id })
		},
		removeBookmark() {
			console.log('REMOVE bookmark! id:', this.id)

			this.$store.dispatch('bookmarks/removeBookmark', { bookmark: this.id })
		},
		showLoginModal() {
			// save intent object
			this.$intent.set('bookmark', this.$route.path, { id: this.id })

			this.$bus.$emit('openModal')
		}
	},
	mounted() {},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		},
		isBookmarked() {
			return this.$store.getters['bookmarks/getBookmarkBySlug'](this.id)
		}
	}
}
</script>
