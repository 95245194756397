var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mount
    ? _c(
        "portal",
        { attrs: { selector: _vm.appendTo } },
        [
          _c(
            "transition",
            {
              attrs: {
                name: "vm-backdrop-transition",
                "enter-active-class": _vm.bgInClass,
                "leave-active-class": _vm.bgOutClass
              }
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show"
                  }
                ],
                class: ["vm-backdrop", _vm.id + "-backdrop", _vm.bgClass],
                style: { "z-index": _vm.zIndex - 1 }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "vm-transition",
                "enter-active-class": _vm.inClass,
                "leave-active-class": _vm.outClass
              },
              on: {
                "before-enter": _vm.beforeOpen,
                enter: _vm.opening,
                "after-enter": _vm.afterOpen,
                "before-leave": _vm.beforeClose,
                leave: _vm.closing,
                "after-leave": _vm.afterClose
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show,
                      expression: "show"
                    }
                  ],
                  ref: "vm-wrapper",
                  class: ["vm-wrapper", _vm.wrapperClass, _vm.id],
                  style: { "z-index": _vm.zIndex },
                  attrs: { tabindex: "0" },
                  on: {
                    keydown: function($event) {
                      return _vm.keydown($event)
                    },
                    click: function($event) {
                      return _vm.clickOutside($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      ref: "vm",
                      class: ["vm", _vm.modalClass],
                      style: _vm.modalStyle,
                      attrs: {
                        role: "dialog",
                        "aria-label": _vm.title,
                        "aria-modal": "true"
                      }
                    },
                    [
                      _vm.enableClose
                        ? _c(
                            "button",
                            {
                              staticClass: "vm-btn-close",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.close($event)
                                }
                              }
                            },
                            [_vm._v("Close Modal")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("content", [
                        _c(
                          "div",
                          { staticClass: "vm-content" },
                          [_vm._t("default")],
                          2
                        )
                      ])
                    ],
                    2
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }