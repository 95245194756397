var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "columns"
    },
    [
      _vm.block1
        ? _c("div", {
            staticClass: "columns__block",
            domProps: { innerHTML: _vm._s(_vm.block1) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.block2
        ? _c("div", {
            staticClass: "columns__block",
            domProps: { innerHTML: _vm._s(_vm.block2) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.block3
        ? _c("div", {
            staticClass: "columns__block",
            domProps: { innerHTML: _vm._s(_vm.block3) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.block4
        ? _c("div", {
            staticClass: "columns__block",
            domProps: { innerHTML: _vm._s(_vm.block4) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }