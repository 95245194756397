<template>
	<div class="products-list">
		<Cards v-if="cardData" :cardData="cardData" />
	</div>
</template>

<style lang="scss">
/* Styles defined in CardsItem.vue */
</style>

<script>
export default {
	components: {
		Cards: () => import('@/components/Cards.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {
	},
	computed: {
		products() {
			console.log('products ', this.$store.getters['products/getAllProducts']);
			return this.$store.getters['products/getAllProducts']
		},
		cardData() {
			const data = {
				type: 'products',
				autocrop: ['oneone'],
				dataSize: 'col3',
				columns: ['col4']
			}
			data.uuids = Array.from(this.products, item => item.uuid)
			return data
		}
	}
}
</script>
