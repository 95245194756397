<template>
	<form v-editable="blok" class="bn-form">
		<h2 class="section-title">{{ blok.title }}</h2>

		<fieldset class="filters">
			<div class="form-field" :key="application._uid" v-for="application in productApplications">
				<input :id="'application-' + application.value" type="radio" name="radio-options" />
				<label :for="'application-' + application.value" class="for-radio">{{ application.name }}</label>
			</div>
		</fieldset>
	</form>
</template>

<style lang="scss"></style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		productApplications() {
			return this.$store.getters['products/getProductApps']
		}
	}
}
</script>
