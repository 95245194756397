<template>
	<div v-editable="blok" class="bs-text-image" :style="background()">
		<div class="bs-text-image__wrapper" :class="blok.image_position">
            <div class="bs-text-image__image"> 
                <picture>
					<img width="721" height="840" class="slideimage" v-lazy-load :data-src="this.blok.image.filename" alt="" />
				</picture>
            </div>
            <div class="bs-text-image__text" :style="textColor()" data-aos="fade" data-aos-offset="0" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
                <div class="bs-text-image__pre" v-if="blok.category" v-html="blok.category"></div>
                <h2 class="bs-text-image__title" v-if="blok.title" v-html="blok.title"></h2>
                <div v-if="description" v-html="description" class="bs-text-image__description"></div>
                <div v-if="note" v-html="note" class="bs-text-image__note"></div>
            </div>
        </div>
	</div>
</template>

<style lang="scss">
   .bs-text-image {
        &__wrapper {
            display: flex;
            align-items: stretch;
            &.right {
                .bs-text-image__text {
                    order: -1;
                }
            }

            @media (max-width: 768px) {
                flex-direction: column;
            }
        }

        &__image {
            display: block;
            width: 50%;
            min-height: 100vh;
            height: 100vh;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            @media (max-width: 768px) {
                order: 0 !important;
                width: 100%;
                min-height: 840px;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            text-align: center;
            padding-top: 120px;
            padding-bottom: 120px;
            position: relative;
            @media (max-width: 768px) {
                width: 100%;
                order: 2 !important;
            }
        }
        p {
            font-size: 22px; 
            line-height: 27px;
            // font-family: "Teodor-Regular", "Times New Roman", Times, serif;
        }

        &__pre {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 15px;
            letter-spacing: 1px;
        }

        &__description {
            display: block;
            width: 100%;
            max-width: 430px;
            margin: 0 auto;
            p {
                font-family: "Teodor-Regular", "Times New Roman", Times, serif;
                font-size: 22px;
                line-height: 27px;
            }
        }

        &__note {
            width: 100%;
            max-width: 580px;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
            line-height: 22px;
            @media (max-width: 768px) {
                position: static;
                transform: none;
                left: auto;
                padding-left: 20px;
                padding-right: 20px;
            }

            p {
                font-size: 16px;
                font-family: "FranklinGothicLTPro-Bk", "Segoe UI", "Helvetica Neue", sans-serif;
                line-height: 22px;
                margin-bottom: 0;
                a {
                    color: #fff;
                }
            }

        }

        h2 {
            font-size: 48px;
            line-height: 57px;
            margin-bottom: 26px;
            display: block;
            width: 100%;
            max-width: 480px;
            @media (max-width: 768px) {
                font-size: 40px;
                line-height: 48px;
            }
        }

        h1 {
            font-size: 80px;
            line-height: 100px;
        }

        h3 {
            font-size: 22px;
            line-height: 27px;
        }

        picture {
            height: 100%;
            object-fit: cover;
            width: 100%;
            display: block;
            overflow: hidden;
            @media (max-width: 768px) {
                // display: none !important;
            }
            img {
                max-width: 300%;
                min-height: 100%;
                min-width: 100%;
            }
        }
   }
</style>

<script>
    export default {
        components: {
        },
        data() {
            return {}
        },
        props: ['blok'],
        methods: {
            background() {
                if(this.blok.background) {
                    return 'background:' + this.blok.background.color;
                }
            },
            textColor() {
                return 'color: ' + this.blok.text_color;
            },
            bgimage() {
                if (this.blok.image) {
                    return 'background-image:url(' + this.blok.image.filename + ')';
                }
            }
            
        },
        mounted() {
            console.debug(this.blok);
            let para = document.createElement('script');
            para.setAttribute('src', 'https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js');
            document.head.appendChild(para);
            para.onload = function() {
                var image = document.querySelectorAll('img.slideimage');
                var image2 = document.querySelectorAll('img.bs-image__image');
                new simpleParallax(image, {delay: 0.1, transition: 'cubic-bezier(.49,.73,.64,.66)', scale: 1.1});
                new simpleParallax(image2, {delay: 0.1, transition: 'cubic-bezier(.49,.73,.64,.66)', scale: 1.2});
		    };
        },
        computed: {
            description() {
                if (this.blok.description) {
                    return this.$storyapi.richTextResolver.render(this.blok.description)
                }
            },
            note() {
                if (this.blok.note) {
                    return this.$storyapi.richTextResolver.render(this.blok.note)
                }
            }
        }
    }
    </script>