<template>
	<div v-if="product" class="solution-product">
		<nuxt-link v-if="getProductSlug()" :to="'/' + getProductSlug() + '/'" class="primary-link">
			<picture v-if="image()">
				<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
				<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
				<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
				<img v-lazy-load :data-src="image()" :alt="product.name" />
			</picture>
			{{ product.name }}
		</nuxt-link>
	</div>
</template>

<style lang="scss">
.solution-product {
	display: inline-flex;
	a {
		display: block;
		margin-block-end: 40px;
		width: 130px;
	}

	img {
		margin-block-end: 15px;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.solution-product {
		a {
			margin-inline-end: 5px;
			margin-inline-start: 5px;
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.solution-product {
		a {
			margin-inline-end: 20px;
		}
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.imageSource && this.imageSource.filename) {
				// default size
				width = 130
				height = 130

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.imageSource.filename, {
					size: width + 'x' + height,
					focus: this.imageSource.focus,
					fileType: fileType
				})
				return processedImage
			}
		},
		getProductSlug(){
            if (this.blok.product){
                const product = this.$store.getters['products/getProductByUUID'](this.blok.product.id)
                return product.full_slug;
            }   
        }
	},
	mounted() {},
	computed: {
		product() {
			if (this.blok.product) return this.$store.getters['products/getProductByUUID'](this.blok.product.id)
		},
		imageSource() {
			if (this.product && this.product.content && this.product.content.data) {
				let thumb

				this.product.content.data.forEach(item => {
					if (item.component === 'product_data') {
						if (item.thumbnail && item.thumbnail.filename)
							thumb = item.thumbnail
					}
				})

				if (thumb)
					return thumb
			}
		}
	}
}
</script>
