var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      ref: "videoClip",
      staticClass: "video-clip"
    },
    [
      _c(
        "video",
        {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          ref: "video",
          staticClass: "video-clip__video",
          attrs: {
            loop: _vm.blok.loop,
            muted: "",
            playsinline: "",
            "data-poster": _vm.blok.poster.filename,
            "aria-label": _vm.blok.aria_description,
            autoplay: ""
          },
          domProps: { muted: true }
        },
        [
          _c("source", {
            ref: "videoSrc",
            attrs: { "data-src": _vm.blok.mp4.filename, type: "video/mp4" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }