var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      ref: "carouselContainer",
      staticClass: "carousel-container",
      class: _vm.checkAutoplay
    },
    [
      _c(
        "button",
        {
          ref: "skipBtn",
          staticClass: "carousel-skip",
          attrs: { type: "button" }
        },
        [
          _vm._v(
            "Skip Carousel of " +
              _vm._s(_vm.blok.carousel_item.length) +
              " Items"
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { ref: "carousel", staticClass: "carousel" },
        _vm._l(_vm.blok.carousel_item, function(item) {
          return _c(
            "li",
            { key: _vm.blok.carousel_item.id },
            _vm._l(item.block, function(hero) {
              return _c(_vm._f("dashify")(hero.component), {
                key: hero._uid,
                tag: "Hero",
                attrs: { blok: hero }
              })
            }),
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "carousel__bullets",
          style: "--timing: " + _vm.timing + "ms"
        },
        _vm._l(_vm.blok.carousel_item, function(item, index) {
          return _c(
            "li",
            {
              key: _vm.blok.carousel_item.id,
              attrs: { "aria-hidden": "true" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "carousel__bullet",
                  class: _vm.slide == index ? "active" : "",
                  attrs: { tabindex: "-1" },
                  on: {
                    click: function($event) {
                      _vm.autoplayStop(), _vm.jumpTo(index)
                    }
                  }
                },
                [_vm._v("Item " + _vm._s(index))]
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.blok.carousel_item.length > 1
        ? [
            _c(
              "button",
              {
                ref: "prevBtn",
                staticClass: "carousel__btn carousel__btn--prev",
                attrs: { "data-delta": "-1", tabindex: "-1" },
                on: { click: _vm.changeSlide }
              },
              [_vm._v("Previous Slide")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "nextBtn",
                staticClass: "carousel__btn carousel__btn--next",
                attrs: { "data-delta": "1", tabindex: "-1" },
                on: { click: _vm.changeSlide }
              },
              [_vm._v("Next Slide")]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }