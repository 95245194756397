<template>
	<div class="top-link-bar">
		<a v-for="tab in this.tabs" 
			:href="tab.link + '#tabs'"
			:class="isSelected(tab.link) ? 'selected-tab' : false">
			{{tab.label}}
		</a>
	</div>
</template>

<style lang="scss">
.top-link-bar {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 920px;
	max-width: 100%;
	a {
		display: block;
		background: #EDE9E3;
		color: #000;
		font-family: "Teodor-Regular", "Times New Roman", Times, serif;
		font-size: 16px;
		height: 52px;
		flex-basis: 33%;
		width: 33%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		border-left: 1px solid #CBCBCB;
		line-height: 48px;
		padding-top: 4px;
		&:first-child {
			border-left: 0;
		}

		&.selected-tab {
			background: #fff;
		}
	}

	@media (max-width: 1024px) {
		width: 100%;
		border-bottom: 1px solid #CBCBCB;
		a {
			width: 33.33%;
			flex-basis: 33.33%;
			font-size: 15px;
			padding-top: 0;
		}
	}
}
</style>

<script>
export default {
	components: {
	},
	data() {
		return {
			tabs: [
				{ label: 'Features', link: '/products/gira/' },
				{ label: 'Reconfigure', link: '/products/gira/reconfigure/' },
				{ label: 'Design Resources', link: '/products/gira/design-resources/' }
			],
			selected: ''
		}
	},
	props: ['blok'],
	methods: {
		isSelected(url){
			if(url === this.$route.path || url + '/' === this.$route.path){
				return true;
			}
			return false;
		}
	},
	mounted() {
		// console.log(this.$route);
		// console.log(this.blok);
	},
	computed: {}
}
</script>
