<template>
	<section class="interactive-block-parent">
		<div v-editable="blok" class="interactive-block__mobile" :style="backgroundStyle()"  data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
			<div class="interactive-block__content">
				<span v-if="blok.Number" class="interactive-block__number">
					{{blok.Number}}
				</span>
				<div v-if="text" v-html="text"></div>
			</div>
		</div>
		<div v-editable="blok" class="interactive-block" :style="backgroundStyle()">
			<div class="interactive-block__content">
				<span v-if="blok.Number" class="interactive-block__number">
					{{blok.Number}}
				</span>
				<div v-if="text" v-html="text"></div>
			</div>
			<div  class="interactive-block__selector" v-if="hasAtLeastOneItem()" :class="hasItems() ? '' : 'hide'">
				<a class="interactive-block__selector--toggle" >
					<svg width="16px" height="10px" viewBox="0 0 16 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<g id="PDP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="reconfigure-3" transform="translate(-1283.000000, -3155.000000)" fill="#151515">
								<g id="Group" transform="translate(1194.000000, 3139.000000)">
									<g transform="translate(89.000000, 16.000000)" id="drop-down-arrow">
										<polygon id="Fill-1" transform="translate(8.000000, 4.801876) rotate(-270.000000) translate(-8.000000, -4.801876) " points="3.19812391 11.6389217 10.400938 4.79249563 3.21688124 -2.05392807 4.39859293 -3.19812391 12.8018761 4.79249563 4.3798356 12.8018761"></polygon>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</a>
				<div class="interactive-block__selector--buttons">
					<div class="interactive-block__selector--buttonsList">
						<button
							:class="isSelectedColor(blok.color_label) ? 'selected-color' : false"
							:data-color="blok.color_label" 
							v-for="blok in blok.items" @click="changeSelectedColor">
							<span class="swatch" :style="blok.swatch ? 'background-image:url(' + blok.swatch.filename + ')' : 'background-color:'+ blok.color.color">
							</span> 
							{{blok.color_label}}
						</button>
					</div>
				</div>
				
			</div>
			<template v-if="hasAtLeastOneItem()">
				<component :selected="isSelectedColor(blok.color_label)" :key="blok._uid" v-for="blok in blok.items" :blok="blok" :is="blok.component | dashify"></component>
			</template>
		</div>
	</section>
</template>

<style lang="scss">
.selected-color {
	span {
		outline: 2px solid #7FCBFF;
	}
}
	.hide {
		display: none;
	}
	.interactive-block-parent {
		width: 100%;
		min-height: 100vh;
	}
	.interactive-block {
		width: 100%;
		height: 100vh;
		background: #fff;
		position: relative;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		@media (min-width: 1025px) {
			min-height: 650px;
		}

		&__number {
			font-family: "Teodor-Regular", "Times New Roman", Times, serif;
			font-size: 160px;
			line-height: 160px;
			display: inline-block;
			margin-bottom: 40px;
			&:after {
				content: "";
				width: 54%;
				height: 14px;
				background: #FF5601;
				display: block;
				margin-left: 4px;
			}

			@media (max-width: 1024px) {
				margin-right: -20px;
				&:after {
					width: 62%;
				}
			}
		}

		&__content {
			position: absolute;
			left:0;
			top: 0;
			width: 330px;
			z-index: 10;
			height: 100%;
			background: rgba(255,255,255,0.8);
			padding: 100px 50px;
			@media (max-width: 1024px) {
				display: none;
			}

			h3 {
				font-size: 28px;
				line-height: 1.2;
				margin-bottom: 40px;
			}

			a {
				color: #FF5601;
			}
		}

		&__selector {
			position: absolute;
			bottom: 40px;
			right: 40px;
			border-radius: 6px;
			background: #fff;
			z-index: 15;
			width: auto;
			min-width: 195px;
			height: auto;
			box-shadow: 0px 9px 10px rgba(0,0,0,0.2);
			@media (max-width: 1024px) {
				right: auto;
				left: 50%;
				transform: translateX(-50%);
				min-width: 240px;
			}

			&--toggle {
				display: block;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 13px;
				border-bottom: 1px solid #CBCBCB;
				cursor: pointer;
				svg {
					transition: all 0.2s;
				}

				&.toggled {
					border-bottom: 0;
					svg {
						transform: rotate(180deg);
					}
				}
			}

			&--buttons {
				display: block;
				width: 100%;
				height: auto;
				padding: 8px 10px;
				

				button {
					display: flex;
					width: 100%;
					padding: 6px 5px;
					text-align: left;
					align-items: center;
					font-size: 16px;
					position: relative;
					z-index: 5;
					max-width: 290px;
					span {
						display: block;
						width: 38px;
						height: 38px;
						border: 3px solid #fff;
						border-radius: 50%;
						margin-right: 10px;
						background-size: cover;
						background-position: center center;
						background-repeat: no-repeat;
						z-index: -1;
						position: relative;
						margin-top: -3px;
						flex-basis: 38px;
						min-width: 38px;
					}

					&:before {
						width: 48px;
						height: 100%;
						display: block;
						content:"";
						z-index: 5;
						left: 0;
						top: 0px;
						position: absolute;
					}
				}
			}

			&--buttonsList {
				width: 100%;
				max-height: 200px;
				overflow-y: auto;
				scroll-behavior: smooth;
				scroll-snap-type: y mandatory;
				scrollbar-color: #fff var(--gray-lite);
				scrollbar-width: thin;
				-webkit-overflow-scrolling: touch;

				&::-webkit-scrollbar-track {
					background: var(--gray-lite);
					border-radius: 6px;
				}
				&::-webkit-scrollbar-thumb {
					background-color: var(--black);
					border-radius: 6px;
				}
				&::-webkit-scrollbar {
					width: 6px;
					border-radius: 6px;
				}
				&::-webkit-scrollbar-track {
					margin: 0 var(--side-space);
				}
			}
		}

		&__mobile {
			display: none;
			@media (max-width: 1024px) {
				display: block;
				width: 100%;
				height: 100vh;
				position: relative;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat; 
				.interactive-block__content {
					display: flex;
					width: 100%;
					text-align: center;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					p {
						font-size: 18px;
					}
				}
			}
		}

		&__fullscreen {
			display: none;
			@media (max-width: 1024px) {
				display: block;
				position: absolute;
				top: 20px;
				right: 20px;
				z-index: 20;
			}

			&__div {
				display: none;
				position: fixed;
				width: 100%;
				height: 100%; 
				left: 0;
				top: 0;
				z-index: 200;
				overflow-x: scroll;
				img {
					max-width: unset;
					max-height: 100%;
					width: auto;
					display: block;
				} 

				&__close {
					position: fixed;
					z-index: 202;
					right: 20px;
					top: 20px;
				}
			}
		}
	}
</style>

<script>
import $ from 'jquery';
export default {
	components: {},
	data() {
		return {
			selectedColor:''
		}
	},
	props: ['blok'],
	methods: {
		changeSelectedColor(event) {
			if (event) { 
				this.selectedColor = event.target.dataset.color;
				$(event.target).closest('.interactive-block').removeAttr('style');
			}
		},
		isSelectedColor(color){
			return color === this.selectedColor;
		},
		hasAtLeastOneItem(){
			return this.blok && this.blok.items.length > 0;
		},
		hasItems(){
			return this.blok && this.blok.items.length > 1;
		},
		toggle(event) {
			if (event) {
			
			}
		},

		backgroundStyle() {
			if(this.blok && this.blok.background) {
				return 'background-image:url(' + this.blok.background.filename + ')';
			}
		}
	},
	mounted() {
		if(this.hasAtLeastOneItem() && this.blok.preselect){
			this.selectedColor = this.blok.items[0].color_label;
		}

	},
	computed: {
		text() {
			if (this.blok.text) {
				return this.$storyapi.richTextResolver.render(this.blok.text).replace(/\|/g, "<br />");
			}
		}

	}
}
</script>
