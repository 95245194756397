<template>
	<div v-editable="blok" class="solution-details">
		<div class="solution-details__products">
			<h2 class="section-title">Product(s) in use</h2>
			<component :key="blok._uid" v-for="blok in blok.products" :blok="blok" :is="blok.component | dashify"></component>
		</div>

		<div v-if="blok.title_1" class="solution-details__info">
			<h2 class="section-title">Info</h2>

			<h3 v-if="blok.title_1" class="sub-title">{{ blok.title_1 }}</h3>
			<p v-if="blok.description_1">{{ blok.description_1 }}</p>

			<h3 v-if="blok.title_2" class="sub-title">{{ blok.title_2 }}</h3>
			<p v-if="blok.description_2">{{ blok.description_2 }}</p>

			<h3 v-if="blok.title_3" class="sub-title">{{ blok.title_3 }}</h3>
			<p v-if="blok.description_3">{{ blok.description_3 }}</p>

			<h3 v-if="blok.title_4" class="sub-title">{{ blok.title_4 }}</h3>
			<p v-if="blok.description_4">{{ blok.description_4 }}</p>
		</div>
	</div>
</template>

<style lang="scss">
.solution-details {
	border-block-end: 2px solid var(--gray-lite);
	margin: 0 auto var(--margin-bottom);
	padding: 0 0 20px;
	width: min(var(--content-width), 100%);

	&__products {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px;

		h2 {
			width: 100%;
		}
	}
	&__info {
		display: grid;
		grid-template-areas:
			'h2 h2'
			'a c'
			'b d'
			'e g'
			'f h';
		grid-template-columns: repeat(2, 1fr);

		h2 {
			grid-area: h2;
		}

		h3 {
			margin-block-end: 0;
		}

		h3:nth-of-type(1) {
			grid-area: a;
		}
		p:nth-of-type(1) {
			grid-area: b;
		}

		h3:nth-of-type(2) {
			grid-area: c;
		}
		p:nth-of-type(2) {
			grid-area: d;
		}

		h3:nth-of-type(3) {
			grid-area: e;
		}
		p:nth-of-type(3) {
			grid-area: f;
		}

		h3:nth-of-type(4) {
			grid-area: g;
		}
		p:nth-of-type(4) {
			grid-area: h;
		}
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.solution-details {
		--margin-bottom: 50px;

		&__products {
			margin-block-end: 10px;

			h2 {
				margin-inline-start: 5px;
			}
		}

		&__info {
			max-width: 375px;
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.solution-details {
		--margin-bottom: 150px;

		@include grid;

		&__products {
			grid-column: 1 / span 6;
		}

		&__info {
			grid-column: 7 / span 6;
		}
	}
}

/* Large & Up Screen */
@media all and #{$mq-l-min} {
	.solution-details {
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		rte() {
			if (this.blok.rte) {
				return this.$storyapi.richTextResolver.render(this.blok.rte)
			}
		}
	}
}
</script>
