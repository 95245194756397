var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "page-header-account"
    },
    [
      _c(
        "div",
        [
          _vm._l(_vm.blok.breadcrumb, function(breadcrumb) {
            return _c(_vm._f("dashify")(breadcrumb.component), {
              key: breadcrumb._uid,
              tag: "Breadcrumb",
              attrs: { blok: breadcrumb }
            })
          }),
          _vm._v(" "),
          _vm.blok.title
            ? _c("h1", [_vm._v(_vm._s(_vm.blok.title))])
            : _vm._e(),
          _vm._v(" "),
          _vm.blok.profile_link
            ? _c("nuxt-link", { attrs: { to: "/account/profile" } }, [
                _vm._v("Edit Profile")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.description
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", [
        _vm.blok.logout_button
          ? _c(
              "button",
              {
                staticClass: "primary-button",
                attrs: { type: "button" },
                on: { click: _vm.logOut }
              },
              [_vm._v("Log Out")]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }