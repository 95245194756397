<template>
	<div class="modal-forms">
		<div class="user-login" v-if="toggleForms">
			<form @submit.prevent="handleLogin" class="bn-form" :class="this.login.errors ? 'has-errors' : false" method="post">
				<!-- <input type="hidden" name="_token" value="SqpUCDWjaDb3F7t85A5S9n1WAaShSM7BuLjkfUNj"> -->

				<h2>Log In</h2>

				<p><small>If you don’t yet have an account, please create one to access all that B+N has to offer. Existing user? You may need to create a new account due to our new website as of August 2021.</small></p>

				<p v-if="this.errorServer" class="error">There was a problem with creating the account, please try again.</p>
				<p v-if="errors('email')" v-html="errors('email')" class="error"></p>

				<fieldset>
					<div class="form-field">
						<label for="email">Email Address</label>
						<input type="email" id="email" name="email" required @invalid="invalidateLoginForm" v-model="login.email" />
					</div>

					<div class="form-field">
						<label for="password">Password</label>
						<input type="password" id="password" name="password" required @invalid="invalidateLoginForm" v-model="login.password" />
					</div>

					<div class="field-group" data-size="half">
						<div class="form-field">
							<input id="remember-me" type="checkbox" name="remember-me" v-model="login.rememberMe" />
							<label for="remember-me" class="for-checkbox">Remember me</label>
						</div>
						<div class="form-field" data-align="right">
							<button type="button" class="link-style" @click="toggleForms = !toggleForms">Forgot Password?</button>
						</div>
					</div>
				</fieldset>

				<div class="form-actions">
					<button type="submit" class="primary-button">Log In</button>

					<nuxt-link to="/create-account" @click.native="closeModal">Create a new account</nuxt-link>
				</div>
			</form>
		</div>

		<div class="forgot-password" v-if="!toggleForms">
			<form v-if="!toogleForgotPassword" @submit.prevent="resetPassword" class="bn-form" :class="this.forgotPassword.errors ? 'has-errors' : false" method="post">
				<!-- <input type="hidden" name="_token" value="SqpUCDWjaDb3F7t85A5S9n1WAaShSM7BuLjkfUNj">-->

				<h2>Forgot Password</h2>
				<p>Please enter the email address associated with your B+N account.</p>

				<fieldset>
					<div class="form-field">
						<label for="email">Email Address</label>
						<input type="email" id="email" name="email" required @invalid="invalidateForgotPassworForm" v-model="forgotPassword.email" />
					</div>
				</fieldset>

				<div class="form-actions">
					<button type="submit" class="primary-button">Reset Password</button>
				</div>
			</form>

			<template v-if="toogleForgotPassword">
				<h2>Reset Password Submitted</h2>
				<p>Please check your email for further instructions to complete the process.</p>
			</template>
		</div>
	</div>
</template>

<style lang="scss">
.modal-forms {
	margin: 0 auto;
	width: min(425px, 100%);

	h2 {
		margin-block-end: 1.5em;
		text-align: center;
	}

	.form-actions {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.user-login {
	.form-actions {
		min-height: 90px;
	}

	[data-align='right'] {
		text-align: right;
	}
}
</style>

<script>
// work in progress - scratch is in UserProfile component for now
export default {
	components: {},
	data() {
		return {
			toggleForms: true,
			toogleForgotPassword: false,
			login: {
				errors: false,
				email: '',
				password: '',
				rememberMe: false
			},
			forgotPassword: {
				errors: false,
				email: ''
			},
			errorServer: false,
			errorMessages: null
		}
	},
	props: ['blok'],
	computed: {},
	methods: {
		closeModal() {
			this.$bus.$emit('closeModal')
		},
		handleIntent() {
			if (process.client) {
				this.$intent.handle()
			}
		},
		invalidateForgotPassworForm() {
			this.forgotPassword.errors = true
		},
		invalidateLoginForm() {
			this.login.errors = true
		},
		handleLogin() {
			// clear existing errors first
			this.errorMessages = null

			this.$auth
				.loginWith('laravelSanctum', {
					data: this.login
				})
				.then(response => {
					this.closeModal()
					this.handleIntent()
                    this.$bus.$emit('loginSuccess');
				})
				.catch(error => {
					// do nothing if there is no error object
					if (!error) return

					// use server-side validation messages if they exist
					// otherwise use a generic error message
					if (error.response && error.response.data && error.response.data.errors) this.errorMessages = error.response.data.errors
					else this.errorServer = true
				})
		},
		errors(fieldId) {
			// return formatted error messages for a given field

			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		resetPassword() {
			this.$axios
				.post(process.env.requestPasswordChangeEndpoint, {
					email: this.forgotPassword.email
				})
				.then(response => {
					// @todo Show success message: "We have emailed you a reset password link"
					this.toogleForgotPassword = true
				})
				.catch(error => {
					// use server-side validation messages if they exist
					// otherwise use a generic error message
					if (error.response && error.response.data && error.response.data.errors) this.errorMessages = error.response.data.errors
					else this.errorServer = true
				})
		}
	},
	mounted() {}
}
</script>
