var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "message-block"
    },
    [
      _c("h1", [_vm._v(_vm._s(_vm.blok.title))]),
      _vm._v(" "),
      _vm.text1
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.text1) } })
        : _vm._e(),
      _vm._v(" "),
      _vm.text2 && _vm.acctMessage
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.text2) } })
        : _vm._e(),
      _vm._v(" "),
      _vm.blok.cta_link && _vm.blok.cta_link.cached_url
        ? _c(
            "nuxt-link",
            {
              staticClass: "secondary-button",
              attrs: { to: "/" + _vm.blok.cta_link.cached_url + "/" }
            },
            [_vm._v(_vm._s(_vm.blok.cta_text))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }