var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "bn-form"
    },
    [
      _c("h2", { staticClass: "section-title" }, [
        _vm._v(_vm._s(_vm.blok.title))
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "filters" },
        _vm._l(_vm.productApplications, function(application) {
          return _c(
            "div",
            { key: application._uid, staticClass: "form-field" },
            [
              _c("input", {
                attrs: {
                  id: "application-" + application.value,
                  type: "radio",
                  name: "radio-options"
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "for-radio",
                  attrs: { for: "application-" + application.value }
                },
                [_vm._v(_vm._s(application.name))]
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }