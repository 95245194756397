var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ]
    },
    [
      _c("figure", [
        _c("img", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          attrs: {
            "data-src": _vm.blok.image.filename,
            alt: _vm.blok.image.alt
          }
        }),
        _vm._v(" "),
        _c("figcaption", [_vm._v(_vm._s(_vm.blok.title))])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }