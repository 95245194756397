var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "solutions-list" },
    [_c("Cards", { attrs: { cardData: _vm.cardData } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }