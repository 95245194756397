import Vue from 'vue'

const components = {
  Accordion: () => import('../../components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => c.default || c),
  AccordionItem: () => import('../../components/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => c.default || c),
  Blockquote: () => import('../../components/Blockquote.vue' /* webpackChunkName: "components/blockquote" */).then(c => c.default || c),
  Bookmark: () => import('../../components/Bookmark.vue' /* webpackChunkName: "components/bookmark" */).then(c => c.default || c),
  Breadcrumb: () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => c.default || c),
  Callout: () => import('../../components/Callout.vue' /* webpackChunkName: "components/callout" */).then(c => c.default || c),
  Cards: () => import('../../components/Cards.vue' /* webpackChunkName: "components/cards" */).then(c => c.default || c),
  CardsItem: () => import('../../components/CardsItem.vue' /* webpackChunkName: "components/cards-item" */).then(c => c.default || c),
  Carousel: () => import('../../components/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => c.default || c),
  ContactButton: () => import('../../components/ContactButton.vue' /* webpackChunkName: "components/contact-button" */).then(c => c.default || c),
  ContactInfo: () => import('../../components/ContactInfo.vue' /* webpackChunkName: "components/contact-info" */).then(c => c.default || c),
  Course: () => import('../../components/Course.vue' /* webpackChunkName: "components/course" */).then(c => c.default || c),
  EmailSignUp: () => import('../../components/EmailSignUp.vue' /* webpackChunkName: "components/email-sign-up" */).then(c => c.default || c),
  FormContact: () => import('../../components/FormContact.vue' /* webpackChunkName: "components/form-contact" */).then(c => c.default || c),
  FormCreateAccount: () => import('../../components/FormCreateAccount.vue' /* webpackChunkName: "components/form-create-account" */).then(c => c.default || c),
  FormRequest: () => import('../../components/FormRequest.vue' /* webpackChunkName: "components/form-request" */).then(c => c.default || c),
  FullPageScroll: () => import('../../components/FullPageScroll.vue' /* webpackChunkName: "components/full-page-scroll" */).then(c => c.default || c),
  GDPR: () => import('../../components/GDPR.vue' /* webpackChunkName: "components/gdpr" */).then(c => c.default || c),
  Gallery: () => import('../../components/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => c.default || c),
  GlobalFooter: () => import('../../components/GlobalFooter.vue' /* webpackChunkName: "components/global-footer" */).then(c => c.default || c),
  GlobalHeader: () => import('../../components/GlobalHeader.vue' /* webpackChunkName: "components/global-header" */).then(c => c.default || c),
  GlobalModal: () => import('../../components/GlobalModal.vue' /* webpackChunkName: "components/global-modal" */).then(c => c.default || c),
  Hero: () => import('../../components/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => c.default || c),
  Home: () => import('../../components/Home.vue' /* webpackChunkName: "components/home" */).then(c => c.default || c),
  ImageFeature: () => import('../../components/ImageFeature.vue' /* webpackChunkName: "components/image-feature" */).then(c => c.default || c),
  ImageGrid: () => import('../../components/ImageGrid.vue' /* webpackChunkName: "components/image-grid" */).then(c => c.default || c),
  Instagram: () => import('../../components/Instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => c.default || c),
  LayoutAside: () => import('../../components/LayoutAside.vue' /* webpackChunkName: "components/layout-aside" */).then(c => c.default || c),
  ListActions: () => import('../../components/ListActions.vue' /* webpackChunkName: "components/list-actions" */).then(c => c.default || c),
  ListActionsItem: () => import('../../components/ListActionsItem.vue' /* webpackChunkName: "components/list-actions-item" */).then(c => c.default || c),
  ListDetails: () => import('../../components/ListDetails.vue' /* webpackChunkName: "components/list-details" */).then(c => c.default || c),
  ListDetailsItem: () => import('../../components/ListDetailsItem.vue' /* webpackChunkName: "components/list-details-item" */).then(c => c.default || c),
  ListIcon: () => import('../../components/ListIcon.vue' /* webpackChunkName: "components/list-icon" */).then(c => c.default || c),
  ListIconItem: () => import('../../components/ListIconItem.vue' /* webpackChunkName: "components/list-icon-item" */).then(c => c.default || c),
  MegamenuCard: () => import('../../components/MegamenuCard.vue' /* webpackChunkName: "components/megamenu-card" */).then(c => c.default || c),
  MegamenuContent: () => import('../../components/MegamenuContent.vue' /* webpackChunkName: "components/megamenu-content" */).then(c => c.default || c),
  MessageBlock: () => import('../../components/MessageBlock.vue' /* webpackChunkName: "components/message-block" */).then(c => c.default || c),
  Modal: () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => c.default || c),
  Page: () => import('../../components/Page.vue' /* webpackChunkName: "components/page" */).then(c => c.default || c),
  Page404: () => import('../../components/Page404.vue' /* webpackChunkName: "components/page-404" */).then(c => c.default || c),
  PageConfigurator: () => import('../../components/PageConfigurator.vue' /* webpackChunkName: "components/page-configurator" */).then(c => c.default || c),
  PageCustomServices: () => import('../../components/PageCustomServices.vue' /* webpackChunkName: "components/page-custom-services" */).then(c => c.default || c),
  PageError: () => import('../../components/PageError.vue' /* webpackChunkName: "components/page-error" */).then(c => c.default || c),
  PageHeader: () => import('../../components/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => c.default || c),
  PageHeaderAccount: () => import('../../components/PageHeaderAccount.vue' /* webpackChunkName: "components/page-header-account" */).then(c => c.default || c),
  PageHome: () => import('../../components/PageHome.vue' /* webpackChunkName: "components/page-home" */).then(c => c.default || c),
  PageRollingOffice: () => import('../../components/PageRollingOffice.vue' /* webpackChunkName: "components/page-rolling-office" */).then(c => c.default || c),
  Picture: () => import('../../components/Picture.vue' /* webpackChunkName: "components/picture" */).then(c => c.default || c),
  ProductApplications: () => import('../../components/ProductApplications.vue' /* webpackChunkName: "components/product-applications" */).then(c => c.default || c),
  ProductData: () => import('../../components/ProductData.vue' /* webpackChunkName: "components/product-data" */).then(c => c.default || c),
  ProductDetails: () => import('../../components/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => c.default || c),
  ProductsFilter: () => import('../../components/ProductsFilter.vue' /* webpackChunkName: "components/products-filter" */).then(c => c.default || c),
  ProductsList: () => import('../../components/ProductsList.vue' /* webpackChunkName: "components/products-list" */).then(c => c.default || c),
  ResetPassword: () => import('../../components/ResetPassword.vue' /* webpackChunkName: "components/reset-password" */).then(c => c.default || c),
  SectionIntro: () => import('../../components/SectionIntro.vue' /* webpackChunkName: "components/section-intro" */).then(c => c.default || c),
  Slider: () => import('../../components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => c.default || c),
  SliderItem: () => import('../../components/SliderItem.vue' /* webpackChunkName: "components/slider-item" */).then(c => c.default || c),
  Social: () => import('../../components/Social.vue' /* webpackChunkName: "components/social" */).then(c => c.default || c),
  SolutionData: () => import('../../components/SolutionData.vue' /* webpackChunkName: "components/solution-data" */).then(c => c.default || c),
  SolutionDetails: () => import('../../components/SolutionDetails.vue' /* webpackChunkName: "components/solution-details" */).then(c => c.default || c),
  SolutionDetailsProduct: () => import('../../components/SolutionDetailsProduct.vue' /* webpackChunkName: "components/solution-details-product" */).then(c => c.default || c),
  SolutionsFilter: () => import('../../components/SolutionsFilter.vue' /* webpackChunkName: "components/solutions-filter" */).then(c => c.default || c),
  SolutionsList: () => import('../../components/SolutionsList.vue' /* webpackChunkName: "components/solutions-list" */).then(c => c.default || c),
  Stat: () => import('../../components/Stat.vue' /* webpackChunkName: "components/stat" */).then(c => c.default || c),
  TextBlock: () => import('../../components/TextBlock.vue' /* webpackChunkName: "components/text-block" */).then(c => c.default || c),
  TextFeature: () => import('../../components/TextFeature.vue' /* webpackChunkName: "components/text-feature" */).then(c => c.default || c),
  TextMedia: () => import('../../components/TextMedia.vue' /* webpackChunkName: "components/text-media" */).then(c => c.default || c),
  TextMediaItem: () => import('../../components/TextMediaItem.vue' /* webpackChunkName: "components/text-media-item" */).then(c => c.default || c),
  TextSectioned: () => import('../../components/TextSectioned.vue' /* webpackChunkName: "components/text-sectioned" */).then(c => c.default || c),
  Timeline: () => import('../../components/Timeline.vue' /* webpackChunkName: "components/timeline" */).then(c => c.default || c),
  TimelineItem: () => import('../../components/TimelineItem.vue' /* webpackChunkName: "components/timeline-item" */).then(c => c.default || c),
  UserBookmarks: () => import('../../components/UserBookmarks.vue' /* webpackChunkName: "components/user-bookmarks" */).then(c => c.default || c),
  UserLogin: () => import('../../components/UserLogin.vue' /* webpackChunkName: "components/user-login" */).then(c => c.default || c),
  UserLogout: () => import('../../components/UserLogout.vue' /* webpackChunkName: "components/user-logout" */).then(c => c.default || c),
  UserProfile: () => import('../../components/UserProfile.vue' /* webpackChunkName: "components/user-profile" */).then(c => c.default || c),
  VideoClip: () => import('../../components/VideoClip.vue' /* webpackChunkName: "components/video-clip" */).then(c => c.default || c),
  CustomServicesHero: () => import('../../components/CustomServices/CustomServicesHero.vue' /* webpackChunkName: "components/custom-services-hero" */).then(c => c.default || c),
  CustomServicesImage: () => import('../../components/CustomServices/CustomServicesImage.vue' /* webpackChunkName: "components/custom-services-image" */).then(c => c.default || c),
  CustomServicesImageText: () => import('../../components/CustomServices/CustomServicesImageText.vue' /* webpackChunkName: "components/custom-services-image-text" */).then(c => c.default || c),
  CustomServicesText: () => import('../../components/CustomServices/CustomServicesText.vue' /* webpackChunkName: "components/custom-services-text" */).then(c => c.default || c),
  CustomServicesTextOnImage: () => import('../../components/CustomServices/CustomServicesTextOnImage.vue' /* webpackChunkName: "components/custom-services-text-on-image" */).then(c => c.default || c),
  RollingOfficeBio: () => import('../../components/RollingOffice/Bio.vue' /* webpackChunkName: "components/rolling-office-bio" */).then(c => c.default || c),
  RollingOfficeBioItem: () => import('../../components/RollingOffice/BioItem.vue' /* webpackChunkName: "components/rolling-office-bio-item" */).then(c => c.default || c),
  RollingOfficeInteractiveImage: () => import('../../components/RollingOffice/InteractiveImage.vue' /* webpackChunkName: "components/rolling-office-interactive-image" */).then(c => c.default || c),
  RollingOfficeInteractiveImageItem: () => import('../../components/RollingOffice/InteractiveImageItem.vue' /* webpackChunkName: "components/rolling-office-interactive-image-item" */).then(c => c.default || c),
  RollingOfficeMedia: () => import('../../components/RollingOffice/Media.vue' /* webpackChunkName: "components/rolling-office-media" */).then(c => c.default || c),
  RollingOfficeMediaAndText: () => import('../../components/RollingOffice/MediaAndText.vue' /* webpackChunkName: "components/rolling-office-media-and-text" */).then(c => c.default || c),
  RollingOfficePdpVideo: () => import('../../components/RollingOffice/PdpVideo.vue' /* webpackChunkName: "components/rolling-office-pdp-video" */).then(c => c.default || c),
  RollingOfficeTabLinkBar: () => import('../../components/RollingOffice/TabLinkBar.vue' /* webpackChunkName: "components/rolling-office-tab-link-bar" */).then(c => c.default || c),
  RollingOfficeTextWithImage: () => import('../../components/RollingOffice/TextWithImage.vue' /* webpackChunkName: "components/rolling-office-text-with-image" */).then(c => c.default || c),
  RollingOfficeTextWithNumber: () => import('../../components/RollingOffice/TextWithNumber.vue' /* webpackChunkName: "components/rolling-office-text-with-number" */).then(c => c.default || c),
  RollingOfficeTextWithVideo: () => import('../../components/RollingOffice/TextWithVideo.vue' /* webpackChunkName: "components/rolling-office-text-with-video" */).then(c => c.default || c),
  RollingOfficeTile: () => import('../../components/RollingOffice/Tile.vue' /* webpackChunkName: "components/rolling-office-tile" */).then(c => c.default || c),
  RollingOfficeTileItem: () => import('../../components/RollingOffice/TileItem.vue' /* webpackChunkName: "components/rolling-office-tile-item" */).then(c => c.default || c),
  RollingOfficeTwoColumnText: () => import('../../components/RollingOffice/TwoColumnText.vue' /* webpackChunkName: "components/rolling-office-two-column-text" */).then(c => c.default || c)
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
