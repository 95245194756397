var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "sectioned-text"
    },
    [
      _c("h4", { staticClass: "section-title" }, [
        _vm._v(_vm._s(_vm.blok.group_title))
      ]),
      _vm._v(" "),
      _vm.content
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }