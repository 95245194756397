<template>
	<div v-editable="blok" :class="blok.custom_class" class="text-block" :data-size="[blok.col2 && 'col2']">
		<h2 v-if="blok.section_title">{{ blok.section_title }}</h2>

		<div v-if="text" v-html="text"></div>

		<div v-if="text2" v-html="text2"></div>
	</div>
</template>

<style lang="scss">
.text-block {
	--margin-bottom: 50px;

	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	> div {
		width: min(1100px, 100%);
	}

	h2 ~ h2 {
		margin-block-start: 1.25em;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.text-block[data-size='col2'] {
		> div {
			margin-block-end: 35px;
		}
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.text-block[data-size='col2'] {
		@include grid();
		row-gap: 25px;

		> h2 {
			grid-column: 1 / -1;
		}

		> div:nth-of-type(1) {
			grid-column: 1 / span 6;
		}

		> div:nth-of-type(2) {
			grid-column: 7 / span 6;
		}
	}
}
</style>

<script>
export default {
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		text() {
			if (this.blok.text) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		},
		text2() {
			if (this.blok.text_2) {
				return this.$storyapi.richTextResolver.render(this.blok.text_2).replace(/\|/g, "<br />");
			}
		}
	}
}
</script>
