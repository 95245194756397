<template>
	<li v-editable="blok">
		<template v-if="isFile">
			<template v-if="loggedIn || !blok.protected_file">
				<a :href="blok.file.filename" class="primary-link icon-download" target="_blank" rel="noopener">{{ blok.link_text }}</a>
			</template>
			<template v-else>
				<a href="#" class="primary-link icon-download" @click.prevent="showLoginModal">{{ blok.link_text }}</a>
			</template>
		</template>

		<template v-else-if="blok.external">
			<a v-if="blok.link && blok.link.cached_url" :href="blok.link.cached_url" class="primary-link" :class="download" target="_blank" rel="noopener">{{ blok.link_text }}</a>
		</template>

		<template v-else>
			<nuxt-link v-if="blok.link && blok.link.cached_url" :to="'/' + blok.link.cached_url + '/'" class="primary-link" :class="download">{{ blok.link_text }}</nuxt-link>
		</template>
	</li>
</template>

<style lang="scss">
.actions-list-container {
	margin: 0 auto var(--margin-bottom);
	max-width: var(--content-width);

	.section-title {
		margin-block-end: 1.5em;
	}
}
.actions-list {
	li {
		margin-block-end: 20px;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.actions-list-container {
		--margin-bottom: 50px;
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.actions-list-container {
		--margin-bottom: 80px;
	}

	.actions-list {
		@include grid();

		li {
			grid-column: span 3;
		}
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		showLoginModal() {
			// save intent object
			this.$intent.set('download', this.$route.path, { file: this.blok.file.filename })

			this.$bus.$emit('openModal')
		}
	},
	mounted() {},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		},
		isFile() {
			if (this.blok.file && this.blok.file.filename) return true
		},
		download() {
			if (this.blok.link.cached_url.includes('.pdf') || this.blok.link.cached_url.includes('.zip')) {
				return 'icon-download'
			}
		}
	}
}
</script>
