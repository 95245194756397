var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "page-header",
      class: [_vm.blok.add_divider && "page-header--divider"]
    },
    [
      _c(
        "div",
        { staticClass: "page-header__text" },
        [
          _vm._l(_vm.blok.breadcrumb, function(breadcrumb) {
            return _c(_vm._f("dashify")(breadcrumb.component), {
              key: breadcrumb._uid,
              tag: "Breadcrumb",
              attrs: { blok: breadcrumb }
            })
          }),
          _vm._v(" "),
          _vm.blok.title
            ? _c(
                "h1",
                [
                  _vm._v(_vm._s(_vm.blok.title) + " "),
                  _vm.blok.bookmark === true
                    ? _c("Bookmark", { attrs: { id: _vm.bookmarkId } })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.description
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } })
            : _vm._e(),
          _vm._v(" "),
          _vm.blok.note
            ? _c("p", { staticClass: "page-header__note" }, [
                _vm._v(_vm._s(_vm.blok.note))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.blok.contact_button === true
            ? _c("ContactButton", {
                attrs: { contact_text: _vm.blok.contact_text }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.image()
        ? _c(
            "picture",
            [
              this.blok.image.filename.endsWith("svg")
                ? [
                    _c("img", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-src": this.blok.image.filename,
                        alt: _vm.blok.image.alt
                      }
                    })
                  ]
                : [
                    _c("source", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-srcset": _vm.image("webp", true),
                        media:
                          "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                        type: "image/webp"
                      }
                    }),
                    _vm._v(" "),
                    _c("source", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-srcset": _vm.image(null, true),
                        media:
                          "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                        type: "image/jpeg"
                      }
                    }),
                    _vm._v(" "),
                    _c("source", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-srcset": _vm.image("webp"),
                        type: "image/webp"
                      }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-src": _vm.image(),
                        alt: _vm.blok.image.alt,
                        width: "450",
                        height: "235"
                      }
                    })
                  ]
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }