<template>
	<div v-editable="blok" class="bio-block">
		<h2 v-if="blok.title" v-html="blok.title"  data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center"></h2>
		<div class="bio-block__wrapper">
			<component :key="blok._uid" v-for="blok in blok.items" :blok="blok" :is="blok.component | dashify"></component>
		</div>
	</div>
</template>

<style lang="scss">
	.bio-block {
		background: #fff;
		width: 100%; 
		max-width: 1330px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
		padding: 40px 45px 100px;
		h2 {
			font-size: 48px;
			font-family: "Teodor-Regular", "Times New Roman", Times, serif;
			line-height: 1.1;
			margin-bottom: 40px;
			@media (max-width: 1024px) {
				text-align: center;
				font-size: 38px;
			}
		}

		&__wrapper {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			@media (max-width: 1024px) {
				flex-direction: column;
			}
		}
	}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
	}
}
</script>
