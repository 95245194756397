var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loggedIn || !_vm.blok.protected
    ? _c(
        "main",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ],
          staticClass: "configurator",
          attrs: { id: "content" }
        },
        [
          _c("iframe", {
            attrs: {
              frameborder: "0",
              "data-cfgrurl": _vm.blok.iframe_src.cached_url,
              title: _vm.blok.iframe_title
            }
          })
        ]
      )
    : _c(
        "main",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ],
          staticClass: "wait",
          attrs: { id: "content" }
        },
        [
          _c(
            "div",
            { staticClass: "content-container" },
            [_c("user-login", { attrs: { blok: _vm.blok } })],
            1
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }