var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.editable(),
          expression: "editable()"
        }
      ],
      staticClass: "cards-block",
      class: [!_vm.cardData && _vm.blok.is_gallery && "cards-block--gallery"],
      attrs: { "data-class": _vm.customClass() }
    },
    [
      _vm.cardData && _vm.cardData.uuids && _vm.cardData.uuids.length
        ? [
            _vm.cardData.section_title
              ? _c("header", [
                  _c("h2", { staticClass: "section-title" }, [
                    _vm._v(_vm._s(_vm.cardData.section_title))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "cards-grid",
                attrs: { "data-size": _vm.cardData.dataSize }
              },
              _vm._l(_vm.cardData.uuids, function(uuid) {
                return _c("CardsItem", {
                  key: uuid,
                  attrs: {
                    uuid: uuid,
                    type: _vm.cardData.type,
                    autocrop: _vm.cardData.autocrop,
                    columns: _vm.cardData.columns
                  }
                })
              }),
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.blok
        ? [
            _vm.blok.section_title ||
            (_vm.blok.cta_text && _vm.blok.cta_link.cached_url)
              ? _c(
                  "header",
                  [
                    this.inAccordion == undefined && _vm.blok.section_title
                      ? _c("h2", { staticClass: "section-title" }, [
                          _vm._v(_vm._s(_vm.blok.section_title))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    this.inAccordion == true && _vm.blok.section_title
                      ? _c("h4", { staticClass: "text-3" }, [
                          _vm._v(_vm._s(_vm.blok.section_title))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.blok.cta_text && _vm.blok.cta_link.cached_url
                      ? _c(
                          "nuxt-link",
                          {
                            staticClass: "primary-link",
                            attrs: {
                              to: "/" + _vm.blok.cta_link.cached_url + "/"
                            }
                          },
                          [_vm._v(_vm._s(_vm.blok.cta_text))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : [
                  _vm.blok.cta_text && _vm.blok.cta_link.cached_url
                    ? _c(
                        "nuxt-link",
                        {
                          staticClass: "primary-link",
                          attrs: {
                            to: "/" + _vm.blok.cta_link.cached_url + "/"
                          }
                        },
                        [_vm._v(_vm._s(_vm.blok.cta_text))]
                      )
                    : _vm._e()
                ],
            _vm._v(" "),
            _vm.blok.is_gallery
              ? _c(
                  "client-only",
                  [
                    _vm.galleryCardIndex !== null
                      ? _c(
                          "portal",
                          { attrs: { selector: "body" } },
                          [
                            _c("Gallery", {
                              ref: "gallery",
                              attrs: {
                                images: _vm.galleryImages,
                                index: _vm.galleryCardIndex,
                                "disable-scroll": true,
                                background: "rgba(0,0,0,1)",
                                interfaceColor: "#000"
                              },
                              on: { close: _vm.galleryClose }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.item
              ? _c(
                  "div",
                  {
                    staticClass: "cards-grid",
                    attrs: { "data-size": _vm.blok.columns }
                  },
                  [
                    _vm.blok.is_gallery
                      ? _vm._l(_vm.blok.item, function(item, index) {
                          return _c(_vm._f("dashify")(item.component), {
                            key: item._uid,
                            tag: "component",
                            attrs: {
                              blok: item,
                              autocrop: _vm.blok.autocrop,
                              columns: _vm.blok.columns,
                              galleryCardIndex: index
                            },
                            on: { cardClick: _vm.cardClick }
                          })
                        })
                      : _vm._l(_vm.blok.item, function(item) {
                          return _c(_vm._f("dashify")(item.component), {
                            key: item._uid,
                            tag: "component",
                            attrs: {
                              blok: item,
                              autocrop: _vm.blok.autocrop,
                              columns: _vm.blok.columns
                            }
                          })
                        })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.disclaimer
              ? _c("footer", {
                  domProps: { innerHTML: _vm._s(_vm.disclaimer) }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }