<template>
	<div v-editable="blok" class="bs-text" :style="background()">
		<div class="bs-text__wrapper" data-aos="fade" data-aos-offset="0" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
			<div class="bs-text__text" :style="textColor()">
                <div v-if="blok.above_title" v-html="blok.above_title" class="bs-text__pre"></div>	
				<div v-if="title" v-html="title" class="bs-text__title"></div>	
                <div v-if="description" v-html="description" class="bs-text__description"></div>
                <template v-if="blok.add_button">
                    <div class="bs-text__button">
                        <a v-if="blok.button_link.linktype === 'url'" :href="blok.button_link && blok.button_link.cached_url ? blok.button_link.cached_url : '#'" :class="blok.button_color">
                            {{ blok.button_text }}
                        </a>
                        <nuxt-link v-else :to="blok.button_link && blok.button_link.cached_url ? '/' + blok.button_link.cached_url : '#'" :class="blok.button_color">
                            {{ blok.button_text }}
                        </nuxt-link>
                    </div>
                </template>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
   .bs-text {
        padding: 160px 0px;
        @media (max-width: 768px) {
            padding: 50px 0px;
        }
        &__wrapper {
            width: 100%;
            max-width: 1100px;
            padding: 0px 10px;
            margin: 0 auto;
            text-align: center;
        }

        p {
            font-size: 24px; 
            line-height: 30px;
            font-family: "Teodor-Regular", "Times New Roman", Times, serif;
        }

        &__pre {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 22px;
            letter-spacing: 1px;
        }

        &__description {
            display: block;
            width: 100%;
            max-width: 664px;
            margin: 0 auto;
        }

        h2 {
            font-size: 48px;
            line-height: 57px;
            margin-bottom: 30px;
            @media (max-width: 768px) {
                font-size: 40px;
                line-height: 48px;
            }
        }

        h1 {
            font-size: 80px;
            line-height: 100px;
            @media (max-width: 768px) {
                font-size: 56px;
                line-height: 62px;
            }
        }

        h3 {
            font-size: 22px;
            line-height: 27px;
        }

        &__button {
            a{
                font-size: 24px;
                line-height: 24px;
                padding: 23px 70px 20px;
                border-radius: 34px;
                display: inline-block;
                color: #fff;
                font-weight: 700;
                background: #000;
                text-decoration: none;
            }
            a.orange {
                color: #fff;
                background: #FF5601;
            }
        }
   }
</style>

<script>
    export default {
        components: {
        },
        data() {
            return {}
        },
        props: ['blok'],
        methods: {
            background() {
                if (this.blok.background_image.filename) {
                    return 'background: url('+ this.blok.background_image.filename +') center center no-repeat ' + this.blok.background.color;
                } else {
                    return 'background:' + this.blok.background.color;
                }
            },
            textColor() {
                return 'color: ' + this.blok.text_color;
            }
            
        },
        mounted() {
            console.debug(this.blok);
        },
        computed: {
            title() {
                if (this.blok.title) {
                    return this.$storyapi.richTextResolver.render(this.blok.title).replace(/\|/g, "<br />")
                }
            },
            description() {
                if (this.blok.description) {
                    return this.$storyapi.richTextResolver.render(this.blok.description)
                }
            },
        }
    }
    </script>