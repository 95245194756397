var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      ref: "hero",
      staticClass: "hero",
      class: [_vm.blok.short_height && "hero--short"]
    },
    [
      _vm._l(_vm.blok.media, function(item) {
        return _c(_vm._f("dashify")(item.component), {
          key: item._uid,
          tag: "component",
          attrs: { blok: item, short: _vm.blok.short_height }
        })
      }),
      _vm._v(" "),
      _vm.blok.title ||
      _vm.blok.cta_link.cached_url ||
      _vm.blok.video_link.cached_url
        ? _c(
            "div",
            { ref: "heroOverlay", staticClass: "hero__overlay" },
            [
              _vm.blok.title &&
              !_vm.blok.cta_link.cached_url &&
              !_vm.blok.video_link.cached_url
                ? _c("p", { staticClass: "text-jumbo" }, [
                    _vm._v(_vm._s(_vm.blok.title))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.blok.title && _vm.blok.cta_link.cached_url
                ? _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: _vm.blok.cta_link.cached_url,
                        "aria-label": _vm.blok.title + ". " + _vm.blok.cta_label
                      }
                    },
                    [
                      _vm.blok.title
                        ? _c("p", { staticClass: "text-jumbo" }, [
                            _vm._v(_vm._s(_vm.blok.title))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "primary-link" }, [
                        _vm._v(_vm._s(_vm.blok.cta_label))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.blok.video_link.cached_url
                ? _c(
                    "a",
                    {
                      ref: "playButton",
                      attrs: {
                        href: _vm.blok.video_link.cached_url,
                        "aria-label": _vm.blok.title + " Play Video."
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showVideoModal($event)
                        }
                      }
                    },
                    [
                      _vm.blok.title
                        ? _c("p", { staticClass: "text-jumbo" }, [
                            _vm._v(
                              _vm._s(_vm.blok.title) +
                                _vm._s(_vm.blok.iframe_title)
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "play-button",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v("Play Video")]
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }