<template>
	<div v-editable="blok" class="course">
		<picture v-if="image()">
			<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
			<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
			<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
			<img v-lazy-load :data-src="image()" :alt="blok.image.alt" />
		</picture>

		<div class="course__meta">
			<h2>{{ blok.title }}</h2>

			<h3 class="sub-title">Program Level</h3>
			<p>{{ blok.program_level }}</p>

			<h3 class="sub-title">Course Credits</h3>
			<p>{{ blok.course_credits }}</p>

			<a v-if="blok.cta_link && blok.cta_link.cached_url" :href="blok.cta_link.cached_url" class="primary-button" target="_blank" rel="noopener">{{ blok.cta_text }}</a>

			<button v-if="description1" type="button" class="primary-link" :aria-expanded="showDetails" @click=";(showDetails = !showDetails), (aria = !aria)">{{ showDetails == false ? 'Show' : 'Hide' }} Details</button>
		</div>

		<div v-if="description1" class="course__description" :class="showDetails == true ? 'active' : ''" :aria-hidden="aria">
			<div v-if="description1" v-html="description1"></div>

			<div v-if="description2" v-html="description2"></div>
		</div>
	</div>
</template>

<style lang="scss">
.course {
	margin: 0 auto var(--margin-bottom);
	max-width: var(--content-width);

	h2 {
		margin-block-end: 1em;
	}
	.sub-title {
		margin-block-end: 0.5em;
	}

	&__meta {
		align-items: flex-start;
		display: flex;
		flex-direction: column;

		.primary-button {
			margin-block-end: 25px;
		}

		.primary-link {
			margin-block-start: auto;
		}
	}

	&__description {
		height: 0;
		overflow: hidden;

		> div {
			opacity: 0;
			transition: opacity var(--timing) var(--easing);
		}
	}
	&__description.active {
		height: auto;

		> div {
			opacity: 1;
		}
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.course {
		--margin-bottom: 50px;

		picture {
			display: block;
			margin-block-end: 20px;
		}

		&__meta {
			margin-block-end: 25px;
		}
	}
}

/* Meidum Screen & Up */
@media all and #{$mq-m-min} {
	.course {
		--margin-bottom: 100px;

		@include grid;

		picture {
			grid-column: 1 / span 6;
		}

		&__meta {
			grid-column: 7 / span 6;
		}

		&__description {
			@include grid;
			grid-column: 1 / -1;

			> div:nth-child(1) {
				grid-column: 1 / span 5;
			}

			> div:nth-child(2) {
				grid-column: 7 / span 6;
			}
		}
		&__description.active {
			padding-block-start: 40px;
		}
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {
			aria: true,
			showDetails: false
		}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 652
				height = 400

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {},
	computed: {
		description1() {
			if (this.blok.description_1 && this.blok.description_1.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.description_1)
			}
		},
		description2() {
			if (this.blok.description_2 && this.blok.description_2.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.description_2)
			}
		}
	}
}
</script>
