<template>
	<ol v-editable="blok" class="details-list">
		<ListDetailsItem :key="item._uid" v-for="item in blok.item" :blok="item" />
	</ol>
</template>

<style lang="scss">
/* Styles defined in ListDetailsItem.vue */
</style>

<script>
export default {
	components: {
		ListDetailsItem: () => import('@/components/ListDetailsItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
