import { render, staticRenderFns } from "./PageHeaderAccount.vue?vue&type=template&id=aa98c774&"
import script from "./PageHeaderAccount.vue?vue&type=script&lang=js&"
export * from "./PageHeaderAccount.vue?vue&type=script&lang=js&"
import style0 from "./PageHeaderAccount.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/webadmin/sites/bnind.com/bnfe/releases/20240605T022834/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aa98c774')) {
      api.createRecord('aa98c774', component.options)
    } else {
      api.reload('aa98c774', component.options)
    }
    module.hot.accept("./PageHeaderAccount.vue?vue&type=template&id=aa98c774&", function () {
      api.rerender('aa98c774', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/PageHeaderAccount.vue"
export default component.exports