<template>
	<div v-editable="editable()" class="card" v-show="show">
		<template v-if="data">
			<!-- if file download -->
			<template v-if="isFile">
				<!-- regular file download link -->
				<template v-if="loggedIn || !blok.protected_file">
					<a :href="blok.file.filename" target="_blank" rel="noopener">
						<picture v-if="image()">
							<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
							<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
							<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
							<img width="500" height="500" v-lazy-load :data-src="image()" :alt="data.image.alt ? data.image.alt : 'alt'" />
						</picture>
					</a>
				</template>

				<!-- account-gated file download link -->
				<template v-else>
					<a href="#" @click.prevent="showLoginModal">
						<picture v-if="image()">
							<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
							<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
							<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
							<img width="500" height="500" v-lazy-load :data-src="image()" :alt="data.image.alt ? data.image.alt : 'alt'" />
						</picture>
					</a>
				</template>
			</template>

			<template v-else-if="data.link && data.link.cached_url">
				<!-- Download Link: When there's a link, wrap the image in it. -->
				<nuxt-link v-if="externalDownload" :to="'/' + data.link.cached_url + '/'" target="_blank" rel="noopener">
					<picture v-if="image()">
						<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
						<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
						<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
						<img width="500" height="500" v-lazy-load :data-src="image()" :alt="data.image.alt ? data.image.alt : 'alt'" />
					</picture>
				</nuxt-link>

				<!-- Default Link: When there's a link, wrap the image in it. -->
				<nuxt-link v-else :to="'/' + data.link.cached_url + '/'">
					<picture v-if="image()">
						<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
						<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
						<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
						<img width="500" height="500" v-lazy-load :data-src="image()" :alt="data.image.alt ? data.image.alt : 'alt'" />
					</picture>
				</nuxt-link>
			</template>

			<!-- No link, render only the image. -->
			<template v-else>
				<picture v-if="galleryCardIndex !== undefined && image()">
					<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
					<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
					<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
					<img width="500" height="500" v-lazy-load :data-src="image()" :alt="data.image.alt ? data.image.alt : 'alt'" @click="galleryClick" class="gallery-image" />
				</picture>

				<template v-else>
					<picture v-if="image()">
						<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
						<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
						<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
						<img width="500" height="500" v-lazy-load :data-src="image()" :alt="data.image.alt ? data.image.alt : 'alt'" />
					</picture>
				</template>
			</template>

			<h3 v-if="data.title" class="text-5">
				<template v-if="isFile">
					<!-- regular file download link -->
					<template v-if="this.loggedIn || !blok.protected_file">
						<a :href="blok.file.filename" class="icon-download" target="_blank" rel="noopener">{{ data.title }}</a>
					</template>

					<!-- account-gated file download link -->
					<template v-else>
						<a href="#" class="icon-download" @click.prevent="showLoginModal">{{ data.title }}</a>
					</template>
				</template>

				<template v-else-if="data.link && data.link.cached_url">
					<template v-if="externalDownload">
						<!-- Download Link: When there's a link, wrap the title in it. -->
						<nuxt-link :to="'/' + data.link.cached_url + '/'" class="icon-download" target="_blank" rel="noopener">{{ data.title }}</nuxt-link>
					</template>

					<template v-else>
						<!-- Default Link: When there's a link, wrap the title in it. -->
						<nuxt-link v-if="data.link && data.link.cached_url" :to="'/' + data.link.cached_url + '/'">{{ data.title }}</nuxt-link>
					</template>
				</template>

				<!-- No link, render only the text. -->
				<template v-else>
					{{ data.title }}
				</template>

				<Bookmark v-if="data.bookmark === true && data.link && data.link.cached_url" :id="data.link.cached_url" />
			</h3>

			<h4 v-if="data.sub_title" class="sub-title">
				<template v-if="isFile">
					<!-- regular file download link -->
					<template v-if="loggedIn || !blok.protected_file">
						<a :href="blok.file.filename" target="_blank" rel="noopener">{{ data.sub_title }}</a>
					</template>

					<!-- account-gated file download link -->
					<template v-else>
						<a href="#" target="_blank" @click.prevent="showLoginModal">{{ data.sub_title }}</a>
					</template>
				</template>
				<!-- No link, render only the text. -->
				<template v-else>
					{{ data.sub_title }}
				</template>
				
				<template v-if="data.sub_title_2">
					<br />{{ data.sub_title_2 }}
				</template>
			</h4>

			<p v-if="data.blurb">{{ data.blurb }}</p>

			<div v-if="description" v-html="description"></div>

			<nuxt-link v-if="data.cta_link && data.cta_link.cached_url" :to="'/' + data.cta_link.cached_url + '/'" class="primary-button">{{ data.cta_text }}</nuxt-link>
		</template>
	</div>
</template>

<style lang="scss">
.cards-block {
	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	/* When Nested in Accordion */
	.accordion__item & {
		--margin-bottom: 0;
	}
	/* When Nested in Accordion w/Download Link */
	> a {
		display: inline-block;
		margin-block-end: 20px;
	}

	header {
		display: flex;
		justify-content: space-between;
		margin-block-end: 1em;

		.section-title {
			margin-block-end: 0;
		}
		.primary-link {
			flex-shrink: 0;
			margin-block-end: 0.5em;
		}
	}

	footer {
		color: var(--gray-medium);
	}
}

.cards-grid {
	--col: 1;

	@include grid();
	grid-template-columns: repeat(var(--col), 1fr);

	&[data-size='col6'] {
		--col: 2;

		.card {
			h3 {
				--min-font: 1.25;
				--max-font: 1.5;
				padding-inline-end: 0;

				span {
					display: block;
					margin-block-end: 4px;
				}
			}
		}
	}

	&[data-size='col4-hscroll'] {
		--col: 4;

		max-width: var(--content-width);
		-ms-overflow-style: none; /* IE11: hide scrollbar */
		overflow-x: auto;
		overscroll-behavior-x: contain;
		scrollbar-width: none; /* Firefox: hide scrollbar */
		scroll-behavior: smooth;
		scroll-snap-type: x mandatory;
		-webkit-overflow-scrolling: touch;

		/* Chrome/Edge/Safari: hide scrollbar */
		&::-webkit-scrollbar {
			display: none;
		}

		.card {
			min-width: 300px;
			scroll-snap-align: start;
		}
	}
}

.card {
	margin: 0 auto 20px;
	width: 100%;

	> a:not(.primary-button) {
		display: block;
		width: 100%;
	}

	> a:first-of-type:not(.primary-button)::after {
		display: none;
	}

	a:not(.primary-button) {
		display: inline-block;
		text-decoration: none;
	}

	h3 {
		margin-block-end: 1em;
		margin-block-start: 15px;
		padding-inline-end: 44px;
		position: relative;
	}

	h4 {
		margin-block-start: -0.5em;
	}

	picture {
		display: block;
		overflow: hidden;

		& + div {
			padding-block-start: 25px;
		}
	}
	img {
		max-width: none;
		transition: transform 300ms ease-in-out;
		width: 100%;

		&.gallery-image {
			cursor: pointer;
		}
	}
}

@media all and (min-width: 568px) {
	.cards-grid {
		&[data-size='col3'] {
			--col: 2;
		}
		&[data-size='col4'] {
			--col: 2;
		}
		&[data-size='col6'] {
			--col: 3;
		}
	}
}
/* Small Screen Only */
@media all and #{$mq-s-max} {
	.cards-block {
		--margin-bottom: 0px;
	}

	.cards-block--gallery {
		--margin-bottom: 50px;
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.cards-block {
		--margin-bottom: 40px;

		header {
			grid-column: 1 / -1;
		}
	}

	.cards-block--gallery {
		--margin-bottom: 125px;
	}

	.cards-grid {
		&[data-size='col2'] {
			--col: 2;
		}
		&[data-size='col3'] {
			--col: 2;
		}
		&[data-size='col4'] {
			--col: 3;
		}
		&[data-size='col6'] {
			--col: 4;
		}
	}
}

@media all and #{$mq-l-min} {
	.cards-grid {
		&[data-size='col3'] {
			--col: 3;
		}
		&[data-size='col4'] {
			--col: 4;
		}
		&[data-size='col6'] {
			--col: 6;
		}
	}
}

@media all and (max-width: 1294px) {
	.cards-grid {
		&[data-size='col4-hscroll'] {
			margin: 0 calc(var(--content-space-side) * -1px);
			padding-inline-start: 15px;

			.card {
				scroll-margin: 0 0 0 15px;
			}
		}
	}
}

/* Hover for Non-Touch */
@media (hover: hover) and (pointer: fine) {
	.card {
		> a:hover img {
			transform: scale(1.05);
		}
	}
}
</style>

<script>
export default {
	components: {
		Bookmark: () => import('~/components/Bookmark.vue')
	},
	data() {
		return {
			data: null,
			show: true,
            loggedIn:false
		}
	},
	props: ['blok', 'uuid', 'type', 'galleryCardIndex', 'autocrop', 'columns'],
	methods: {
		showLoginModal() {
			// save intent object
			this.$intent.set('download', this.$route.path, { file: this.blok.file.filename })
			this.$bus.$emit('openModal')
		},
		galleryClick() {
			if (this.galleryCardIndex !== undefined) this.$emit('cardClick', this.galleryCardIndex)
		},
		editable() {
			if(this.blok){
				return this.blok
			}
			return this;
		},
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let ratio = 'oneone' // default ratio is 1:1 square
			let width, height

			if (this.data && this.data.image && this.data.image.filename) {
				// grab autocrop value if it exists
				if (this.autocrop && this.autocrop.length) {
					ratio = this.autocrop[0]
				}

				// if this grid has no columns, return the original image
				if (!this.columns || !this.columns.length) return this.data.image.filename

				// get the image width based on grid columns
				switch (this.columns[0]) {
					case 'col2':
						width = 652
						break
					case 'col3':
						width = 428
						break
					case 'col4':
					case 'col4-hscroll':
						width = 316
						break
					case 'col6':
						width = 204
						break
					default:
						return this.data.image.filename
				}

				// get the image height based on the image ratio and width
				switch (ratio) {
					case 'oneone': // 1:1
						height = width
						break
					case 'threetwo': // 3:2
						height = Math.round((width * 2) / 3)
						break
					case 'none': // variable height
						height = 0
						break
					default:
						return this.data.image.filename
				}

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.data.image.filename, {
					size: width + 'x' + height,
					focus: this.data.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		},
        getLoggedIn(){
            return this.$auth.loggedIn;
        }
	},
	created() {
		// see if there's a link uuid to use
		let linkId
		if (this.uuid) linkId = this.uuid
		else if (this.blok && !this.blok.is_custom && this.blok.link && this.blok.link.id) linkId = this.blok.link.id

		if (linkId) {
			// uuids are used for autogenerated cards
			let detail, linkType

			if (this.type) {
				linkType = this.type
			} else {
				if (this.blok.link.cached_url.startsWith('products/')) linkType = 'products'
				else if (this.blok.link.cached_url.startsWith('solutions/')) linkType = 'solutions'
			}

			switch (linkType) {
				case 'solutions':
					detail = this.$store.getters['solutions/getSolutionByUUID'](linkId)
					this.data = {}

					// get solution data
					if (detail && detail.content.data) {
						detail.content.data.forEach(component => {
							if (component.component === 'solution_data') {
								if (component.thumbnail && component.thumbnail.filename) this.data.image = component.thumbnail

								// use verticals as subtitle
								if (component.verticals) {
									const verticals = []
									component.verticals.forEach(vertical => {
										verticals.push(this.$store.getters['solutions/getCategoryNameByValue'](vertical))
									})
									this.data.sub_title = verticals.join(', ')
								}
							}
						})
					}
					break
				case 'products':
					detail = this.$store.getters['products/getProductByUUID'](linkId)
					this.data = {}

					// get product data
					if (detail && detail.content.data) {
						detail.content.data.forEach(component => {
							if (component.component === 'product_data') {
								this.data.blurb = component.blurb
								if (component.thumbnail && component.thumbnail.filename) this.data.image = component.thumbnail
								if(component.data_title){
									this.data.title = component.data_title;
								}
							}
						})
					}
					break
				default:
					break
			}

			if (!detail) return

			// get title from page header component
			if(detail.content.body){
				detail.content.body.forEach(component => {
					if (component.component === 'page_header') {
						this.data.title = component.title
					}
				})
			}
			
			// get url from full slug
			this.data.link = {
				cached_url: detail.full_slug
			}

			// placeholder thumbnail image
			if (!this.data.image) {
				this.data.image = {
					filename: 'https://a.storyblok.com/f/102348/316x316/b900e6733a/316x316.png',
					alt: 'placeholder image'
				}
			}

			// these are bookmarkable
			this.data.bookmark = true

			// handle filter events
			if (linkType === 'solutions') {
				// products used filter event handler
				this.$bus.$on('solutionsFilterProductsUsed', filterArray => {
					// if no filters active, show all cards
					if (!filterArray.length) {
						this.show = true
						return
					}

					const solution = this.$store.getters['solutions/getSolutionByUUID'](linkId)
					const body = solution.content.body
					let match = false

					body.forEach(item => {
						if (item.component === 'solution_details') {
							const products = item.products

							if (products) {
								products.forEach(item => {
									if (item.component === 'solution_details_product') {
										if (item.product && item.product.id) {
											if (filterArray.includes(item.product.id)) match = true
										}
									}
								})
							}
						}
					})

					this.show = match
				})
			} else if (linkType === 'products') {
				// applications filter event handler
				this.$bus.$on('productsFilterApplication', filterArray => {
					// if no filters active, show all cards
					if (!filterArray.length) {
						this.show = true
						return
					}

					const product = this.$store.getters['products/getProductByUUID'](linkId)
					const data = product.content.data
					let match = false

					if (data) {
						for (let j = 0; j < data.length; j++) {
							if (data[j].component === 'product_data') {
								const applications = data[j].applications
								for (let k = 0; k < applications.length; k++) {
									const application = this.$store.getters['products/getProductApplicationByValue'](applications[k])
									if (filterArray.includes(application.id.toString())) {
										match = true
										break
									}
								}
							}
						}
					}

					this.show = match
				})
			}
		} else if (this.blok) {
			// regular blok data for manual SB editing
			this.data = this.blok
		}

        this.$bus.$on('loginSuccess', data => {
            this.loggedIn = this.getLoggedIn();
        });
	},
	computed: {
		isFile() {
			if (this.data && this.data.file && this.data.file.filename) return true
		},
		description() {
			if (this.blok && this.blok.description && this.blok.description.content[0].content && this.blok.is_custom) {
				return this.$storyapi.richTextResolver.render(this.blok.description)
			}
		},
		externalDownload() {
			if (this.blok && this.blok.link) {
				if (this.blok.link.cached_url.includes('.pdf') || this.blok.link.cached_url.includes('.zip')) {
					return true
				}
			}
		}
	},
    mounted() {
        this.loggedIn = this.getLoggedIn();
    }
}
</script>
