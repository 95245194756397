var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "two-column-text",
      class: _vm.isFullscreen()
    },
    [
      _c("div", { staticClass: "two-column-text__wrapper" }, [
        _c(
          "div",
          {
            staticClass: "two-column-text__col1",
            style: _vm.columnOneStyles()
          },
          [
            _vm.column1Text
              ? _c("div", {
                  staticClass: "two-column-text__content",
                  attrs: {
                    "data-aos": "fade",
                    "data-aos-offset": "-150",
                    "data-aos-delay": "0",
                    "data-aos-duration": "700",
                    "data-aos-once": "true",
                    "data-aos-anchor-placement": "top-center"
                  },
                  domProps: { innerHTML: _vm._s(_vm.column1Text) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", {
              staticClass: "bg-color",
              style: _vm.columnOneStyles()
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "two-column-text__col2",
            style: _vm.columnTwoStyles()
          },
          [
            _vm.column2Text
              ? _c("div", {
                  staticClass: "two-column-text__content",
                  attrs: {
                    "data-aos": "fade",
                    "data-aos-offset": "-150",
                    "data-aos-delay": "0",
                    "data-aos-duration": "700",
                    "data-aos-once": "true",
                    "data-aos-anchor-placement": "top-center"
                  },
                  domProps: { innerHTML: _vm._s(_vm.column2Text) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", {
              staticClass: "bg-color",
              style: _vm.columnTwoStyles()
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }