var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "is-hero product-media",
      class: _vm.snapToTop()
    },
    [
      _vm.blok.Video.filename
        ? _c("div", { staticClass: "prod-video-wrapper" }, [
            _c(
              "video",
              {
                ref: "video",
                staticClass: "video-clip__video",
                attrs: {
                  loop: "true",
                  muted: "",
                  playsinline: "",
                  autoplay: _vm.blok.Autoplay
                },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  ref: "videoSrc",
                  attrs: {
                    "data-src": _vm.blok.Video.filename,
                    type: "video/mp4"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "video-text" }, [
              _c("h1", { attrs: { tabindex: "-1" } }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.blok.Text) + "\n\t\t\t\t"),
                _vm.blok.image_top
                  ? _c("img", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-src": _vm.blok.image_top.filename,
                        width: "600",
                        alt: _vm.blok.image_top.alt
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.blok.Description))]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "video-icon",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.showVideoModal(_vm.blok.link.cached_url)
                      }
                    }
                  },
                  [_vm._v("Play Video")]
                )
              ])
            ])
          ])
        : _c("div", { staticClass: "prod-image-wrapper" }, [
            _vm.blok.Image
              ? _c("img", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  attrs: {
                    "data-src": _vm.blok.Image.filename,
                    alt: _vm.blok.Image.alt
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "image-text" }, [
              _c("h2", [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.blok.Text) + "\n\t\t\t")
              ])
            ])
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "product-tabs", attrs: { id: "tabs" } },
        [_c("TabLinkBar", { attrs: { blok: _vm.blok } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }