<template>
	<div v-editable="blok"></div>
</template>

<style lang="scss">
</style>

<script>
export default {
	components: {
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
	}
}
</script>
