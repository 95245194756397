var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "page-404 center-vertically", attrs: { id: "content" } },
    [
      _c("div", { staticClass: "content-container" }, [
        _c(
          "div",
          { staticClass: "message-block" },
          [
            _c("h1", [_vm._v("404")]),
            _vm._v(" "),
            _vm.story.content
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "editable",
                        rawName: "v-editable",
                        value: _vm.editable(),
                        expression: "editable()"
                      }
                    ]
                  },
                  [_c("p", [_vm._v(_vm._s(_vm.story.content.content))])]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "NuxtLink",
              { staticClass: "secondary-button active", attrs: { to: "/" } },
              [_vm._v("Home page")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }