<template>
	<div v-editable="blok" class="bs-image">
        <picture>
            <img width="1599" height="932" class="bs-image__image" v-lazy-load :data-src="this.blok.image.filename" alt="" />
        </picture>
		<div v-if="note" v-html="note" class="bs-image__note"></div>  
	</div>
</template>

<style lang="scss">
    .bs-image {
        display: block;
        width: 100%;
        height: 100vh;
        position: relative;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: cover;
        &__note {
            width: 100%;
            max-width: 512px;
            position: absolute;
            bottom: 20px;
            right: 20px;
            font-size: 16px;
            line-height: 22px;
            padding: 20px;
            background: rgba(255,255,255,0.6);
            @media (max-width: 768px) {
                max-width: calc(100% - 40px);
                text-align: center;
            }

            p {
                font-size: 16px;
                font-family: "FranklinGothicLTPro-Bk", "Segoe UI", "Helvetica Neue", sans-serif;
                line-height: 22px;
                margin-bottom: 0;
                a {
                    color: #000;
                }
            }
        }

        picture {
            height: 100%;
            object-fit: cover;
            width: 100%;
            display: block;
            overflow: hidden;
            @media (max-width: 768px) {
                // display: none !important;
            }
            img {
                max-width: 300%;
                min-height: 100%;
                min-width: 100%;
            }
        }
    }
</style>

<script>
    export default {
        components: {
        },
        data() {
            return {}
        },
        props: ['blok'],
        methods: {
            bgimage() {
                if (this.blok.image) {
                    return 'background-image:url(' + this.blok.image.filename + ')';
                }
            }
            
        },
        mounted() {
            console.debug(this.blok);
        },
        computed: {
            note() {
                if (this.blok.note) {
                    return this.$storyapi.richTextResolver.render(this.blok.note)
                }
            }
        }
    }
    </script>