<template>
	<div v-editable="blok" class="bio-item"  data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
		<img v-if="blok.image.filename" v-lazy-load :data-src="blok.image.filename" :alt="blok.image.alt" />
		<p v-if="blok.text">{{blok.text}}</p>
	</div>
</template>

<style lang="scss">
	.bio-item {
		width: calc(50% - 30px);
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		img {
			width: 200px;
			@media (max-width: 1024px) {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 12px;
			}
		}
		p {
			max-width: calc(100% - 220px);
			@media (max-width: 1024px) {
				text-align: center;
				width: 100%;
				max-width: 100%;
			}
		}

		@media (max-width: 1024px) {
			flex-direction: column;
			text-align: center;
			width: 100%;
		}
	}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
	}
}
</script>
