var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "home"
    },
    [
      _c(
        "div",
        { staticClass: "home-slider" },
        _vm._l(_vm.blok.slides, function(slide, index) {
          return _c(
            "div",
            { key: slide.id, staticClass: "home-slider__item" },
            [
              slide.video.length
                ? _c("VideoClip", { attrs: { blok: slide.video[0] } })
                : _c("picture", [
                    _c("source", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-srcset": _vm.image(slide.image, "webp", true),
                        media: "(min-width: 1800px)",
                        type: "image/webp"
                      }
                    }),
                    _vm._v(" "),
                    _c("source", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-srcset": _vm.image(slide.image, null, true),
                        media: "(min-width: 1800px)",
                        type: "image/jpeg"
                      }
                    }),
                    _vm._v(" "),
                    _c("source", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-srcset": _vm.image(slide.image, "webp"),
                        type: "image/webp"
                      }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      staticClass: "slideimage",
                      attrs: {
                        width: "1440",
                        height: "810",
                        id: "home-slide" + index,
                        "data-src": _vm.image(slide.image),
                        alt: ""
                      }
                    })
                  ]),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "text-jumbo" },
                [
                  _vm.isVideoLink(slide.link)
                    ? [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: slide.link.cached_url,
                              tabindex: "-1"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.showVideoModal(slide.link.cached_url)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(slide.title) +
                                "\n\t\t\t\t\t\t"
                            ),
                            _vm.wordArray(slide.words)
                              ? _c(
                                  "b",
                                  { staticClass: "words" },
                                  [
                                    _vm._v("\n\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t"),
                                    _vm._l(_vm.wordArray(slide.words), function(
                                      word
                                    ) {
                                      return [
                                        _c("span", { key: word.id }, [
                                          _vm._v(_vm._s(word))
                                        ])
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "video-icon" }, [
                              _vm._v("Play Video")
                            ])
                          ]
                        )
                      ]
                    : slide.link.linktype === "url"
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href:
                              slide.link && slide.link.cached_url
                                ? slide.link.cached_url
                                : "#",
                            tabindex: "-1"
                          }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(slide.title) +
                              "\n\t\t\t\t\t"
                          ),
                          _vm.wordArray(slide.words)
                            ? _c(
                                "b",
                                { staticClass: "words" },
                                [
                                  _vm._v("\n\t\t\t\t\t\t \n\t\t\t\t\t\t"),
                                  _vm._l(_vm.wordArray(slide.words), function(
                                    word
                                  ) {
                                    return [
                                      _c("span", { key: word.id }, [
                                        _vm._v(_vm._s(word))
                                      ])
                                    ]
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    : _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to:
                              slide.link && slide.link.cached_url
                                ? slide.link.cached_url
                                : "#",
                            tabindex: "-1"
                          }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(slide.title) +
                              "\n\t\t\t\t\t"
                          ),
                          _vm.wordArray(slide.words)
                            ? _c(
                                "b",
                                { staticClass: "words" },
                                [
                                  _vm._v("\n\t\t\t\t\t\t \n\t\t\t\t\t\t"),
                                  _vm._l(_vm.wordArray(slide.words), function(
                                    word
                                  ) {
                                    return [
                                      _c("span", { key: word.id }, [
                                        _vm._v(_vm._s(word))
                                      ])
                                    ]
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                ],
                2
              ),
              _vm._v(" "),
              slide.video.length ? [_vm._m(0, true)] : _vm._e()
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "home-wrapper" }, [
        _c("div", {
          staticClass: "home-content",
          class: _vm.gsapReady ? "ready" : ""
        })
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scroll-hint" }, [
      _c("span", { staticClass: "scroll-hint__mouse" }, [_c("span")]),
      _vm._v(" "),
      _c("p", [_vm._v("Scroll Down")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }