<template>
	<picture v-editable="blok" v-if="blok.src_small || blok.src_large">
		<source v-lazy-load :data-srcset="image('large', 'webp', true)" media="(min-width: 1441px)" type="image/webp" />
		<source v-lazy-load :data-srcset="image('large', null, true)" media="(min-width: 1441px)" type="image/jpeg" />
		<source v-lazy-load :data-srcset="image('large', 'webp', false)" media="(min-width: 769px) and (max-width: 1440px)" type="image/webp" />
		<source v-lazy-load :data-srcset="image('large', null, false)" media="(min-width: 769px) and (max-width: 1440px)" type="image/jpeg" />
		<source v-lazy-load :data-srcset="image('small', 'webp', false)" media="(max-width: 768px)" type="image/webp" />
		<source v-lazy-load :data-srcset="image('small', null, false)" media="(max-width: 768px)" type="image/jpeg" />
		<source v-lazy-load :data-srcset="image('small', 'webp')" type="image/webp" />
		<img fetchpriority="high" width="768" height="400" v-lazy-load :data-src="image('small', 'webp')" :alt="blok.src_large.alt" data-not-lazy rel="preload" />
	</picture>
</template>

<style lang="scss">
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok', 'short'],
	methods: {
		image(imageSize, fileType = null, isHighResolution = false) {
			const highResolutionScale = 1.5
			let width, height, image

			if (imageSize === 'small') {
				image = this.blok.src_small
				if (this.short) {
					width = 768
					height = 400
				} else {
					width = 768
					height = 605
				}
			} else if (imageSize === 'large') {
				image = this.blok.src_large
				if (this.short) {
					width = 1440
					height = 500
				} else {
					width = 1440
					height = 960
				}
			}

			if (image) {
				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(image.filename, {
					size: width + 'x' + height,
					focus: image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {
	},
	computed: {} 
}
</script>
