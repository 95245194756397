var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "callout",
      class: [_vm.blok.add_divider && "callout--top-divider"]
    },
    [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } }),
      _vm._v(" "),
      _vm.blok.cta_secondary_link && _vm.blok.cta_secondary_link.cached_url
        ? _c(
            "nuxt-link",
            {
              staticClass: "secondary-button",
              attrs: { to: "/" + _vm.blok.cta_secondary_link.cached_url + "/" }
            },
            [_vm._v(_vm._s(_vm.blok.cta_secondary_text))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.blok.cta_primary_text == "Contact Us"
        ? _c(
            "a",
            {
              staticClass: "primary-button",
              attrs: { href: "'/' + blok.cta_primary_link.cached_url + '/'" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.contactForm($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.blok.cta_primary_text))]
          )
        : _vm.blok.cta_primary_link && _vm.blok.cta_primary_link.cached_url
        ? _c(
            "nuxt-link",
            {
              staticClass: "primary-button",
              attrs: { to: "/" + _vm.blok.cta_primary_link.cached_url + "/" }
            },
            [_vm._v(_vm._s(_vm.blok.cta_primary_text))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }