var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "blockquote",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "blockquote"
    },
    [
      _c("p", [_vm._v(_vm._s(_vm.blok.text))]),
      _vm._v(" "),
      _c("footer", [
        _c("cite", [
          _vm._v("\n\t\t\t" + _vm._s(_vm.blok.author) + " "),
          _vm.blok.profession
            ? _c("span", [_vm._v(_vm._s(_vm.blok.profession))])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }