<template>
	<p v-editable="blok" class="stat">
		<em>{{ blok.value }}</em>
		{{ blok.description }}
	</p>
</template>

<style lang="scss">
.stat {
	--min-font: 1;
	--max-font: 1.5;

	margin-block-end: 1em;

	+ .stat {
		margin-block-end: 2.5em;
	}

	em {
		--min-font: 3.75;
		--max-font: 4.5;

		color: var(--orange);
		display: block;
		font-family: 'Times New Roman', Times, serif;
		font-style: normal;
		line-height: 1;
	}
}
</style>

<script>
export default {
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
