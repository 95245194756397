<template>
	<div v-editable="blok" :class="isFullscreen()" class="two-column-text">
		<div class="two-column-text__wrapper">
            <div class="two-column-text__col1" :style="columnOneStyles()">
                <div v-if="column1Text" v-html="column1Text" class="two-column-text__content" data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center"></div>
                <span class="bg-color" :style="columnOneStyles()"></span>
            </div>

            <div class="two-column-text__col2" :style="columnTwoStyles()">
                <div v-if="column2Text" v-html="column2Text" class="two-column-text__content" data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center"></div>
                <span class="bg-color" :style="columnTwoStyles()"></span>
            </div>
        </div>
	</div>
</template>

<style lang="scss">
    .two-column-text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        max-width: 1330px;
        background: #fff;
        margin: 0 auto;
        overflow: hidden;
        &.fullscreen {
            max-width: 100%;
            min-height: 100vh;
        }

        &__wrapper {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            height: auto;
            width: 100%;
            max-width: 1330px;
            background: transparent;
            margin: 0 auto;
            &.right {
                flex-direction: row-reverse;
            }

            @media (max-width: 1024px) {
                flex-direction: column !important;
			}
        }

        h2 {
            
            font-size: 90px;
            font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
            line-height: 1.1;
            strike {
                text-decoration: none;
                vertical-align: 49px;
                font-size: 20px;
            }
            @media (max-width: 1024px) {
               font-size: 54px;
               text-align: center;
               strike {
                    vertical-align: 24px;
               }
            }
        }

        p {
            font-size: 18px;
            @media (max-width: 1024px) {
                text-align: center;
            }
        }

        &__col1 {
            width: 59%;
            padding: 100px 103px 100px 45px;
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @media (max-width: 1024px) {
                width: 100%;
                padding: 50px 45px;
            }

            .bg-color {
                display: none;

                .fullscreen & {
                    display: block;
                    position: absolute;
                    right: 100%;
                    top: 0;
                    height: 100%;
                    width: 100vw;
                    @media (max-width: 1024px) {
                        display: none;
                    }
                }
            }

        }

        &__col2 {
            width: 41%;
            padding: 100px;
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @media (max-width: 1024px) {
                width: 100%;
                padding: 50px 45px;
            }

            .bg-color {
                display: none;

                .fullscreen & {
                    display: block;
                    position: absolute;
                    left: 100%;
                    top: 0;
                    height: 100%;
                    width: 100vw;
                }
                @media (max-width: 1024px) {
                    display: none;
                }
            }
        }

    }
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
        columnOneStyles() {
			let bgColor, textColor;
			if (this.blok && this.blok.column_1_bg) {
				bgColor = 'background:' + this.blok.column_1_bg.color + ';';
			}
			if (this.blok && this.blok.column_1_text_color) {
				textColor = 'color:' + this.blok.column_1_text_color + ';';
			}

			const styles = bgColor + textColor;

			return styles;
		},
        columnTwoStyles() {
			let bgColor, textColor;
			if (this.blok && this.blok.column_2_bg) {
				bgColor = 'background:' + this.blok.column_2_bg.color + ';';
			}
			if (this.blok && this.blok.column_2_text_color) {
				textColor = 'color:' + this.blok.column_2_text_color + ';';
			}

			const styles = bgColor + textColor;

			return styles;
		},
 
        isFullscreen() {
			if(this.blok && this.blok.is_fullscreen) {
				return ' fullscreen';
			}
		}
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		column1Text() {
			if (this.blok.column_1_text) {
				return this.$storyapi.richTextResolver.render(this.blok.column_1_text)
			}
		},
        column2Text() {
			if (this.blok.column_2_text) {
				return this.$storyapi.richTextResolver.render(this.blok.column_2_text)
			}
		}
	}
}
</script>
