var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "text-with-video"
    },
    [
      _c(
        "div",
        { staticClass: "text-with-video__wrapper", class: _vm.blok.variation },
        [
          _vm.blok.video_file.filename
            ? _c(
                "div",
                {
                  staticClass: "text-with-video__video",
                  attrs: {
                    "data-aos": "fade",
                    "data-aos-offset": "-150",
                    "data-aos-delay": "0",
                    "data-aos-duration": "700",
                    "data-aos-once": "true",
                    "data-aos-anchor-placement": "top-center"
                  }
                },
                [
                  _c(
                    "video",
                    {
                      ref: "video",
                      staticClass: "text-with-video__video--video",
                      attrs: {
                        loop: "true",
                        muted: "",
                        playsinline: "",
                        autoplay: "true"
                      },
                      domProps: { muted: true }
                    },
                    [
                      _c("source", {
                        directives: [
                          { name: "lazy-load", rawName: "v-lazy-load" }
                        ],
                        ref: "videoSrc",
                        attrs: {
                          "data-src": _vm.blok.video_file.filename,
                          type: "video/mp4"
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-with-video__text",
              style: _vm.styles(),
              attrs: {
                "data-aos": "fade",
                "data-aos-offset": "-150",
                "data-aos-delay": "0",
                "data-aos-duration": "700",
                "data-aos-once": "true",
                "data-aos-anchor-placement": "top-center"
              }
            },
            [
              _vm.text
                ? _c("div", {
                    staticClass: "text-with-video__content",
                    domProps: { innerHTML: _vm._s(_vm.text) }
                  })
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }