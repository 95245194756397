var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ]
    },
    [
      _vm.isFile
        ? [
            _vm.loggedIn || !_vm.blok.protected_file
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "primary-link icon-download",
                      attrs: {
                        href: _vm.blok.file.filename,
                        target: "_blank",
                        rel: "noopener"
                      }
                    },
                    [_vm._v(_vm._s(_vm.blok.link_text))]
                  )
                ]
              : [
                  _c(
                    "a",
                    {
                      staticClass: "primary-link icon-download",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showLoginModal($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.blok.link_text))]
                  )
                ]
          ]
        : _vm.blok.external
        ? [
            _vm.blok.link && _vm.blok.link.cached_url
              ? _c(
                  "a",
                  {
                    staticClass: "primary-link",
                    class: _vm.download,
                    attrs: {
                      href: _vm.blok.link.cached_url,
                      target: "_blank",
                      rel: "noopener"
                    }
                  },
                  [_vm._v(_vm._s(_vm.blok.link_text))]
                )
              : _vm._e()
          ]
        : [
            _vm.blok.link && _vm.blok.link.cached_url
              ? _c(
                  "nuxt-link",
                  {
                    staticClass: "primary-link",
                    class: _vm.download,
                    attrs: { to: "/" + _vm.blok.link.cached_url + "/" }
                  },
                  [_vm._v(_vm._s(_vm.blok.link_text))]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }