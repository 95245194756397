var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loggedIn || !_vm.blok.protected
    ? _c(
        "main",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ],
          staticClass: "prod-content",
          class: [
            _vm.blok.overlap_content && "content-overlap",
            this.vCenterMain && "center-vertically"
          ],
          attrs: { id: "content" }
        },
        [
          _vm._l(_vm.blok.data, function(blok) {
            return _c(_vm._f("dashify")(blok.component), {
              key: blok._uid,
              tag: "component",
              attrs: { blok: blok }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.blok.hero, function(blok) {
            return _c(_vm._f("dashify")(blok.component), {
              key: blok._uid,
              tag: "component",
              attrs: { blok: blok }
            })
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-container pdp-container" },
            _vm._l(_vm.blok.body, function(blok) {
              return _c(_vm._f("dashify")(blok.component), {
                key: blok._uid,
                tag: "component",
                attrs: { blok: blok },
                on: { verticallyCenter: _vm.centerIt }
              })
            }),
            1
          )
        ],
        2
      )
    : _c(
        "main",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ],
          staticClass: "wait",
          attrs: { id: "content" }
        },
        [
          _c(
            "div",
            { staticClass: "content-container pdp-container" },
            [_c("user-login", { attrs: { blok: _vm.blok } })],
            1
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }