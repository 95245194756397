<template>
	<div class="modal-forms">
		<div class="contact-form">
			<form @submit.prevent="submit" class="bn-form" :class="this.hasErrors ? 'has-errors' : false" method="post">
				<h2>Leave us a message</h2>
				<p class="note"><em>*</em> = required</p>

				<p v-if="this.errorServer" class="error">There was a problem submitting the form, please try again.</p>

				<fieldset>
					<!-- DEV NOTE: can add .has-error class to .form-field to highlight. -->
					<div class="form-field" :class="{ 'has-error': this.errors('firstname') }">
						<label for="firstname">First Name<em>*</em></label>
						<input type="text" id="firstname" name="firstname" maxlength="48" required @invalid="invalidateForm" v-model="firstName" />
						<p v-if="errors('firstname')" v-html="errors('firstname')" class="error"></p>
					</div>

					<div class="form-field" :class="{ 'has-error': this.errors('lastname') }">
						<label for="lastname">Last Name<em>*</em></label>
						<input type="text" id="lastname" name="lastname" maxlength="48" required @invalid="invalidateForm" v-model="lastName" />
						<p v-if="errors('lastname')" v-html="errors('lastname')" class="error"></p>
					</div>

					<div class="form-field" :class="{ 'has-error': this.errors('email') }">
						<label for="email">Email Address<em>*</em></label>
						<input type="email" id="email" name="email" required @invalid="invalidateForm" v-model="email" />
						<p v-if="errors('email')" v-html="errors('email')" class="error"></p>
					</div>

					<div class="form-field">
						<label for="phone">Phone</label>
						<input type="text" id="phone" name="phone" maxlength="12" v-model="phone" />
					</div>

					<div class="form-field" :class="{ 'has-error': this.errors('companyname') }">
						<label for="companyname">Company Name<em>*</em></label>
						<input type="text" id="companyname" name="companyname" required @invalid="invalidateForm" v-model="companyName" />
						<p v-if="errors('companyname')" v-html="errors('companyname')" class="error"></p>
					</div>

					<div class="form-field" :class="{ 'has-error': this.errors('projectname') }">
						<label for="projectname">Project Name<em>*</em></label>
						<input type="text" id="projectname" name="projectname" required @invalid="invalidateForm" v-model="projectName" />
						<p v-if="errors('projectname')" v-html="errors('projectname')" class="error"></p>
					</div>

					<div class="form-field">
						<label for="city">City<em>*</em></label>
						<input type="text" id="city" name="city" required maxlength="128" v-model="city" />
						<p v-if="errors('city')" v-html="errors('city')" class="error"></p>
					</div>

					<div class="field-group" data-size="half">
						<div class="form-field">
							<label for="state">State<em>*</em></label>
							<select class="custom-select" id="state" name="state" required v-model="state">
								<option value="N/A">N/A</option>
								<option value="AK">AK</option>
								<option value="AL">AL</option>
								<option value="AR">AR</option>
								<option value="AZ">AZ</option>
								<option value="CA">CA</option>
								<option value="CO">CO</option>
								<option value="CT">CT</option>
								<option value="DC">DC</option>
								<option value="DE">DE</option>
								<option value="FL">FL</option>
								<option value="GA">GA</option>
								<option value="HI">HI</option>
								<option value="IA">IA</option>
								<option value="ID">ID</option>
								<option value="IL">IL</option>
								<option value="IN">IN</option>
								<option value="KS">KS</option>
								<option value="KY">KY</option>
								<option value="LA">LA</option>
								<option value="MA">MA</option>
								<option value="MD">MD</option>
								<option value="ME">ME</option>
								<option value="MI">MI</option>
								<option value="MN">MN</option>
								<option value="MO">MO</option>
								<option value="MS">MS</option>
								<option value="MT">MT</option>
								<option value="NC">NC</option>
								<option value="ND">ND</option>
								<option value="NE">NE</option>
								<option value="NH">NH</option>
								<option value="NJ">NJ</option>
								<option value="NM">NM</option>
								<option value="NV">NV</option>
								<option value="NY">NY</option>
								<option value="OH">OH</option>
								<option value="OK">OK</option>
								<option value="OR">OR</option>
								<option value="PA">PA</option>
								<option value="PR">PR</option>
								<option value="RI">RI</option>
								<option value="SC">SC</option>
								<option value="SD">SD</option>
								<option value="TN">TN</option>
								<option value="TX">TX</option>
								<option value="UT">UT</option>
								<option value="VA">VA</option>
								<option value="VT">VT</option>
								<option value="WA">WA</option>
								<option value="WI">WI</option>
								<option value="WV">WV</option>
								<option value="WY">WY</option>
							</select>
							<p v-if="errors('state')" v-html="errors('state')" class="error"></p>
						</div>

						<div class="form-field">
							<label for="country">Country<em>*</em></label>
							<select class="custom-select" id="country" name="country" required v-model="country">
								<option value="Afghanistan">Afghanistan</option>
								<option value="Åland Islands">Åland Islands</option>
								<option value="Albania">Albania</option>
								<option value="Algeria">Algeria</option>
								<option value="American Samoa">American Samoa</option>
								<option value="Andorra">Andorra</option>
								<option value="Angola">Angola</option>
								<option value="Anguilla">Anguilla</option>
								<option value="Antarctica">Antarctica</option>
								<option value="Antigua and Barbuda">Antigua and Barbuda</option>
								<option value="Argentina">Argentina</option>
								<option value="Armenia">Armenia</option>
								<option value="Aruba">Aruba</option>
								<option value="Australia">Australia</option>
								<option value="Austria">Austria</option>
								<option value="Azerbaijan">Azerbaijan</option>
								<option value="Bahamas">Bahamas</option>
								<option value="Bahrain">Bahrain</option>
								<option value="Bangladesh">Bangladesh</option>
								<option value="Barbados">Barbados</option>
								<option value="Belarus">Belarus</option>
								<option value="Belgium">Belgium</option>
								<option value="Belize">Belize</option>
								<option value="Benin">Benin</option>
								<option value="Bermuda">Bermuda</option>
								<option value="Bhutan">Bhutan</option>
								<option value="Bolivia">Bolivia</option>
								<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
								<option value="Botswana">Botswana</option>
								<option value="Bouvet Island">Bouvet Island</option>
								<option value="Brazil">Brazil</option>
								<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
								<option value="Brunei Darussalam">Brunei Darussalam</option>
								<option value="Bulgaria">Bulgaria</option>
								<option value="Burkina Faso">Burkina Faso</option>
								<option value="Burundi">Burundi</option>
								<option value="Cambodia">Cambodia</option>
								<option value="Cameroon">Cameroon</option>
								<option value="Canada">Canada</option>
								<option value="Cape Verde">Cape Verde</option>
								<option value="Cayman Islands">Cayman Islands</option>
								<option value="Central African Republic">Central African Republic</option>
								<option value="Chad">Chad</option>
								<option value="Chile">Chile</option>
								<option value="China">China</option>
								<option value="Christmas Island">Christmas Island</option>
								<option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
								<option value="Colombia">Colombia</option>
								<option value="Comoros">Comoros</option>
								<option value="Congo">Congo</option>
								<option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
								<option value="Cook Islands">Cook Islands</option>
								<option value="Costa Rica">Costa Rica</option>
								<option value="Cote D'ivoire">Cote D'ivoire</option>
								<option value="Croatia">Croatia</option>
								<option value="Cuba">Cuba</option>
								<option value="Cyprus">Cyprus</option>
								<option value="Czech Republic">Czech Republic</option>
								<option value="Denmark">Denmark</option>
								<option value="Djibouti">Djibouti</option>
								<option value="Dominica">Dominica</option>
								<option value="Dominican Republic">Dominican Republic</option>
								<option value="Ecuador">Ecuador</option>
								<option value="Egypt">Egypt</option>
								<option value="El Salvador">El Salvador</option>
								<option value="Equatorial Guinea">Equatorial Guinea</option>
								<option value="Eritrea">Eritrea</option>
								<option value="Estonia">Estonia</option>
								<option value="Ethiopia">Ethiopia</option>
								<option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
								<option value="Faroe Islands">Faroe Islands</option>
								<option value="Fiji">Fiji</option>
								<option value="Finland">Finland</option>
								<option value="France">France</option>
								<option value="French Guiana">French Guiana</option>
								<option value="French Polynesia">French Polynesia</option>
								<option value="French Southern Territories">French Southern Territories</option>
								<option value="Gabon">Gabon</option>
								<option value="Gambia">Gambia</option>
								<option value="Georgia">Georgia</option>
								<option value="Germany">Germany</option>
								<option value="Ghana">Ghana</option>
								<option value="Gibraltar">Gibraltar</option>
								<option value="Greece">Greece</option>
								<option value="Greenland">Greenland</option>
								<option value="Grenada">Grenada</option>
								<option value="Guadeloupe">Guadeloupe</option>
								<option value="Guam">Guam</option>
								<option value="Guatemala">Guatemala</option>
								<option value="Guernsey">Guernsey</option>
								<option value="Guinea">Guinea</option>
								<option value="Guinea-bissau">Guinea-bissau</option>
								<option value="Guyana">Guyana</option>
								<option value="Haiti">Haiti</option>
								<option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
								<option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
								<option value="Honduras">Honduras</option>
								<option value="Hong Kong">Hong Kong</option>
								<option value="Hungary">Hungary</option>
								<option value="Iceland">Iceland</option>
								<option value="India">India</option>
								<option value="Indonesia">Indonesia</option>
								<option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
								<option value="Iraq">Iraq</option>
								<option value="Ireland">Ireland</option>
								<option value="Isle of Man">Isle of Man</option>
								<option value="Israel">Israel</option>
								<option value="Italy">Italy</option>
								<option value="Jamaica">Jamaica</option>
								<option value="Japan">Japan</option>
								<option value="Jersey">Jersey</option>
								<option value="Jordan">Jordan</option>
								<option value="Kazakhstan">Kazakhstan</option>
								<option value="Kenya">Kenya</option>
								<option value="Kiribati">Kiribati</option>
								<option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
								<option value="Korea, Republic of">Korea, Republic of</option>
								<option value="Kuwait">Kuwait</option>
								<option value="Kyrgyzstan">Kyrgyzstan</option>
								<option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
								<option value="Latvia">Latvia</option>
								<option value="Lebanon">Lebanon</option>
								<option value="Lesotho">Lesotho</option>
								<option value="Liberia">Liberia</option>
								<option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
								<option value="Liechtenstein">Liechtenstein</option>
								<option value="Lithuania">Lithuania</option>
								<option value="Luxembourg">Luxembourg</option>
								<option value="Macao">Macao</option>
								<option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
								<option value="Madagascar">Madagascar</option>
								<option value="Malawi">Malawi</option>
								<option value="Malaysia">Malaysia</option>
								<option value="Maldives">Maldives</option>
								<option value="Mali">Mali</option>
								<option value="Malta">Malta</option>
								<option value="Marshall Islands">Marshall Islands</option>
								<option value="Martinique">Martinique</option>
								<option value="Mauritania">Mauritania</option>
								<option value="Mauritius">Mauritius</option>
								<option value="Mayotte">Mayotte</option>
								<option value="Mexico">Mexico</option>
								<option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
								<option value="Moldova, Republic of">Moldova, Republic of</option>
								<option value="Monaco">Monaco</option>
								<option value="Mongolia">Mongolia</option>
								<option value="Montenegro">Montenegro</option>
								<option value="Montserrat">Montserrat</option>
								<option value="Morocco">Morocco</option>
								<option value="Mozambique">Mozambique</option>
								<option value="Myanmar">Myanmar</option>
								<option value="Namibia">Namibia</option>
								<option value="Nauru">Nauru</option>
								<option value="Nepal">Nepal</option>
								<option value="Netherlands">Netherlands</option>
								<option value="Netherlands Antilles">Netherlands Antilles</option>
								<option value="New Caledonia">New Caledonia</option>
								<option value="New Zealand">New Zealand</option>
								<option value="Nicaragua">Nicaragua</option>
								<option value="Niger">Niger</option>
								<option value="Nigeria">Nigeria</option>
								<option value="Niue">Niue</option>
								<option value="Norfolk Island">Norfolk Island</option>
								<option value="Northern Mariana Islands">Northern Mariana Islands</option>
								<option value="Norway">Norway</option>
								<option value="Oman">Oman</option>
								<option value="Pakistan">Pakistan</option>
								<option value="Palau">Palau</option>
								<option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
								<option value="Panama">Panama</option>
								<option value="Papua New Guinea">Papua New Guinea</option>
								<option value="Paraguay">Paraguay</option>
								<option value="Peru">Peru</option>
								<option value="Philippines">Philippines</option>
								<option value="Pitcairn">Pitcairn</option>
								<option value="Poland">Poland</option>
								<option value="Portugal">Portugal</option>
								<option value="Puerto Rico">Puerto Rico</option>
								<option value="Qatar">Qatar</option>
								<option value="Reunion">Reunion</option>
								<option value="Romania">Romania</option>
								<option value="Russian Federation">Russian Federation</option>
								<option value="Rwanda">Rwanda</option>
								<option value="Saint Helena">Saint Helena</option>
								<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
								<option value="Saint Lucia">Saint Lucia</option>
								<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
								<option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
								<option value="Samoa">Samoa</option>
								<option value="San Marino">San Marino</option>
								<option value="Sao Tome and Principe">Sao Tome and Principe</option>
								<option value="Saudi Arabia">Saudi Arabia</option>
								<option value="Senegal">Senegal</option>
								<option value="Serbia">Serbia</option>
								<option value="Seychelles">Seychelles</option>
								<option value="Sierra Leone">Sierra Leone</option>
								<option value="Singapore">Singapore</option>
								<option value="Slovakia">Slovakia</option>
								<option value="Slovenia">Slovenia</option>
								<option value="Solomon Islands">Solomon Islands</option>
								<option value="Somalia">Somalia</option>
								<option value="South Africa">South Africa</option>
								<option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
								<option value="Spain">Spain</option>
								<option value="Sri Lanka">Sri Lanka</option>
								<option value="Sudan">Sudan</option>
								<option value="Suriname">Suriname</option>
								<option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
								<option value="Swaziland">Swaziland</option>
								<option value="Sweden">Sweden</option>
								<option value="Switzerland">Switzerland</option>
								<option value="Syrian Arab Republic">Syrian Arab Republic</option>
								<option value="Taiwan">Taiwan</option>
								<option value="Tajikistan">Tajikistan</option>
								<option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
								<option value="Thailand">Thailand</option>
								<option value="Timor-leste">Timor-leste</option>
								<option value="Togo">Togo</option>
								<option value="Tokelau">Tokelau</option>
								<option value="Tonga">Tonga</option>
								<option value="Trinidad and Tobago">Trinidad and Tobago</option>
								<option value="Tunisia">Tunisia</option>
								<option value="Turkey">Turkey</option>
								<option value="Turkmenistan">Turkmenistan</option>
								<option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
								<option value="Tuvalu">Tuvalu</option>
								<option value="Uganda">Uganda</option>
								<option value="Ukraine">Ukraine</option>
								<option value="United Arab Emirates">United Arab Emirates</option>
								<option value="United Kingdom">United Kingdom</option>
								<option value="United States">United States</option>
								<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
								<option value="Uruguay">Uruguay</option>
								<option value="Uzbekistan">Uzbekistan</option>
								<option value="Vanuatu">Vanuatu</option>
								<option value="Venezuela">Venezuela</option>
								<option value="Viet Nam">Viet Nam</option>
								<option value="Virgin Islands, British">Virgin Islands, British</option>
								<option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
								<option value="Wallis and Futuna">Wallis and Futuna</option>
								<option value="Western Sahara">Western Sahara</option>
								<option value="Yemen">Yemen</option>
								<option value="Zambia">Zambia</option>
								<option value="Zimbabwe">Zimbabwe</option>
							</select>
							<p v-if="errors('country')" v-html="errors('country')" class="error"></p>
						</div>
					</div>

					<div class="form-field">
						<label for="industry">Industry<em>*</em></label>
						<select class="custom-select" id="industry" name="industry" required v-model="industry">
							<option value="Commercial">Commercial</option>
							<option value="Healthcare">Healthcare</option>
							<option value="Offices/Workspaces">Offices/Workspaces</option>
							<option value="Retail">Retail</option>
							<option value="Residential">Residential</option>
							<option value="Multidiscipline">Multidiscipline</option>
						</select>
						<p v-if="errors('industry')" v-html="errors('industry')" class="error"></p>
					</div>

					<div class="form-field" :class="{ 'has-error': this.errors('message') }" data-size="full">
						<label for="message">Your Message<em>*</em></label>
						<textarea id="message" name="message" maxlength="6000" required @invalid="invalidateForm" v-model="message"></textarea>
						<p v-if="errors('message')" v-html="errors('message')" class="error"></p>
					</div>
				</fieldset>
				<input type="hidden" name="current_page" v-model="pathName" />
				<!-- data-callback='onSubmit' -->
				<button type="submit" class="primary-button" :disabled="this.loading">Submit</button>
			</form>
		</div>
	</div>
</template>

<style lang="scss">
.contact-form {
	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);
	.field-group, .form-field {
		width: min(428px, 100%);
	}
	.g-recaptcha {
		margin-block-end: 45px;
	}
}

@media all and #{$mq-m-min} {
	.contact-form {
		fieldset {
			@include grid();
			row-gap: 20px;
			> div:nth-child(odd) {
				grid-column: 1 / span 6;
			}
			> div:nth-child(even) {
				grid-column: 7 / span 6;
			}
			> .form-field[data-size='full'] {
				width: min(1100px, 100%);
			}
		}
	}
}

@media all and #{$mq-l-min} {
}
</style>

<script>
export default {
	async mounted() {
		try {
			await this.$recaptcha.init()
		} catch (e) {
			console.log(e)
		}
	},
	components: {},
	data() {
		return {
			hasErrors: false,
			errorServer: false,
			errorMessages: null,
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			projectName: '',
			companyName: '',
			city: '',
			state: '',
			country: '',
			message: '',
			industry: '',
            loading:false
		}
	},
	props: ['blok'],
	computed: {
		formAction() {
			return process.env.contactFormAction
		},
		pathName() {
			return window.location.pathname
		}
	},
	created() {
		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},
	methods: {
		closeModal() {
			this.$bus.$emit('closeModal')
		},
		invalidateForm() {
			this.hasErrors = true
		},
		errors(fieldId) {
			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		submitValidatedForm(token) {
			console.log('recaptcha token: ' + token)
			console.log('this.formAction: ' + this.formAction)
			this.$axios.post(this.formAction, {
				token: token, // reCAPTCHA
				firstname: this.firstName,
				lastname: this.lastName,
				email: this.email,
				phone: this.phone,
				projectname: this.projectName,
				companyname: this.companyName,
				city: this.city,
				state: this.state,
				country: this.country,
				message: this.message,
				industry: this.industry,
				current_page: this.pathName
			}, {debug: false})
			.then(data => {
				this.loading = false;
				if (data.status == 201) {
					this.closeModal()
					this.$router.push('/about-b-n/thank-you')
				}
			})
			.catch(error => {
				this.loading = false;
				// do nothing if there is no error object
				if (!error) return

				// use server-side validation messages if they exist
				// otherwise use a generic error message
				if (error.response && error.response.data && error.response.data.errors) {
					this.errorMessages = error.response.data.errors
				} else {
					this.errorServer = true
				}
			})
		},
		submit() {
			console.log('submit method')
            this.loading = true;
			// clear existing errors
			this.errorMessages = null

			// if there are frontend errors, don't submit the form
			if (this.errorMessages !== null){
                this.loading = false;
                return;
            }

			//this.submitValidatedForm('test');
			//console.log('starting ready')
			let cb = this.submitValidatedForm
			grecaptcha.ready(function() {
				//console.log('grecaptcha.ready')
				grecaptcha.execute('6LfhUh0hAAAAABnqrdpdMIrEcR_RiZwEikb_i3WF', { action: 'submit' }).then(cb)
			})
		}
	},
	mounted() {
		const recaptchaScript = document.createElement('script');
		recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LfhUh0hAAAAABnqrdpdMIrEcR_RiZwEikb_i3WF');
		document.head.appendChild(recaptchaScript);
	}
}
</script>
