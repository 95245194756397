var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "text-media"
    },
    [
      _c(
        "div",
        {
          staticClass: "text-media__content",
          class: [_vm.blok.align_content_bottom && "u--align-bottom"]
        },
        [
          _vm._l(_vm.blok.stat, function(item) {
            return _c(_vm._f("dashify")(item.component), {
              key: item._uid,
              tag: "Stat",
              attrs: { blok: item }
            })
          }),
          _vm._v(" "),
          _vm.blok.title
            ? _c("h2", [_vm._v(_vm._s(_vm.blok.title))])
            : _vm._e(),
          _vm._v(" "),
          _vm.blok.description && !_vm.rteDescription
            ? _c("p", [_vm._v(_vm._s(_vm.blok.description))])
            : _vm._e(),
          _vm._v(" "),
          _vm.rteDescription && !_vm.blok.description
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.rteDescription) } })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.image()
        ? _c("picture", [
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                "data-srcset": _vm.image("webp", true),
                media:
                  "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                type: "image/webp"
              }
            }),
            _vm._v(" "),
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                "data-srcset": _vm.image(null, true),
                media:
                  "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                type: "image/jpeg"
              }
            }),
            _vm._v(" "),
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: { "data-srcset": _vm.image("webp"), type: "image/webp" }
            }),
            _vm._v(" "),
            _c("img", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: { "data-src": _vm.image(), alt: _vm.blok.image.alt }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }