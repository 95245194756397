<template>
	<div v-editable="blok" class="hero" :class="[blok.short_height && 'hero--short']" ref="hero">
		<!-- Image or Video Clip -->
		<component :key="item._uid" v-for="item in blok.media" :blok="item" :short="blok.short_height" :is="item.component | dashify"></component>

		<div v-if="blok.title || blok.cta_link.cached_url || blok.video_link.cached_url" class="hero__overlay" ref="heroOverlay">
			<!-- Title Only -->
			<p v-if="blok.title && !blok.cta_link.cached_url && !blok.video_link.cached_url" class="text-jumbo">{{ blok.title }}</p>

			<!-- Text Link -->
			<nuxt-link v-if="blok.title && blok.cta_link.cached_url" :to="blok.cta_link.cached_url" :aria-label="blok.title + '. ' + blok.cta_label">
				<p v-if="blok.title" class="text-jumbo">{{ blok.title }}</p>

				<div class="primary-link">{{ blok.cta_label }}</div>
			</nuxt-link>

			<!-- Video Link -->
			<a v-if="blok.video_link.cached_url" :href="blok.video_link.cached_url" ref="playButton" :aria-label="blok.title + ' Play Video.'" @click.prevent="showVideoModal">
				<p v-if="blok.title" class="text-jumbo">{{ blok.title }}{{ blok.iframe_title }}</p>
				<div class="play-button" aria-hidden="true">Play Video</div>
			</a>
		</div>
	</div>
</template>

<style lang="scss">
.hero {
	background-color: var(--black);
	overflow: hidden;
	position: relative;

	main > & {
		margin: 0 -10px;
	}

	picture {
		display: block;
		max-height: var(--height);
	}

	img {
		height: var(--height);
		object-fit: cover;
		object-position: center;
		width: 100%;
	}

	.video-clip {
		--margin-bottom: 0;

		height: var(--height);
		width: auto;
	}
}
.hero__overlay {
	align-items: center;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	left: 0;
	padding: 25px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	z-index: 1;

	a {
		color: var(--white);
		text-decoration: none;
	}

	.text-jumbo {
		color: var(--white);
		text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
		max-width: 800px;
	}

	.primary-link {
		text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
	}
}

.content-overlap {
	.hero__overlay {
		bottom: calc(var(--content-overlap) * -1);
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.hero {
		--height: 605px;

		display: flex;
		flex-direction: column;
	}

	.hero--short {
		--height: 275px;
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.hero {
		--height: 960px;
	}

	.hero--short {
		--height: 500px;
	}
}

@media all and #{$mq-m-min} and (max-height: 1040px) {
	.hero:not(.hero--short) {
		--height: calc(100vh - var(--header-height));
	}
}
</style>

<script>
export default {
	components: {
		Picture: () => import('~/components/Picture.vue'),
		VideoClip: () => import('@/components/VideoClip.vue')
	},
	data() {
		return {
			showVideo: false
		}
	},
	mixins: [],
	props: ['blok'],
	computed: {},
	methods: {
		showVideoModal: function() {
			this.$bus.$emit('openVideoModal', { type: 'video', url: this.blok.video_link.cached_url })
		}
	},
	mounted() {}
}
</script>
