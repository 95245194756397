var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "text-with-image",
      class: _vm.smallerPadding()
    },
    [
      _c(
        "div",
        {
          staticClass: "text-with-image__wrapper",
          class: _vm.blok.variation,
          style: _vm.styles()
        },
        [
          _c(
            "div",
            {
              staticClass: "text-with-image__image",
              attrs: {
                "data-aos": "fade",
                "data-aos-offset": "-150",
                "data-aos-delay": "0",
                "data-aos-duration": "700",
                "data-aos-once": "true",
                "data-aos-anchor-placement": "top-center"
              }
            },
            [
              _c("picture", [
                _c("source", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  attrs: {
                    "data-srcset": _vm.image("webp", true),
                    media:
                      "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                    type: "image/webp"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  attrs: {
                    "data-srcset": _vm.image(null, true),
                    media:
                      "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)",
                    type: "image/jpeg"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  attrs: {
                    "data-srcset": _vm.image("webp"),
                    type: "image/webp"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  attrs: {
                    "data-src": _vm.image(),
                    width: "720",
                    height: "680",
                    alt: _vm.blok.image.alt
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-with-image__text",
              attrs: {
                "data-aos": "fade",
                "data-aos-offset": "-150",
                "data-aos-delay": "0",
                "data-aos-duration": "700",
                "data-aos-once": "true",
                "data-aos-anchor-placement": "top-center"
              }
            },
            [
              _vm.text
                ? _c("div", {
                    staticClass: "text-with-image__content",
                    domProps: { innerHTML: _vm._s(_vm.text) }
                  })
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }