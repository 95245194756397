<template>
	<div class="icon-list-container">
		<h2 v-if="blok.title" class="section-title">{{ blok.title }}</h2>

		<ul v-editable="blok" class="icon-list">
			<ListIconItem :key="item._uid" v-for="item in blok.item" :blok="item" />
		</ul>
	</div>
</template>

<style lang="scss">
/* Styles defined in ListIconItem.vue */
</style>

<script>
export default {
	components: {
		ListIconItem: () => import('@/components/ListIconItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
