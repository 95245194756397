<template>
	<div v-editable="blok" class="bs-text-on-image" :style="bgimage()">
        <div class="bs-text-on-image__wrapper" data-aos="fade" data-aos-offset="-100" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="bottom-bottom">
            <div class="bs-text-on-image__pre" v-if="blok.category" v-html="blok.category"></div>
            <h2 class="bs-text-on-image__title" v-if="blok.title" v-html="blok.title"></h2>
            <div v-if="description" v-html="description" class="bs-text-on-image__description"></div>
            <div v-if="note" v-html="note" class="bs-text-on-image__note" :class="blok.note_position"></div> 
        </div> 
	</div>
</template>

<style lang="scss">
    .bs-text-on-image {
        display: block;
        width: 100%;
        height: 100vh;
        position: relative;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        &__wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            color: #fff;
            background: rgba(0,0,0,0.5);
        }
        &__pre {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 20px;
            letter-spacing: 1px;
        }

        &__description {
            display: block;
            width: 100%;
            max-width: 670px;
            margin: 0 auto;
            p {
                font-family: "Teodor-Regular", "Times New Roman", Times, serif;
            }
            @media (max-width: 768px) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        

        h2 {
            font-size: 48px;
            line-height: 57px;
            margin-bottom: 26px;
            display: block;
            width: 100%;
            max-width: 610px;
            @media (max-width: 768px) {
                font-size: 40px;
                line-height: 48px;
            }
        }

        h1 {
            font-size: 80px;
            line-height: 100px;
        }

        h3 {
            font-size: 22px;
            line-height: 27px;
        }


        p {
            font-size: 24px; 
            line-height: 30px;
            font-family: "Teodor-Regular", "Times New Roman", Times, serif;
        }

        &__note {
            width: 100%;
            max-width: 510px;
            position: absolute;
            bottom: 40px;
            right: 40px;
            font-size: 16px;
            line-height: 22px;
            padding: 0px;
            text-align: left;
            &.top-left {
                bottom: auto;
                right: auto;
                top: 40px;
                left: 40px;
            }
            @media (max-width: 768px) {
                transform: none;
                position: static;
                max-width: 630px;
                padding-left: 20px;
                padding-right: 20px;
                text-align: center;
            }

            p {
                font-size: 16px;
                font-family: "FranklinGothicLTPro-Bk", "Segoe UI", "Helvetica Neue", sans-serif;
                line-height: 22px;
                margin-bottom: 0;
                a {
                    color: #fff;
                }
            }
        }

        
    }
</style>

<script>
    export default {
        components: {
        },
        data() {
            return {}
        },
        props: ['blok'],
        methods: {
            bgimage() {
                if (this.blok.image) {
                    return 'background-image:url(' + this.blok.image.filename + ')';
                }
            }
            
        },
        mounted() {
            console.debug(this.blok);
        },
        computed: {
            description() {
                if (this.blok.description) {
                    return this.$storyapi.richTextResolver.render(this.blok.description)
                }
            },
            note() {
                if (this.blok.note) {
                    return this.$storyapi.richTextResolver.render(this.blok.note)
                }
            }
        }
    }
    </script>