var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "pdp-video"
    },
    [
      _c(
        "div",
        { staticClass: "pdp-video__wrapper", class: _vm.blok.variation },
        [
          _vm.blok.video_file.filename
            ? _c("div", { staticClass: "pdp-video__video" }, [
                _c(
                  "video",
                  {
                    ref: "video",
                    staticClass: "pdp-video__video--video",
                    attrs: {
                      loop: "true",
                      muted: "",
                      playsinline: "",
                      autoplay: "true"
                    },
                    domProps: { muted: true }
                  },
                  [
                    _c("source", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      ref: "videoSrc",
                      attrs: {
                        "data-src": _vm.blok.video_file.filename,
                        type: "video/mp4"
                      }
                    })
                  ]
                )
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }