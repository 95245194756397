<template>
	<div v-editable="blok" class="accordion__item">
		<h3 class="text-2 accordion__title" :class="[showContent == true ? 'accordion__item--active' : '']">
			<button :aria-expanded="showContent" @click=";(showContent = !showContent), (aria = !aria)">{{ blok.category }}</button>
		</h3>

		<component :key="blok._uid" v-for="blok in blok.content" :blok="blok" :inAccordion="true" :is="blok.component | dashify" :aria-hidden="aria"></component>
	</div>
</template>

<style lang="scss">
.accordion {
	&__item {
		/* Title */
		> h3 {
			//cursor: pointer;
		}

		&:not(:first-of-type) {
			h3 {
				border-top: 1px solid var(--gray-lite);
			}
		}

		/* Button */
		button {
			//align-items: center;
			display: flex;
			font-size: inherit;
			padding: 1.4em 0 1.1em;
			position: relative;
			text-align: left;
			width: 100%;

			/* State : Active */
			&:active {
				color: inherit;
			}

			/* Icon */
			&::before {
				background: url('/img/ui/arrow-down.svg') center center no-repeat;
				content: '';
				display: inline-block;
				height: 24px;
				margin: 0.2em 20px 0 0;
				transform: rotate(0deg);
				transition: transform var(--timing) var(--easing);
				width: 24px;
			}
		}

		/* Actions List */
		.actions-list-container {
			--margin-bottom: 0;
		}
	}

	/* Hide Item Content */
	&__title ~ * {
		height: 0;
		overflow: hidden;
		opacity: 0;
		transition: opacity var(--timing) var(--easing);
	}

	/* State : Active */
	&__item--active {
		//* Button : Arrow
		button::before {
			transform: rotate(-180deg);
		}

		/* Content */
		~ * {
			height: auto;
			opacity: 1;
			overflow: visible;
			padding-block-end: 25px;
		}
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
}
</style>

<script>
export default {
	components: {
		TextSectioned: () => import('~/components/TextSectioned.vue')
	},
	data() {
		return {
			aria: true,
			showContent: false
		}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
