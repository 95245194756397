<template>
	<div v-editable="blok" class="section-intro">
		<div>
			<h2 v-if="blok.section_title" class="section-title">{{ blok.section_title }}</h2>

			<h2 v-if="blok.title" class="text-1">{{ blok.title }}</h2>

			<div v-if="description" v-html="description"></div>
		</div>
	</div>
</template>

<style lang="scss">
.section-intro {
	margin: 0 auto 40px;
	width: min(var(--content-width), 100%);
}

@media all and #{$mq-s-max} {
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.section-intro {
		@include grid();

		> div:first-of-type {
			grid-column: 1 / span 6;
		}
	}
}

@media all and #{$mq-l-min} {
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		description() {
			if (this.blok.description) {
				return this.$storyapi.richTextResolver.render(this.blok.description)
			}
		}
	}
}
</script>
