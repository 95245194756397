<template>
	<div class="contactButton">
		<a href="/about-b-n/contact-us/" class="primary-button" @click.stop.prevent="contactForm">{{ contact_text }}</a>
	</div>
</template>

<style lang="scss">
.contactButton {
	padding: 10px 0 10px;
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['contact_text'],
	methods: {
		contactForm: function() {
			this.showNav = false
			this.$bus.$emit('openContactModal')
		}
	},
	mounted() {},
	computed: {
		description() {
			if (this.blok.text && this.blok.text.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		}
	}
}
</script>
