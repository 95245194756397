<template>
	<div v-editable="editable()" class="cards-block" :class="[!cardData && blok.is_gallery && 'cards-block--gallery']" :data-class="customClass()">
		<template v-if="cardData && cardData.uuids && cardData.uuids.length">
			<header v-if="cardData.section_title"> 
				<h2 class="section-title">{{ cardData.section_title }}</h2>
			</header>

			<div class="cards-grid" :data-size="cardData.dataSize">
				<CardsItem v-for="uuid in cardData.uuids" :uuid="uuid" :type="cardData.type" :autocrop="cardData.autocrop" :columns="cardData.columns" :key="uuid" />
			</div>
		</template>

		<template v-if="blok">
			<header v-if="blok.section_title || (blok.cta_text && blok.cta_link.cached_url)">
				<h2 v-if="this.inAccordion == undefined && blok.section_title" class="section-title">{{ blok.section_title }}</h2>
				<h4 v-if="this.inAccordion == true && blok.section_title" class="text-3">{{ blok.section_title }}</h4>

				<nuxt-link v-if="blok.cta_text && blok.cta_link.cached_url" :to="'/' + blok.cta_link.cached_url + '/'" class="primary-link">{{ blok.cta_text }}</nuxt-link>
			</header>
			<template v-else>
				<nuxt-link v-if="blok.cta_text && blok.cta_link.cached_url" :to="'/' + blok.cta_link.cached_url + '/'" class="primary-link">{{ blok.cta_text }}</nuxt-link>
			</template>

			<client-only v-if="blok.is_gallery">
				<portal v-if="galleryCardIndex !== null" selector="body">
					<Gallery ref="gallery" :images="galleryImages" :index="galleryCardIndex" :disable-scroll="true" @close="galleryClose" background="rgba(0,0,0,1)" interfaceColor="#000" />
					<!-- <LightGallery ref="gallery" :images="galleryImages" :index="galleryCardIndex" :disable-scroll="true" @close="galleryClose" background="rgba(237, 233, 228, .9)" interfaceColor="#000" /> -->
				</portal>
			</client-only>

			<div class="cards-grid" :data-size="blok.columns" v-if="blok.item">
				<template v-if="blok.is_gallery">
					<component :key="item._uid" v-for="(item, index) in blok.item" :blok="item" :autocrop="blok.autocrop" :columns="blok.columns" :galleryCardIndex="index" :is="item.component | dashify" v-on:cardClick="cardClick"></component>
				</template>
				<template v-else>
					<component :key="item._uid" v-for="item in blok.item" :blok="item" :autocrop="blok.autocrop" :columns="blok.columns" :is="item.component | dashify"></component>
				</template>
			</div>

			<footer v-if="disclaimer" v-html="disclaimer"></footer>
		</template>
	</div>
</template>

<style lang="scss">
/* Items styles defined in CardsItem.vue */
</style>

<script>
import { Portal } from '@linusborg/vue-simple-portal'

export default {
	components: {
		CardsItem: () => import('@/components/CardsItem.vue'),
		Portal
	},
	data() {
		return {
			galleryImages: null,
			galleryCardIndex: null
		}
	},
	props: ['blok', 'inAccordion', 'cardData'],
	methods: {
		cardClick(galleryCardIndex) {
			if (this.blok.is_gallery) {
				this.galleryCardIndex = galleryCardIndex
			}
		},
		galleryClose() {
			this.galleryCardIndex = null
		},
		editable() {
			if(this.blok){
				return this.blok
			}
			return {};
		},
		customClass() {
			if(this.blok && this.blok.custom_class) {
				return this.blok.custom_class
			}
			 return '';
		}
	},
	created() {
		// initialize and popoulate gallery array
		if (this.blok && this.blok.item) {
			this.galleryImages = []
			this.blok.item.forEach(card => {
				this.galleryImages.push({
					title: card.gallery_caption ? card.gallery_caption : null,
					url: card.gallery_image && card.gallery_image.filename ? card.gallery_image.filename : card.image.filename
				})
			})
		}
	},
	computed: {
		disclaimer() {
			if (this.blok.disclaimer) {
				return this.$storyapi.richTextResolver.render(this.blok.disclaimer)
			}
		}
	}
}
</script>
