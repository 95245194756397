<template>
	<div v-editable="blok" :class="isFullscreen()" class="media-text" :style="styles()">
		<div :class="blok.variation" class="media-text__wrapper">
			<div class="media-text__image">
				<picture>
					<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
					<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
					<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
					<img v-lazy-load :data-src="image()" width="750" height="793" :alt="blok.image.alt" />
				</picture>
			</div>

			<div class="media-text__text">
				<h2 v-if="blok.title">
					{{blok.title}}<Bookmark v-if="blok.bookmark === true" :id="bookmarkId" />
				</h2>
				<div class="media-text__subtitle" v-if="subtitle" v-html="subtitle"></div>
				<div v-if="text" v-html="text" class="media-text__content"></div>
				<a v-if="blok.bottom_link.cached_url" class="media-text__text__link" :href="'/' + blok.bottom_link.cached_url" :style="styles()">{{blok.bottom_link_text}} <img v-lazy-load :data-src="arrow()" :alt="blok.bottom_link_text" width="20" height="20" /></a>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.media-text {
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
	width: 100%;
	max-width: 1330px;
	background: #fff;
	margin: 0 auto;
	overflow: hidden;
	@media (max-width: 940px) {
		max-width: calc(100% - 20px);
	}
	&.fullscreen {
		max-width: 100%;
		min-height: 100vh;
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: auto;
		width: 100%;
		padding: 100px 45px;
		max-width: 1330px;
		background: transparent;
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		&.right {
			flex-direction: row-reverse;
		}

		@media (max-width: 940px) {
			flex-direction: column !important;
			padding: 50px 20px;

		}
	}

	h2 {
		
		font-size: 48px;
		font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
		line-height: 1.1;
		margin-bottom: 25px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.bookmark-container {
			display: inline-flex;
		}

		@media (max-width: 1024px) {
			font-size: 38px;
		}

	}

	p {
		margin-bottom: 10px;
	}

	&__subtitle {
		margin-bottom: 24px;
		p {
			font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
			font-size: 22px;
			margin-bottom: 0px;
		}

		img[title="badge"] {
			position: absolute;
			left: 40%;
			margin-left: -25px;
			top: 95px;
			@media (max-width: 940px) {
				left: auto;
				margin-left: 0;
				right: 30px;
				top: 65px;
				max-width: 160px;
			}
		}
	}
	&__image {
		width: 55%;
		overflow: hidden;
		// max-height: 90vh;
		@media (max-width: 940px) {
			width: 100%;
			order: 0;
			margin-bottom: 40px;
			max-height: unset;
		}
		img {
			width: 100%;
		}
	}

	&__text {
		width: 40%;
		@media (max-width: 940px) {
			width: 100%;
			order: 1;
		}

		&__link {
			display: block;
			margin-top: 20px;
			font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
			font-size: 19px;
			margin-bottom: 20px;
			text-decoration: none;
			img {
				display: inline-block;
				vertical-align: -4px;
				width: 20px;
				height: 20px;
			}
		}
	}
}
.image-feature {
	display: flex;
	flex-direction: column;
	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	picture {
		display: inline-flex;
		margin: 0 0 10px auto;
	}

	p {
		--min-font: 1.75;
		--max-font: 3.75;

		font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
		line-height: 1.1;
		margin: 1.5em auto 1em;
		text-align: center;
		width: min(1100px, 100%);
	}

	.text-orange {
		color: var(--orange);
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.image-feature {
		--margin-bottom: 50px;

		picture {
			height: 290px;

			img {
				height: inherit;
				object-fit: cover;
				width: 100%;
			}
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.image-feature {
		--margin-bottom: 100px;
	}
}
</style>

<script>
export default {
	components: {
		Bookmark: () => import('~/components/Bookmark.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 1
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 750
				height = 793

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				// return processedImage
				return this.blok.image.filename
			}
		},
		styles() {
			let bgColor, textColor;
			if (this.blok && this.blok.background) {
				bgColor = 'background:' + this.blok.background.color + ';';
			}
			if (this.blok && this.blok.text_color) {
				textColor = 'color:' + this.blok.text_color + ';';
			}

			const styles = bgColor + textColor;

			return styles;
		},
		arrow() {
			if(this.blok && this.blok.text_color) {
				return '/img/ui/arrow-right-' + this.blok.text_color + '.svg';
			} else {
				return '/img/ui/arrow-right-black.svg';
			}
		},

		isFullscreen() {
			if(this.blok && this.blok.is_fullscreen) {
				return ' fullscreen';
			}
		}
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		text() {
			if (this.blok.text) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		},

		subtitle() {
			if (this.blok.subtitle) {
				return this.$storyapi.richTextResolver.render(this.blok.subtitle)
			}
		},

		bookmarkId() {
			return this.$route.params.pathMatch
		}
	}
}
</script>
