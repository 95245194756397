var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "instagram"
    },
    [
      _c("h2", { staticClass: "section-title" }, [_vm._v("Instagram")]),
      _vm._v(" "),
      _c("h3", { staticClass: "text-2" }, [_vm._v(_vm._s(_vm.blok.title))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "instagram__items" },
        _vm._l(_vm.instagramItems, function(item) {
          return _c("div", { key: item.id }, [
            item.link
              ? _c(
                  "a",
                  {
                    attrs: {
                      href: item.link,
                      rel: "noopener",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" }
                      ],
                      attrs: {
                        "data-src": _vm.imageSource(item),
                        alt: item.accessibilityCaption
                      }
                    })
                  ]
                )
              : _vm._e()
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }