<template>
	<div v-editable="blok" :class="isFullscreen()" class="prod-tiles" :style="styles()">
		<template v-if="this.is3Equal()">
			<div class="prod-tiles__wrapper three-equal">
				<div class="prod-tiles__title" data-aos="fade" data-aos-offset="0" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center"><h2 v-if="blok.title" >{{blok.title}}<template v-if="blok.title_2"><br />{{blok.title_2}}</template></h2></div>
				<div class="prod-tiles_scroller">
					<component :key="blok._uid" v-for="blok in blok.tiles" :blok="blok" :is="blok.component | dashify">
					</component>
				</div>
			</div>
		</template>
		<template v-else-if="this.is2Equal()">
			<div class="prod-tiles__wrapper two-equal">
				<div class="prod-tiles__title" data-aos="fade" data-aos-offset="0" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center"><h2 v-if="blok.title" >{{blok.title}}<template v-if="blok.title_2"><br />{{blok.title_2}}</template></h2></div>
				<component :key="blok._uid" v-for="blok in blok.tiles" :blok="blok" :is="blok.component | dashify">
				</component>
			</div>
		</template>
		<template v-else-if="this.isBiggerLeft()">
			<div class="prod-tiles__wrapper bigger-left">
				<div class="prod-tiles__title" data-aos="fade" data-aos-offset="0" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center"><h2 v-if="blok.title">{{blok.title}}<template v-if="blok.title_2"><br />{{blok.title_2}}</template></h2></div>
				<component 
					:key="blok._uid" 
					v-for="blok in blok.tiles" 
					:blok="blok" :is="blok.component | dashify"></component>
			</div>
		</template>
		<template v-else-if="this.isBiggerRight()">
			<div class="prod-tiles__wrapper bigger-right">  
				<div class="prod-tiles__title" data-aos="fade" data-aos-offset="0" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center"><h2 v-if="blok.title">{{blok.title}}<template v-if="blok.title_2"><br />{{blok.title_2}}</template></h2></div>
				<component 
					:key="blok._uid" 
					v-for="blok in blok.tiles" 
					:blok="blok" :is="blok.component | dashify"></component>
			</div>
		</template>
		<template v-else>
			<div class="prod-tiles__wrapper carousel">
				<div class="prod-tiles__title" data-aos="fade" data-aos-offset="0" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center"><h2 v-if="blok.title">{{blok.title}}<template v-if="blok.title_2"><br />{{blok.title_2}}</template></h2></div>
				<div class="prod-tiles__carousel--scroll">
					<div class="prod-tiles__carousel">
						<component 
							:key="blok._uid" 
							v-for="blok in blok.tiles" 
							:blok="blok" :is="blok.component | dashify"></component>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<style lang="scss">
	.prod-tiles {
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		width: 100%;
		max-width: 1330px;
		background: #fff;
		margin: 0 auto;
		overflow: hidden;
		&.fullscreen {
			max-width: 100%;
			min-height: 100vh;
		}

		&__wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			height: auto;
			width: 100%;
			padding: 80px 30px;
			max-width: 1330px;
			background: transparent;
			margin: 0 auto;
			flex-wrap: wrap;
			@media (max-width: 1024px) {
				padding: 50px 20px;
			}

			&.three-equal {
				.tile-item {
					width: 33.33%;
					padding: 0px 15px 15px;

					&__image {
						max-height: 400px;
					}
				}

				.prod-tiles_scroller {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					width: 100%;
					@media (max-width: 1024px) {
						max-width: 100%;
						overflow-x: scroll;
						overscroll-behavior-x: contain;
						scrollbar-width: none;
						scroll-behavior: smooth; 
						scroll-snap-type: x mandatory;
						-webkit-overflow-scrolling: touch;
						&::-webkit-scrollbar-track {
							background: #ededed;
							border-radius: 4px;
						}
						&::-webkit-scrollbar-thumb {
							background-color: #373737;
							border-radius: 4px;
						}
						&::-webkit-scrollbar {
							height: 4px;
							border-radius: 4px;
							display: none;
						}
						&::-webkit-scrollbar-track {
							margin: 0 var(--side-space);
						}
						.tile-item {
							min-width: 300px;
						}
					}
				}
			}

			&.bigger-left {
				@media (max-width: 1024px) {
					flex-direction: column;
				}
				.tile-item {
					width: 59%;
					padding: 0px 15px 15px;
					@media (max-width: 1024px) {
						width: 100%;
					}
					&:last-of-type {
						width: 41%;
						@media (max-width: 1024px) {
						width: 100%;
					}
					} 

					&__image {
						max-height: 440px;
					}

					&__content {
						max-width: 460px;
					}

					
				}
			}

			&.bigger-right {
				@media (max-width: 1024px) {
					flex-direction: column;
				}
				.tile-item {
					width: 41%;
					padding: 0px 15px 15px;
					@media (max-width: 1024px) {
						width: 100%;
					}
					&:last-of-type {
						width: 59%;
						@media (max-width: 1024px) {
							width: 100%;
						}
					} 

					&__image {
						max-height: 440px;
					}

					&__content {
						max-width: 460px;
					}
				}
			}

			&.carousel {
				align-items: center;
				overflow-x: visible;
				max-width: 100%;
				.prod-tiles__title {
					margin-top: auto;
					padding-left: 0 !important;
				}
			}

		}

		&.fullscreen {
			.prod-tiles__wrapper {
				// padding-top: min(5vh, 80px);
				// padding-bottom: min(5vh, 80px);
			}
		}

		&__title {
			flex-basis: 100%;
			padding: 0px 15px;
			@media (max-width: 1024px) {
				display: block;
				height: 100vh;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				flex-basis: auto;
			}
		}

		h2 {
			
			font-size: 48px;
			font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
			line-height: 1.1;
			margin-bottom: 25px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 40px;
			.bookmark-container {
				display: inline-flex;
			}

		}

		p {
			margin-bottom: 10px;
		}

		&__carousel {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			column-gap: 35px;
			.tile-item {
				width: 600px;
				@media (max-width: 768px) {
					width: 300px;
				}
			}

			&--scroll {
				margin-bottom: auto;
				scroll-behavior: smooth;
				padding-bottom: 40px;
				overflow-x: scroll;
				scroll-snap-type: x mandatory;
				scrollbar-color: #fff #ededed;
				scrollbar-width: thin;
				-webkit-overflow-scrolling: touch;

				&::-webkit-scrollbar-track {
					background: #ededed;
					border-radius: 4px;
				}
				&::-webkit-scrollbar-thumb {
					background-color: #373737;
					border-radius: 4px;
				}
				&::-webkit-scrollbar {
					height: 4px;
					border-radius: 4px;
				}
				&::-webkit-scrollbar-track {
					margin: 0 var(--side-space);
				}
			}
		}
	}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		is3Equal(){return this.blok.style === '3_equal'},
		is2Equal(){return this.blok.style === '2_equal'},
		isBiggerLeft(){return this.blok.style === 'bigger_left'},
		isBiggerRight(){return this.blok.style === 'bigger_right'},
		isCarousel(){return this.blok.style === 'carousel'},
		styles() {
			let bgColor, textColor;
			if (this.blok && this.blok.background) {
				bgColor = 'background:' + this.blok.background.color + ';';
			}
			if (this.blok && this.blok.text_color) {
				textColor = 'color:' + this.blok.text_color + ';';
			}

			const styles = bgColor + textColor;

			return styles;
		},
		isFullscreen() {
			if(this.blok && this.blok.is_fullscreen) {
				return ' fullscreen';
			}
		}
	},
	mounted() {
		console.debug(this.blok)
		console.debug(this.blok.style)
	},
	computed: {
	}
}
</script>
