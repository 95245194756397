var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "user-bookmarks"
    },
    [
      _vm.products
        ? _c("Cards", { attrs: { cardData: _vm.productsCardData } })
        : _vm._e(),
      _vm._v(" "),
      _vm.solutions
        ? _c("Cards", { attrs: { cardData: _vm.solutionsCardData } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }