<template>
	<div v-editable="blok" class="text-number">
		<div class="text-number__wrapper">
			<div v-if="blok.number" class="text-number__number"><span>{{blok.number}}</span></div>
			<div v-if="text" class="text-number__title" v-html="text"></div>
		</div>
	</div>
</template>

<style lang="scss">
	.text-number {
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		width: 100%;
		max-width: 1330px;
		background: #fff;
		margin: 0 auto;
		overflow: hidden;
		font-family: "Teodor-Regular", "Times New Roman", Times, serif;
		color: #151515;
		min-height: 100vh;

		&__wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			height: auto;
			width: 100%;
			max-width: 910px;
			padding: 170px 45px;

			@media (max-width: 1024px) {
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 40px;
			}
		}

		&__number { 
			font-size: 330px;
			letter-spacing: -3px;
			padding-right: 50px;
			margin-top: -26px;
			span {
				line-height: 300px;
				display: block;
				&:after {
					content: "";
					width: 124px;
					height: 20px;
					background: #FF5601;
					display: block;
					margin: 0 auto;
				}
			}

			@media (max-width: 1024px) {
				font-size: 220px;
				padding-right: 0;
				margin-bottom: 30px;
				span {
					line-height: 220px;
					&:after {
						width: 100px;
					}
				}
				
			}

		}

		&__title {
			@media (max-width: 1024px) {
				text-align: center;
			}	
			h2{
				font-size: 110px;
				letter-spacing: -1px;
				margin-bottom: 0;
				color: #151515;
				@media (max-width: 1024px) {
					text-align: center;
					font-size: 48px;
					display: inline-block;
					margin-right: 6px;
					line-height: 1.2;
				}
			}
		}
	}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		text() {
			if (this.blok.title) {
				return this.$storyapi.richTextResolver.render(this.blok.title)
			}
		}
	}
}
</script>
