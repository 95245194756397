<template>
	<div v-editable="blok" class="slider-block">
		<h2 v-if="blok.section_title" class="section-title">{{ blok.section_title }}</h2>
		<ol v-editable="blok" class="slider">
			<SliderItem :key="item._uid" v-for="(item, index) in blok.item" :index="index" :blok="item" />
		</ol>
	</div>
</template>

<style lang="scss">
/* Slider styles defined in SliderItem.vue */

.slider-block {
	margin: 0 auto var(--margin-bottom);

	.section-title {
		margin-inline-end: auto;
		margin-inline-start: auto;
		width: min(var(--content-width), 100%);
	}
}
</style>

<script>
export default {
	components: {
		SliderItem: () => import('@/components/SliderItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
