var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "profile-form"
    },
    [
      _c(
        "form",
        {
          staticClass: "bn-form",
          class: this.hasErrors ? "has-errors" : false,
          attrs: { method: "post" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handlePasswordChange($event)
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.token,
                expression: "token"
              }
            ],
            attrs: { type: "hidden", name: "token" },
            domProps: { value: _vm.token },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.token = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          this.errorServer
            ? _c("p", { staticClass: "error" }, [
                _vm._v(
                  "There was a problem updating your password, please try again."
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "form-field" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                attrs: {
                  type: "email",
                  id: "email",
                  name: "email",
                  required: ""
                },
                domProps: { value: _vm.email },
                on: {
                  invalid: _vm.invalidateForm,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors("email")
                ? _c("p", {
                    staticClass: "error",
                    domProps: { innerHTML: _vm._s(_vm.errors("email")) }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-field" }),
            _vm._v(" "),
            _c("div", { staticClass: "field-group" }, [
              _c(
                "div",
                {
                  staticClass: "form-field",
                  class: { "has-error": _vm.errors("password") },
                  attrs: { "data-size": "full" }
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password"
                      }
                    ],
                    attrs: {
                      type: "password",
                      id: "mypassword",
                      name: "mypassword",
                      required: ""
                    },
                    domProps: { value: _vm.password },
                    on: {
                      invalid: _vm.invalidateForm,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors("password")
                    ? _c("p", {
                        staticClass: "error",
                        domProps: { innerHTML: _vm._s(_vm.errors("password")) }
                      })
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-field",
                  class: { "has-error": _vm.errors("password_confirmation") },
                  attrs: { "data-size": "full" }
                },
                [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password_confirmation,
                        expression: "password_confirmation"
                      }
                    ],
                    attrs: {
                      type: "password",
                      id: "password_confirmation",
                      name: "password_confirmation",
                      required: ""
                    },
                    domProps: { value: _vm.password_confirmation },
                    on: {
                      invalid: _vm.invalidateForm,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password_confirmation = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors("password_confirmation")
                    ? _c("p", {
                        staticClass: "error",
                        domProps: {
                          innerHTML: _vm._s(_vm.errors("password_confirmation"))
                        }
                      })
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "primary-button", attrs: { type: "submit" } },
            [_vm._v("Change Password")]
          ),
          _vm._v(" "),
          _vm.successMessage
            ? _c("p", { staticClass: "success-message" }, [
                _vm._v("Your password has been reset. Proceed to "),
                _c("a", { attrs: { href: "/account/profile" } }, [
                  _vm._v("login")
                ]),
                _vm._v(" page.")
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "note" }, [
      _c("em", [_vm._v("*")]),
      _vm._v(" = required")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "email" } }, [
      _vm._v("Email Address"),
      _c("em", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "mypassword" } }, [
      _vm._v("New Password"),
      _c("em", [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "password_confirmation" } }, [
      _vm._v("Confirm Password"),
      _c("em", [_vm._v("*")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }