var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-link-bar" },
    _vm._l(this.tabs, function(tab) {
      return _c(
        "a",
        {
          class: _vm.isSelected(tab.link) ? "selected-tab" : false,
          attrs: { href: tab.link + "#tabs" }
        },
        [_vm._v("\n\t\t" + _vm._s(tab.label) + "\n\t")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }