<template>
	<div v-editable="blok" v-if="text" class="text-feature">
		<div v-if="text" v-html="text"></div>
	</div>
</template>

<style lang="scss">
.text-feature {
	margin: 0 auto var(--margin-bottom);
	width: min(var(--content-width), 100%);

	p {
		--min-font: 1.375;
		--max-font: 3.75;

		font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
		line-height: 1.25;
	}

	.orange-text {
		color: var(--orange);
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.text-feature {
		--margin-bottom: 50px;
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.text-feature {
		--margin-bottom: 100px;
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		text() {
			if (this.blok.text && this.blok.text.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		}
	}
}
</script>
