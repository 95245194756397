var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "solution-details"
    },
    [
      _c(
        "div",
        { staticClass: "solution-details__products" },
        [
          _c("h2", { staticClass: "section-title" }, [
            _vm._v("Product(s) in use")
          ]),
          _vm._v(" "),
          _vm._l(_vm.blok.products, function(blok) {
            return _c(_vm._f("dashify")(blok.component), {
              key: blok._uid,
              tag: "component",
              attrs: { blok: blok }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.blok.title_1
        ? _c("div", { staticClass: "solution-details__info" }, [
            _c("h2", { staticClass: "section-title" }, [_vm._v("Info")]),
            _vm._v(" "),
            _vm.blok.title_1
              ? _c("h3", { staticClass: "sub-title" }, [
                  _vm._v(_vm._s(_vm.blok.title_1))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.description_1
              ? _c("p", [_vm._v(_vm._s(_vm.blok.description_1))])
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.title_2
              ? _c("h3", { staticClass: "sub-title" }, [
                  _vm._v(_vm._s(_vm.blok.title_2))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.description_2
              ? _c("p", [_vm._v(_vm._s(_vm.blok.description_2))])
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.title_3
              ? _c("h3", { staticClass: "sub-title" }, [
                  _vm._v(_vm._s(_vm.blok.title_3))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.description_3
              ? _c("p", [_vm._v(_vm._s(_vm.blok.description_3))])
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.title_4
              ? _c("h3", { staticClass: "sub-title" }, [
                  _vm._v(_vm._s(_vm.blok.title_4))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.blok.description_4
              ? _c("p", [_vm._v(_vm._s(_vm.blok.description_4))])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }