var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "editable",
            rawName: "v-editable",
            value: _vm.blok,
            expression: "blok"
          }
        ],
        staticClass: "image-grid"
      },
      _vm._l(_vm.blok.item, function(item) {
        return _c("img", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          key: item._uid,
          attrs: { "data-src": item.image.filename, alt: item.image.alt }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }