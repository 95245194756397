var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "accordion"
    },
    [
      _c("h2", { staticClass: "section-title" }, [
        _vm._v(_vm._s(_vm.blok.section_title))
      ]),
      _vm._v(" "),
      _vm._l(_vm.blok.item, function(item) {
        return _c("AccordionItem", { key: item._uid, attrs: { blok: item } })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }