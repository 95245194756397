var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "product-details"
    },
    [
      _vm._l(_vm.blok.item, function(blok) {
        return _c(_vm._f("dashify")(blok.component), {
          key: blok._uid,
          tag: "component",
          attrs: { blok: blok }
        })
      }),
      _vm._v(" "),
      _vm.rte
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.rte) } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }