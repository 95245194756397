var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: this.activeSection, staticClass: "fp-parent" },
    [
      _vm.hasItems()
        ? [
            _vm._l(_vm.blok.items, function(blok, index) {
              return [
                _c(
                  "section",
                  {
                    class:
                      index === 0 ? "fp-child-fixed initial" : "fp-child-fixed",
                    attrs: { index: index }
                  },
                  [
                    _c(_vm._f("dashify")(blok.component), {
                      tag: "component",
                      attrs: { blok: blok }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "fp-child-static",
                  attrs: { index: index }
                })
              ]
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }