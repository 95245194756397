var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "breadcrumb"
    },
    [
      _c("li", [_c("nuxt-link", { attrs: { to: "/" } }, [_vm._v("Home")])], 1),
      _vm._v(" "),
      _vm._l(_vm.linkArray, function(link) {
        return _c(
          "li",
          { key: link._uid },
          [
            _c("nuxt-link", { attrs: { to: "/" + link.link + "/" } }, [
              _vm._v(_vm._s(link.label))
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }