var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "actions-list-container" }, [
    _vm.blok.title
      ? _c("h2", { staticClass: "section-title" }, [
          _vm._v(_vm._s(_vm.blok.title))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      {
        directives: [
          {
            name: "editable",
            rawName: "v-editable",
            value: _vm.blok,
            expression: "blok"
          }
        ],
        staticClass: "actions-list"
      },
      _vm._l(_vm.blok.item, function(item) {
        return _c("ListActionsItem", { key: item._uid, attrs: { blok: item } })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }