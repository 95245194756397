var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.text
    ? _c(
        "div",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ],
          staticClass: "text-feature"
        },
        [
          _vm.text
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }