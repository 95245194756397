var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loggedIn
    ? _c("input", {
        attrs: { type: "button", value: "Logout" },
        on: { click: _vm.handleLogout }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }