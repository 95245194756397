var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "section-intro"
    },
    [
      _c("div", [
        _vm.blok.section_title
          ? _c("h2", { staticClass: "section-title" }, [
              _vm._v(_vm._s(_vm.blok.section_title))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.blok.title
          ? _c("h2", { staticClass: "text-1" }, [
              _vm._v(_vm._s(_vm.blok.title))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.description
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }