<template>
	<li v-editable="blok">
		<div class="details-list__content">
			<h2 class="section-title">{{ blok.section_title }}</h2>

			<h3 v-if="blok.title" class="text-2">{{ blok.title }}</h3>
			<p>{{ blok.description }}</p>
		</div>

		<picture v-if="image()">
			<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
			<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
			<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
			<img v-lazy-load :data-src="image()" :alt="blok.image.alt" width="764" height="500" />
		</picture>
	</li>
</template>

<style lang="scss">
.details-list {
	--margin-bottom: 55px;

	border-block-end: 2px solid var(--gray-lite);
	margin: 0 auto var(--margin-bottom);
	max-width: var(--content-width);
	padding: 0 0 var(--padding-bottom);

	li {
		margin-block-end: 60px;
	}

	&__content {
		h3 {
			margin-block-end: 0;
		}

		p {
			margin: 1.5em 0 0 0;
		}
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.details-list {
		--padding-bottom: 0;

		li {
			display: flex;
			flex-direction: column;
		}

		picture {
			margin-block-end: 20px;
			order: -1;
		}
	}
}

/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.details-list {
		--padding-bottom: 90px;

		li {
			@include grid();

			&:nth-child(odd) {
				.details-list__content {
					grid-column: 1 / span 4;
				}

				picture {
					grid-column: 6 / span 7;
				}
			}
			&:nth-child(even) {
				> * {
					grid-row: 1;
				}

				.details-list__content {
					grid-column: 8 / span 4;
				}

				picture {
					grid-column: 1 / span 7;
				}
			}
		}

		li:not(:first-child) {
			.details-list__content {
				justify-content: flex-end;
			}
		}

		&__content {
			align-items: flex-start;
			display: flex;
			flex-direction: column;

			h2 {
				margin-block-end: auto;
			}
		}
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 764
				height = 500

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {},
	computed: {}
}
</script>
