var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.blok.src_small || _vm.blok.src_large
    ? _c(
        "picture",
        {
          directives: [
            {
              name: "editable",
              rawName: "v-editable",
              value: _vm.blok,
              expression: "blok"
            }
          ]
        },
        [
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-srcset": _vm.image("large", "webp", true),
              media: "(min-width: 1441px)",
              type: "image/webp"
            }
          }),
          _vm._v(" "),
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-srcset": _vm.image("large", null, true),
              media: "(min-width: 1441px)",
              type: "image/jpeg"
            }
          }),
          _vm._v(" "),
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-srcset": _vm.image("large", "webp", false),
              media: "(min-width: 769px) and (max-width: 1440px)",
              type: "image/webp"
            }
          }),
          _vm._v(" "),
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-srcset": _vm.image("large", null, false),
              media: "(min-width: 769px) and (max-width: 1440px)",
              type: "image/jpeg"
            }
          }),
          _vm._v(" "),
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-srcset": _vm.image("small", "webp", false),
              media: "(max-width: 768px)",
              type: "image/webp"
            }
          }),
          _vm._v(" "),
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-srcset": _vm.image("small", null, false),
              media: "(max-width: 768px)",
              type: "image/jpeg"
            }
          }),
          _vm._v(" "),
          _c("source", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              "data-srcset": _vm.image("small", "webp"),
              type: "image/webp"
            }
          }),
          _vm._v(" "),
          _c("img", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            attrs: {
              fetchpriority: "high",
              width: "768",
              height: "400",
              "data-src": _vm.image("small", "webp"),
              alt: _vm.blok.src_large.alt,
              "data-not-lazy": "",
              rel: "preload"
            }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }