<template>
	<div class="actions-list-container">
		<h2 v-if="blok.title" class="section-title">{{ blok.title }}</h2>

		<ul v-editable="blok" class="actions-list">
			<ListActionsItem :key="item._uid" v-for="item in blok.item" :blok="item" />
		</ul>
	</div>
</template>

<style lang="scss">
/* Styles defined in ListActionsItem.vue */
</style>

<script>
export default {
	components: {
		ListActionsItem: () => import('@/components/ListActionsItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
