<template>
	<div v-editable="blok" class="text-with-image" :class="smallerPadding()">
		<div :class="blok.variation" class="text-with-image__wrapper" :style="styles()">
			<div class="text-with-image__image"  data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
				<picture>
					<source v-lazy-load :data-srcset="image('webp', true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/webp" />
					<source v-lazy-load :data-srcset="image(null, true)" media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)" type="image/jpeg" />
					<source v-lazy-load :data-srcset="image('webp')" type="image/webp" />
					<img v-lazy-load :data-src="image()" width="720" height="680" :alt="blok.image.alt" />
				</picture>
			</div>

			<div class="text-with-image__text"  data-aos="fade" data-aos-offset="-150" data-aos-delay="0" data-aos-duration="700" data-aos-once="true" data-aos-anchor-placement="top-center">
				<div v-if="text" v-html="text" class="text-with-image__content"></div>	
			</div>
		</div>
	</div>
</template>

<style lang="scss">
    .text-with-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        max-width: 1330px;
        padding: 100px 45px;
        background: #fff;
        margin: 0 auto;
        overflow: hidden;
        @media (max-width: 1024px) {
            padding: 100px 20px;
            max-width: calc(100% - 20px);
            min-height: 100vh;
            align-items: stretch;
        }

        + .text-with-image {
            padding-top: 0;
        }

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: auto;
            width: 100%;
            max-width: 1330px;
            background: transparent;
            margin: 0 auto;
            overflow: hidden;
            &.right {
                flex-direction: row-reverse;
            }

            @media (max-width: 1024px) {
                flex-direction: column !important;
            }
        }

        &__image {
            width: 58%;
            @media (max-width: 1024px) {
                width: 100%;
                order: 0;
            }
        }

        &__text {
            width: 42%;
            padding-left: 120px;
            padding-right: 120px;
            @media (max-width: 1024px) {
                width: 100%;
                order: 1;
                padding-left: 20px;
                padding-right: 20px;
                margin-top: auto;
                margin-bottom: auto;
            }
            .smaller-padding & {
                padding-left: 65px;
                padding-right: 65px;
                @media (max-width: 1024px) {
                    width: 100%;
                    order: 1;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            ul {
                list-style: none;
                margin-left: 0;
                padding-left: 0;
                li:before{
                    display: none;
                }
            }

            h2 {
                font-size: 48px;
                font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
                line-height: 1.1;
                margin-bottom: 25px;
                @media (max-width: 1024px) {
                    font-size: 38px;
                    margin-top: 40px;
                }
            }

            a {
                color: inherit;
            }

            img {
                display: inline-block;
                vertical-align: -7px;
                + a {
                    text-decoration: none;
                }
            }
        }
    }
</style>

<script>
    export default {
        components: {
        },
        data() {
            return {}
        },
        props: ['blok'],
        methods: {
            image(fileType = null, isHighResolution = false) {
                const highResolutionScale = 1
                let width, height
    
                if (this.blok && this.blok.image && this.blok.image.filename) {
                    // default size
                    width = 720
                    height = 680
    
                    // scale up for highres image
                    if (isHighResolution) {
                        height *= highResolutionScale
                        width *= highResolutionScale
                    }
    
                    // crop/scale the image
                    const processedImage = this.$func.imageService(this.blok.image.filename, {
                        size: width + 'x' + height,
                        focus: this.blok.image.focus,
                        fileType: fileType
                    })
                    return processedImage
                }
            },
            styles() {
                let bgColor, textColor;
                if (this.blok && this.blok.text_background) {
                    bgColor = 'background:' + this.blok.text_background.color + ';';
                }
                if (this.blok && this.blok.text_color) {
                    textColor = 'color:' + this.blok.text_color + ';';
                }
    
                const styles = bgColor + textColor;
    
                return styles;
            },
            smallerPadding() {
                if (this.blok && this.blok.smaller_padding) {
                    return ' smaller-padding';
                }
            }
        },
        mounted() {
            console.debug(this.blok)
        },
        computed: {
            text() {
                if (this.blok.text) {
                    return this.$storyapi.richTextResolver.render(this.blok.text)
                }
            },
        }
    }
    </script>