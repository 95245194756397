var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "megamenu-content",
      class: _vm.hasOnlyDescription()
    },
    [
      this.addGridItems()
        ? [
            this.threeColumns()
              ? [
                  _c(
                    "div",
                    { staticClass: "megamenu-columns three" },
                    _vm._l(_vm.getGridCards(), function(gridCard) {
                      return _c(_vm._f("dashify")(gridCard.component), {
                        key: gridCard._uid,
                        tag: "component",
                        attrs: { blok: gridCard }
                      })
                    }),
                    1
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            this.fourColumns()
              ? [
                  _c(
                    "div",
                    { staticClass: "megamenu-columns four" },
                    _vm._l(_vm.getGridCards(), function(gridCard) {
                      return _c(_vm._f("dashify")(gridCard.component), {
                        key: gridCard._uid,
                        tag: "component",
                        attrs: { blok: gridCard }
                      })
                    }),
                    1
                  )
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      this.justDescription()
        ? [
            _c("div", { staticClass: "megamenu-content__wrapper" }, [
              _vm.description
                ? _c("div", {
                    staticClass: "description",
                    domProps: { innerHTML: _vm._s(_vm.description) }
                  })
                : _vm._e()
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }