var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "megamenu-card"
    },
    [
      _c(
        "nuxt-link",
        {
          staticClass: "megamenu-card__link",
          attrs: { to: "/" + _vm.blok.link.cached_url + "/" },
          nativeOn: {
            click: function($event) {
              return _vm.exitNav()
            }
          }
        },
        [
          _c("picture", [
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: { "data-srcset": _vm.image("webp"), type: "image/webp" }
            }),
            _vm._v(" "),
            _c("source", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: { "data-srcset": _vm.image(null), type: "image/jpeg" }
            }),
            _vm._v(" "),
            _c("img", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                "data-src": _vm.image(),
                width: "400",
                height: "180",
                alt: _vm.blok.title
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "nuxt-link",
        {
          staticClass: "megamenu-card__title",
          attrs: { to: "/" + _vm.blok.link.cached_url + "/" },
          nativeOn: {
            click: function($event) {
              return _vm.exitNav()
            }
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.blok.title) + "\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }