var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "editable",
          rawName: "v-editable",
          value: _vm.blok,
          expression: "blok"
        }
      ],
      staticClass: "text-media-block"
    },
    _vm._l(_vm.blok.item, function(item) {
      return _c(_vm._f("dashify")(item.component), {
        key: item._uid,
        tag: "component",
        attrs: { blok: item }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }