<template>
	<div class="layout-aside">
		<aside>
			<component :key="blok._uid" v-for="blok in blok.aside" :blok="blok" :is="blok.component | dashify"></component>
		</aside>
		<div class="layout-aside__content">
			<component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify"></component>
		</div>
	</div>
</template>

<style lang="scss">
.layout-aside {
	margin: 0 auto;
	width: min(var(--content-width), 100%);
}
/* Medium & Up Screen */
@media all and #{$mq-m-min} {
	.layout-aside {
		@include grid();

		aside {
			grid-column: 1 / span 3;
		}

		.layout-aside__content {
			grid-column: 4 / span 9;
		}
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
