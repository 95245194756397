<template>
	<ul v-editable="blok" class="social">
		<li>
			<a :href="blok.facebook_link.cached_url" class="social__facebook">Visit B+N Industries on Facebook</a>
		</li>
		<li>
			<a :href="blok.twitter_link.cached_url" class="social__twitter">Visit B+N Industries on Twitter</a>
		</li>
		<li>
			<a :href="blok.linkedin_link.cached_url" class="social__linkedin">Visit B+N Industries on LinkedIn</a>
		</li>
		<li>
			<a :href="blok.instagram_link.cached_url" class="social__instagram">Visit B+N Industries on Instagram</a>
		</li>
		<li>
			<a :href="blok.pinterest_link.cached_url" class="social__pinterest">Visit B+N Industries on Pinterest</a>
		</li>
	</ul>
</template>

<style lang="scss">
.social {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 0 30px -10px;
	padding: 0;

	li {
		align-items: center;
		display: inline-flex;
	}

	a {
		background-image: var(--url);
		background-position: center center;
		background-repeat: no-repeat;
		display: block;
		font-size: 0;
		height: 44px;
		overflow: hidden;
		text-indent: 100%;
		width: 44px;
	}

	&__facebook {
		--url: url(/img/ui/icon-facebook.svg);
	}
	&__twitter {
		--url: url(/img/ui/icon-twitter.svg);
	}
	&__linkedin {
		--url: url(/img/ui/icon-linkedin.svg);
	}
	&__instagram {
		--url: url(/img/ui/icon-instagram.svg);
	}
	&__pinterest {
		--url: url(/img/ui/icon-pinterest.svg);
	}
}

// Up to Medium Screen
@media all and #{$mq-s-max} {
	.social {
		margin-top: 10px;

		a {
			background-size: auto 22px;
			margin-right: 10px;
		}
	}
}
</style>

<script>
export default {
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {}
}
</script>
