var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error.statusCode === 404
        ? _c("Page404", { attrs: { error: this.error } })
        : _c("PageError", { attrs: { error: _vm.error } }),
      _vm._v(" "),
      _c("GlobalModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }