<template>
	<div v-editable="blok" class="email-signup">
		<h2>{{ blok.title }}</h2>

		<form @submit.prevent="submit" :class="this.errors ? 'has-errors' : false" method="post">
			<fieldset>
				<input aria-label="Subscribe to Welch's newsletter" class="email-signup__input" type="email" placeholder="Email Address" name="email" required @invalid="invalidateForm" v-model="email" />
				<select class="custom-select form-field email-signup__input" id="industry" name="industry" required v-model="industry">
					<option value="">Industry</option>
					<option value="Commercial">Commercial</option>
					<option value="Healthcare">Healthcare</option>
					<option value="Offices/Workspaces">Offices/Workspaces</option>
					<option value="Retail">Retail</option>
					<option value="Residential">Residential</option>
					<option value="Multidiscipline">Multidiscipline</option>
				</select>
				
				<button class="email-signup__button" type="submit">Join</button>
			</fieldset>
			<p v-if="serverError == true" class="error">Please enter a valid email.</p>
			<transition v-if="success" name="fade">
				<p class="email-signup__thanks">{{ blok.success_message }}</p>
			</transition>
		</form>
		<p>{{ blok.description }}</p>
	</div>
</template>

<style lang="scss">
.email-signup {
	--form-item-height: 45px;
	--input-border-color: #575757;
	padding-block-end: 20px;
	width: min(450px, 100%);

	p {
		font-size: 0.875rem;
		line-height: 1.4;
	}

	form {
		position: relative;
	}

	fieldset {
		border: 0;
		display: flex;
		padding: 0;
		position: relative;
	}

	&__input {
		--placeholderColor: #b8b8b8;
		background: none;
		border: solid var(--input-border-color);
		border-width: 0 0 1px 0;
		color: var(--white);
		display: flex;
		font-size: 1rem;
		height: var(--form-item-height);
		justify-content: center;
		padding: 0 0 0 0;
		width: calc(50% - 24px);
		margin-right: 10px;
		color: var(--placeholderColor);

		&::-webkit-input-placeholder {
			color: var(--placeholderColor);
		}
		&::-moz-placeholder {
			color: var(--placeholderColor);
		}
		&:-ms-input-placeholder {
			color: var(--placeholderColor);
		}
		&:-moz-placeholder {
			color: var(--placeholderColor);
		}

		// Overwrite autofill styling
		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px var(--black) inset !important;
			-webkit-text-fill-color: var(--white) !important;
		}
	}

	&__button {
		border: 0;
		cursor: pointer;
		font-size: inherit; // adding to prevent accessibility error
		height: 100%;
		overflow: hidden;
		position: absolute;
		right: 0;
		text-indent: 100%;
		top: 0;
		white-space: nowrap;
		width: 45px;
	}

	&__button::after {
		background: url(/img/ui/icon-submit.svg) right center no-repeat;
		content: '';
		display: flex;
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
	:focus {
		box-shadow: 0 0 0 3px #7cadf6;
		outline: none;
	}

	p.email-signup__thanks {
		color: var(--orange);
		margin: 15px 0 20px;
	}

	/* Error State */
	.has-errors {
		:invalid:focus {
			box-shadow: 0 0 0 1px #f00;
		}
		:invalid:not(fieldset) {
			border-color: #f00;
		}
	}
	p.error {
		color: #f00;
	}
}
// Up to Large Screen
@media all and #{$mq-m-max} {
	.email-signup {
		p {
			margin: 10px 0 25px;
		}
	}
}

//Large Screen
@media all and #{$mq-l-min} {
	.email-signup {
		&__input {
			font-size: 1.25rem;
		}

		p {
			font-size: 1.125rem;
			margin: 20px 0 35px;
		}
	}
}

@media all and #{$mq-s-max} {
	.email-signup {
		fieldset {
			flex-direction: column;
			align-items: flex-start;
		}

		&__input {
			width: 100%;
			margin-right: 0;
			margin-bottom: 10px;
		}

		&__button {
			position: relative;
			width: auto;
			display: inline-flex;
			text-align: left;
			text-indent: 0;
			line-height: 32px;
			margin-top: 10px;
			background: #fff;
			width: auto;
			padding: 6px 11px 0px 15px;
			border-radius: 20px;
			align-items: center;
			color: #333;
			font-size: 18px;
			margin-bottom: 10px;
			&:after {
				display: flex;
				left: 0;
				height: 23px;
				position: relative;
				top: 0;
				width: 23px;
				margin-left: 10px;
				margin-top: -5px;
			}
		}
	}
}
</style>

<script>
export default {
	data() {
		return {
			errors: false,
			serverError: false,
			email: '',
			industry: '',
			success: false
		}
	},
	props: ['blok', 'title', 'description'],
	computed: {
		formAction() {
			return process.env.subscribeFormAction
		}
	},
	created() {
		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},
	methods: {
		invalidateForm() {
			this.errors = true
		},
		submit() {
			this.$axios
				.post(
					this.formAction,
					{
						email: this.email,
						industry: this.industry
					},
					{
						debug: false
					}
				)
				.then(data => {
					if (data.status == 201) {
						this.success = true
						this.errors = false
						this.serverError = false
						this.email = ''
						setTimeout(() => (this.success = false), 7500)
					}
				})
				.catch(error => {
					// @todo handle error response
					this.serverError = true
				})
		}
	},
	mounted() {}
}
</script>
