<template>
	<div v-editable="blok" class="carousel-container" :class="checkAutoplay" ref="carouselContainer">
		<button class="carousel-skip" type="button" ref="skipBtn">Skip Carousel of {{ blok.carousel_item.length }} Items</button>
		<ul class="carousel" ref="carousel">
			<li v-for="item in blok.carousel_item" :key="blok.carousel_item.id">
				<Hero :key="hero._uid" v-for="hero in item.block" :blok="hero" :is="hero.component | dashify" />
			</li>
		</ul>

		<ul class="carousel__bullets" :style="'--timing: ' + timing + 'ms'">
			<li aria-hidden="true" v-for="(item, index) in blok.carousel_item" :key="blok.carousel_item.id">
				<button @click="autoplayStop(), jumpTo(index)" class="carousel__bullet" :class="slide == index ? 'active' : ''" tabindex="-1">Item {{ index }}</button>
			</li>
		</ul>

		<template v-if="blok.carousel_item.length > 1">
			<button @click="changeSlide" class="carousel__btn carousel__btn--prev" data-delta="-1" tabindex="-1" ref="prevBtn">Previous Slide</button>

			<button @click="changeSlide" class="carousel__btn carousel__btn--next" data-delta="1" tabindex="-1" ref="nextBtn">Next Slide</button>
		</template>
	</div>
</template>

<style lang="scss">
/* Container */
.carousel-container {
	margin: 0 -10px;
	overflow: hidden;
	position: relative;
}

/* Carousel Skip Button */
.carousel-skip {
	background-color: var(--white);
	font-size: 0.875rem;
	left: 0;
	line-height: 1;
	opacity: 0;
	overflow: hidden;
	padding: 10px;
	position: absolute;
	top: 0;
	transform: translateY(-100%);
	transition: transform 200ms linear;
	z-index: 2;

	&:focus {
		box-shadow: inset 0 0 0 3px rgba(0, 95, 204, 0.65);
		border: 0;
		outline: none;
		opacity: 1;
		transform: translateY(0);
	}
}

/* Base Carousel Styling and Functionality */
.carousel {
	-ms-overflow-style: none; /* IE11: hide scrollbar */
	scrollbar-width: none; /* Firefox: hide scrollbar */
	display: flex;
	list-style: none;
	margin: 0;
	min-height: inherit;
	overflow-x: auto;
	overscroll-behavior-x: contain;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch;

	/* Chrome/Edge/Safari: hide scrollbar */
	&::-webkit-scrollbar {
		display: none;
	}

	> li {
		flex: 1 0 100%;
		scroll-snap-align: start;
		position: relative;
	}
}

/* Carousel Prev/Next Buttons */
.carousel__btn {
	background: var(--white) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18'%3E%3Cpath fill='none' stroke='%23000' stroke-width='2' transform='rotate(90 4.75 9.125)' d='M-3.125 12.875l7.774-7.5 7.976 7.5'/%3E%3Cdefs /%3E%3C/svg%3E") center center no-repeat;
	border-radius: 50%;
	height: 36px;
	overflow: hidden;
	position: absolute;
	text-indent: 100%;
	top: 50%;
	transform: translateY(-50%);
	white-space: nowrap;
	width: 36px;
	z-index: 1;

	.content-overlap & {
		top: calc(50% + (var(--content-overlap) / 2));
	}

	&--prev {
		left: 20px;
		transform: translateY(-50%) scale(-1);
	}

	&--next {
		right: 20px;
	}

	&[disabled] {
		cursor: default;
		opacity: 0.5;
	}
}

/* Carousel Bullets */
.carousel__bullets {
	bottom: 35px;
	display: flex;
	left: 50%;
	margin-block-end: 0;
	position: absolute;
	transform: translateX(-50%);
	z-index: 1;

	li {
		line-height: 0;
	}

	.content-overlap & {
		bottom: calc(35px + (var(--content-overlap) * -1));
	}
}

.carousel__bullet {
	background: linear-gradient(to right, var(--white) 50%, rgba(255, 255, 255, 0.5) 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	border-radius: 3px;
	color: transparent;
	font-size: 0;
	height: 6px;
	margin: 0 3px;
	//opacity: 0.5;
	//overflow: hidden;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 60px;

	&::before {
		content: '';
		display: block;
		height: 44px;
		left: 0;
		position: absolute;
		top: -19px;
		width: 100%;
	}

	&.active {
		//opacity: 1;
		background-position: left bottom;
		cursor: default;
	}
}

.carousel-container--autoplay {
	.carousel__bullet {
		--timing: var(var(--timing), 0);

		&.active {
			animation: slideOver var(--timing) var(--easing) forwards;
		}
	}
}
@keyframes slideOver {
	0% {
		background-position: right bottom;
	}
	100% {
		background-position: left bottom;
	}
}
</style>

<script>
export default {
	components: {
		Hero: () => import('~/components/Hero.vue')
	},
	data() {
		return {
			autoplay: this.blok.autoplay,
			slide: 0
		}
	},
	computed: {
		checkAutoplay() {
			if (process.client) {
				if (!('ontouchstart' in document.documentElement) && this.$motionPref == true && this.autoplay == true) {
					return 'carousel-container--autoplay'
				}
			}
		},
		timing() {
			return this.blok.autoplay_time
		}
	},
	props: ['blok'],
	methods: {
		activeItem: function() {
			let active = this.carousel.querySelector('li.active')
			if (active != null && this.blok.carousel_item.length > 1) {
				let index = [...active.parentElement.children].indexOf(active)

				// Manage button activation
				if (index == this.carouselItems.length - 1) {
					this.btnNext.setAttribute('disabled', 'disabled')
				} else if (index == 0) {
					this.btnPrev.setAttribute('disabled', 'disabled')
				} else {
					this.btnNext.removeAttribute('disabled')
					this.btnPrev.removeAttribute('disabled')
				}

				// Update index
				if (index > this.blok.carousel_item.length - 1) {
					this.slide = 0
				}
				this.slide = index
			}
		},
		autoplayInit: function() {
			this.timer = setInterval(() => this.autoplayMove(), this.blok.autoplay_time)
		},
		autoplayMove: function(index) {
			// Move 1 if not last
			if (this.slide < this.carouselItems.length - 1) {
				index = 1
			}

			// Move slide
			this.jumpTo(index)
		},
		autoplayStop: function() {
			this.autoplay = false
			clearInterval(this.timer)
		},
		changeSlide: function(e) {
			// Figure out item width
			const scrollValue = this.carouselItems[0].offsetWidth

			// Move if prev/next is clicked
			const width = scrollValue,
				delta = e.target.dataset.delta
			this.carousel.scrollTo(this.carousel.scrollLeft + width * delta, 0)

			this.autoplayStop()
		},
		checkUserHScroll: function() {
			const mouseEvent = e => {
				if (e.deltaX && e.deltaY < 10) {
					// Stope Autoplay
					this.autoplayStop()
					// Remove listener
					this.carouselContainer.removeEventListener('wheel', mouseEvent)
				}
			}
			// Add listener
			this.carouselContainer.addEventListener('wheel', mouseEvent, false)
		},
		jumpTo: function(index) {
			let delta = 1

			// Movement if No Autoplay
			if (!this.autoplay) {
				// Manual Forward/Backward Movement
				if (index < this.slide) {
					// Move backwards
					delta = -1
					index = this.slide - index
				} else {
					// Move forwards
					index = index - this.slide
				}
			}

			// Figure out item width
			const scrollValue = this.carouselItems[0].offsetWidth

			// Move when bullet is clicked
			this.carousel.scrollTo(this.carousel.scrollLeft + scrollValue * (index * delta), 0)
		},
		skipBtn: function() {
			// Set up skip button only if data-description is defined
			if (this.carouselContainer.nextElementSibling !== null) {
				const nextEl = this.carouselContainer.nextElementSibling
				this.nextEl = nextEl

				// Skip Carousel Button
				this.btnSkip.addEventListener('click', e => {
					this.nextEl.setAttribute('tabindex', '0')
					this.nextEl.focus()
				})
				this.nextEl.addEventListener('click', e => {
					this.nextEl.removeAttribute('tabindex', '0')
				})
			}
		}
	},
	mounted() {
		this.carouselContainer = this.$refs.carouselContainer
		this.carousel = this.$refs.carousel
		this.carouselItems = this.carousel.querySelectorAll('li')
		this.btnNext = this.$refs.nextBtn
		this.btnPrev = this.$refs.prevBtn
		this.btnSkip = this.$refs.skipBtn

		// Autoplay
		if (this.autoplay == true && !('ontouchstart' in document.documentElement)) {
			this.autoplayInit()
		}

		// Stops autoplay if user scrolls carousel
		this.checkUserHScroll()

		// Skip Button
		this.skipBtn()

		// Observe the slide change
		const threshold = 0.5
		const options = {
			root: this.carousel,
			threshold
		}
		let observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.intersectionRatio >= threshold) {
					entry.target.classList.add('active')
				} else {
					entry.target.classList.remove('active')
				}
			})
			// Update active slide
			this.activeItem()
		}, options)
		;[].map.call(this.carouselItems, function(el) {
			observer.observe(el)
		})
	}
}
</script>
