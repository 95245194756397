<template>
	<ul v-editable="blok" class="breadcrumb">
		<li>
			<nuxt-link :to="'/'">Home</nuxt-link>
		</li>
		<li v-for="link in linkArray" :key="link._uid">
			<nuxt-link :to="'/' + link.link + '/'">{{ link.label }}</nuxt-link>
		</li>
	</ul>
</template>

<style lang="scss">
.breadcrumb {
	display: flex;
	margin-block-end: 5px;

	a {
		--min-font: 1.125;
		--max-font: 1.25;
		text-decoration: none;
	}

	li:not(:first-child) {
		padding-inline-start: 6px;
	}

	// li:not(:first-child) a::before {
	// 	content: '/';
	// 	padding-inline-end: 6px;
	// }

	li:only-of-type a::after,
	li:not(:last-of-type) a::after {
		content: '/';
		padding-inline-start: 6px;
	}
}

@media all and #{$mq-s-max} {
}
</style>

<script>
export default {
	data() {
		return {
			linkArray: null
		}
	},
	props: ['blok'],
	methods: {},
	computed: {},
	created() {
		this.linkArray = []

		if (this.blok.item) {
			this.blok.item.forEach(item => {
				this.linkArray.push({ label: item.link_label, link: item.link.cached_url })
			})
		}
	}
}
</script>
