var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contactButton" }, [
    _c(
      "a",
      {
        staticClass: "primary-button",
        attrs: { href: "/about-b-n/contact-us/" },
        on: {
          click: function($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.contactForm($event)
          }
        }
      },
      [_vm._v(_vm._s(_vm.contact_text))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }