<template>
	<div>
		<div v-editable="blok" class="image-grid">
			<img :key="item._uid" v-for="item in blok.item" v-lazy-load :data-src="item.image.filename" :alt="item.image.alt" />
		</div>
	</div>
</template>

<style lang="scss">
.image-grid {
	@include grid();
	gap: 20px;
	margin: 0 auto var(--margin-bottom);
	place-items: center;
	width: min(var(--content-width), 100%);

	img {
		grid-column: span var(--col-span);
	}
}

@media all and (max-width: 567px) {
	.image-grid {
		--col-span: 6;
	}
}
@media all and (min-width: 568px) {
	.image-grid {
		--col-span: 4;
	}
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.image-grid {
		--margin-bottom: 50px;
	}
}

/* Medium Screen & Up */
@media all and #{$mq-m-min} {
	.image-grid {
		--margin-bottom: 100px;
	}
}

/* Large Screen */
@media all and #{$mq-l-min} {
	.image-grid {
		--col-span: 3;
	}
}
</style>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
</script>
